import * as actionTypes from './actions';

const AppReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.USER_STATE:
      return {
        ...state,
        user: action.user,
        loggedIn: action.loggedIn,
        isMaintenance: action.isMaintenance || false
      };
    case actionTypes.MAINTENANCE_MODE:
      return {
        isMaintenance: action.isMaintenance || false
      };
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        modalContent: action.modalContent
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        showModal: false
      };
    case actionTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationType: action.notificationType,
        notificationMessage: action.notificationMessage
      };
    case actionTypes.HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false
      };
    default:
      return state;
  }
};

export default AppReducer;
