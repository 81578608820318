import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import RedStar from '../RedStar';

const HighlightText = ({ children }) => {
  return (
    <Typography variant="caption" sx={{ bgcolor: '#ededed', borderRadius: 1, p: '2px 8px' }}>
      {children}
      <RedStar />
    </Typography>
  );
};

HighlightText.propTypes = {
  children: PropTypes.string
};

export default HighlightText;
