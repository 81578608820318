import { JoiNumber } from 'helpers/joiExtensions';
import Joi from 'joi';

export const cryptoCurrencySchema = Joi.object({
  enableBuy: Joi.boolean(),
  enableSell: Joi.boolean(),
  enableAdvancedOrdersHalt: Joi.boolean(),
  enableInstantOrder: Joi.boolean(),
  instantMinimumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Minimum Buy Amount'),
  instantMaximumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Maximum Buy Amount'),
  instantMinimumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Minimum Sell Amount Idr'),
  instantMaximumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Maximum Sell Amount Idr'),
  enableMarketOrder: Joi.boolean(),
  minimumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Minimum Buy Amount'),
  maximumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Maximum Buy Amount'),
  minimumSellAmountIdr: JoiNumber.normalizeValue().integer().min(0).label('Minimum Sell Amount Idr'),
  maximumSellAmountIdr: JoiNumber.normalizeValue().integer().min(0).label('Maximum Sell Amount Idr'),
  enableLimitOrder: Joi.boolean(),
  limitMinimumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Minimum Buy Amount'),
  limitMaximumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Maximum Buy Amount'),
  limitMinimumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Minimum Sell Amount Idr'),
  limitMaximumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Maximum Sell Amount Idr'),
  enableOcoOrder: Joi.boolean(),
  ocoMinimumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Minimum Buy Amount'),
  ocoMaximumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Maximum Buy Amount'),
  ocoMinimumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Minimum Sell Amount Idr'),
  ocoMaximumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Maximum Sell Amount Idr'),
  enableStopOrder: Joi.boolean(),
  stopMinimumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Minimum Buy Amount'),
  stopMaximumBuyAmount: JoiNumber.normalizeValue().integer().min(0).label('Maximum Buy Amount'),
  stopMinimumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Minimum Sell Amount Idr'),
  stopMaximumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Maximum Sell Amount Idr'),
  enableStopLimitOrder: Joi.boolean(),
  stopLimitMinimumBuyAmount: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Minimum Buy Amount'),
  stopLimitMaximumBuyAmount: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Maximum Buy Amount'),
  stopLimitMinimumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Minimum Sell Amount Idr'),
  stopLimitMaximumSellAmountIdr: JoiNumber.normalizeValue()
    .integer()
    .min(0)
    .label('Maximum Sell Amount Idr')
});
