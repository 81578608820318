import { Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import KycTask from 'components/molecules/KycTask';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useState } from 'react';

const UserWealthTrusted = ({ userWealthTrustedData }) => {
  const [showDetail, setShowDetail] = useState(false);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const userWealthTrustedTableData = [
    { label: 'Mothers maiden name', value: userWealthTrustedData.mothersMaidenName },
    { label: 'Investment experience', value: userWealthTrustedData.investmentExperience },
    { label: 'Estimated net worth', value: userWealthTrustedData.estimatedNetWorth },
    { label: 'Home ownership status ', value: userWealthTrustedData.homeOwnership },
    { label: 'Relationship', value: userWealthTrustedData.relationship },
    { label: 'Trusted contact name', value: userWealthTrustedData.contactName },
    { label: 'Trusted contact Phone number', value: userWealthTrustedData.contactPhone },
    { label: 'Trusted contact address', value: userWealthTrustedData.contactAddress },
    { label: 'Contact country', value: userWealthTrustedData.contactCountry },
    { label: 'Trusted contact province', value: userWealthTrustedData.contactProvince },
    { label: 'Trusted contact city', value: userWealthTrustedData.contactCity },
    { label: 'Trusted contact district', value: userWealthTrustedData.contactDistrict },
    { label: 'Trusted contact subdistrict', value: userWealthTrustedData.contactSubDistrict },
    { label: 'Trusted contact postal code', value: userWealthTrustedData.contactPostalCode },
    { label: 'Spouse Relationship', value: userWealthTrustedData.spouseRelationship },
    { label: 'Spouse contact name', value: userWealthTrustedData.spouseName },
    { label: 'Spouse Phone number', value: userWealthTrustedData.spousePhoneNumber },
    { label: 'Spouse contact address', value: userWealthTrustedData.spouseAddress },
    { label: 'Spouse contact country', value: userWealthTrustedData.spouseCountry },
    { label: 'Spouse contact province', value: userWealthTrustedData.spouseProvince },
    { label: 'Spouse contact city', value: userWealthTrustedData.spouseCity },
    { label: 'Spouse contact district', value: userWealthTrustedData.spouseDistrict },
    { label: 'Spouse contact subdistrict', value: userWealthTrustedData.spouseSubDistrict },
    { label: 'Spouse contact postal code', value: userWealthTrustedData.spousePostalCode },
    {
      label: 'Last Upload Time',
      value: userWealthTrustedData.createdAt ? dateFormatter(userWealthTrustedData.createdAt) : ''
    }
  ];

  const kycTaskStatus = () => (
    <>
      {userWealthTrustedData.status === 'UPLOADED' ? (
        <Typography sx={{ color: 'green' }}>VERIFIED</Typography>
      ) : (
        <Typography>{userWealthTrustedData.status}</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => handleDropdown(showDetail);

  const isTaskDisabled = userWealthTrustedData.status === 'NOT UPLOADED';

  return (
    <KycTask
      title="User Wealth Trusted Contact"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={userWealthTrustedTableData} />
    </KycTask>
  );
};

UserWealthTrusted.propTypes = {
  userWealthTrustedData: PropTypes.object
};

export default UserWealthTrusted;
