import Joi from 'joi';

export const addFundSchema = Joi.object({
  bankAccountName: Joi.string().required(),
  bankAccountNumber: Joi.string().required(),
  bankCode: Joi.string().required(),
  bankHolder: Joi.string().required(),
  bankIcon: Joi.string().required(),
  bankName: Joi.string().required(),
  bankForSubscription: Joi.string().required(),
  buyFee: Joi.number().min(0).allow(null, ''),
  buyIcon: Joi.string().allow(null, ''),
  currency: Joi.string().required(),
  custodianBank: Joi.string().required(),
  custodianFee: Joi.number().min(0).allow(null, ''),
  displayCode: Joi.string().required(),
  enCustodyFee: Joi.string().required(),
  enDescription: Joi.string().required(),
  enServiceFee: Joi.string().required(),
  errorIcon: Joi.string().allow(null, ''),
  expenseRatio: Joi.number().min(0).required(),
  fundCategory: Joi.string().required(),
  fundCode: Joi.string().required(),
  fundManager: Joi.string().required(),
  fundRisk: Joi.string().required(),
  inCustodyFee: Joi.string().required(),
  inDescription: Joi.string().required(),
  inServiceFee: Joi.string().required(),
  lockingPeriod: Joi.number().min(0).allow(null, ''),
  managementFee: Joi.number().min(0).allow(null, ''),
  managerIcon: Joi.string().required(),
  managerCode: Joi.string().required(),
  minAfterWithdrawal: Joi.number().min(0).required(),
  minInvestment: Joi.number().min(0).required(),
  minRedemption: Joi.number().min(0).required(),
  name: Joi.string().required(),
  sellBuffer: Joi.number().min(0).allow(null, ''),
  sellFee: Joi.number().min(0).allow(null, ''),
  sellIcon: Joi.string().allow(null, ''),
  sharpeRatio: Joi.number().required(),
  switchable: Joi.boolean().required()
});

export const updateFundSchema = Joi.object({
  id: Joi.number().integer().positive().required(),
  assetAllocation: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required().messages({ 'string.empty': 'NameID cannot be empty' }),
        nameEn: Joi.string().required().messages({ 'string.empty': 'NameEN cannot be empty' }),
        percentage: Joi.number().positive().required().label('Percentage'),
        color: Joi.string().required().messages({ 'string.empty': 'Color cannot be empty' })
      })
    )
    .required(),
  aum: Joi.number().integer().min(0).required(),
  buyFee: Joi.number().min(0).required(),
  custodianFee: Joi.number().min(0).required(),
  enCustodyFee: Joi.string().required(),
  enServiceFee: Joi.string().required(),
  estMonthlyReturn: Joi.number().required(),
  estimatedYearlyReturn: Joi.number().required(),
  expenseRatio: Joi.number().min(0).required(),
  fundNpwp: Joi.string().allow(null, ''),
  goinvestasiMonthlyReturn: Joi.number().allow(null, '').required(),
  inCustodyFee: Joi.string().required(),
  inServiceFee: Joi.string().required(),
  lockingPeriod: Joi.number().min(0).required(),
  managementFee: Joi.number().min(0).allow(null, ''),
  managerNpwp: Joi.string().allow(null, ''),
  minAfterWithdrawal: Joi.number().min(0).required(),
  minInvestment: Joi.number().min(0).required(),
  minRedemption: Joi.number().min(0).required(),
  name: Joi.string().required(),
  sellFee: Joi.number().min(0).required(),
  topHoldings: Joi.alternatives().try(Joi.array(), Joi.string()).required(),
  yearlyMaximumDrawDown: Joi.number().required(),
  fundFact: Joi.object().allow(''),
  prospectus: Joi.object().allow(''),
  sharpeRatio: Joi.number().required()
});
