import Joi from 'joi';

export const pepStatusUploadSchema = Joi.object({
  userId: Joi.number().integer().positive().required(),
  pepCurrentStatus: Joi.boolean().allow(null, '').required(),
  pepNewStatus: Joi.boolean().required(),
}).required();

export const pepStatusSchema = Joi.object({
  pepStatus: Joi.boolean().required(),
});
