import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { IMAGES } from 'constants/images';
import { logout } from 'services/AuthService';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { USER_STATE } from 'utils/redux/actions';

const Header = () => {
  const [appState, dispatch] = useStateValue();
  const { user } = appState;
  const { showNotification } = useNotification();

  const handleLogout = () => {
    logout().then(() => {
      dispatch({
        type: USER_STATE,
        user: null,
        loggedIn: false
      });
      showNotification('success', 'Logged out successfully');
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar
        sx={{
          '@media print': { display: 'none' },
          justifyContent: 'space-between'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={IMAGES.PLUANG_LOGO} alt="" />
          <Typography variant="subtitle1" sx={{ color: 'black', ml: 12 }}>
            {user?.name} - {user?.id}
          </Typography>
        </Box>
        <Button size="small" variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
