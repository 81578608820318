import Joi from 'joi';

export const eddUploadSchema = Joi.object({
  user_id: Joi.number().integer().positive().required(),
  occupation: Joi.string().max(100).required(),
  occupation_details: Joi.string().required(),
  occupation_field: Joi.string().max(100).required(),
  position: Joi.string().max(100).required(),
  workplace_address: Joi.string().required(),
  workplace_district: Joi.string().required(),
  workplace_sub_district: Joi.string().required(),
  workplace_city: Joi.string().required(),
  workplace_province: Joi.string().required(),
  income_level: Joi.string().max(50).required(),
  source_of_fund: Joi.string().required(),
  investment_objective: Joi.string().required(),
  npwp: Joi.string()
    .pattern(/^[0-9]{15}$/)
    .allow('')
}).required();
