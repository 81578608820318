import { Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import KycTask from 'components/molecules/KycTask';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useState } from 'react';

const Commodities = ({ commodityData }) => {
  const [showDetail, setShowDetail] = useState(false);

  const commoditiesTableData = [
    { label: 'Account Number', value: commodityData?.bankAccountNumber },
    {
      label: 'Created At',
      value: commodityData?.updatedAt ? dateFormatter(commodityData.updatedAt) : ''
    }
  ];

  const kycTaskStatus = () => (
    <>
      {commodityData?.bankAccountNumber && (
        <Typography sx={{ color: 'green' }}>COMPLETED</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => setShowDetail(!showDetail);

  const isTaskDisabled = !commodityData?.bankAccountNumber;

  return (
    <KycTask
      title="Pluang Commodities Account"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={commoditiesTableData} />
    </KycTask>
  );
};

Commodities.propTypes = {
  commodityData: PropTypes.object
};

export default Commodities;
