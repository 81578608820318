import { createTheme } from '@mui/material/styles';

const COLORS = {
  primary100: '#dbeafe',
  primary700: '#2563eb',
  success100: '#dcfce7',
  success700: '#388e3c',
  neutral100: '#f5f5f5',
  neutral700: '#525252',
  warning100: '#fef3c7',
  warning700: '#f59e0b',
  info100: '#cffafe',
  info700: '#06b6d4',
  error100: '#fee2e2',
  error700: '#ef4444'
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      100: COLORS.primary100,
      700: COLORS.primary700
    },
    error: {
      main: '#dc3545',
      100: COLORS.error100,
      700: COLORS.error700
    },
    info: {
      main: '#14a2b9',
      100: COLORS.info100,
      700: COLORS.info700
    },
    success: {
      main: '#28a745',
      100: COLORS.success100,
      700: COLORS.success700
    },
    warning: {
      main: '#ffa000',
      100: COLORS.warning100,
      700: COLORS.warning700
    },
    neutral: {
      main: '#737373',
      100: COLORS.neutral100,
      700: COLORS.neutral700
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Lato'
    },
    body1: {
      fontSize: 14
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: 0.1,
          textTransform: 'capitalize'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 12,
          minHeight: 32
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minHeight: 32
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 20
        }
      }
    }
  }
});

export default theme;
