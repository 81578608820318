import {
  MISSION_REWARD_TRIGGER_ASSET_CLASS,
  MISSION_SEGMENT_TYPE,
  MISSION_TYPE,
  REDEMPTION_QUOTA_TYPE,
  MISSION_REWARD_TRIGGER_TYPE,
  MISSION_REDEMPTION_MEDIUM,
  MISSION_GOAL,
  MISSION_REWARD_ASSET_CLASS,
  MISSION_REWARD_UNIT,
  MISSION_REWARD_LOCK
} from 'containers/ops/missions/constants';
import { JoiNumber } from 'helpers/joiExtensions';
import Joi from 'joi';

const minAmountCustomValidation = (value, helpers) => {
  const {
    state: { ancestors }
  } = helpers;
  const config = ancestors[3].config;
  const missionTriggerType = ancestors[1].missionTriggerType;
  const curr = ancestors[0];
  if (missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_BUY_TRANSACTION) {
    if (curr.trackedBy === 'amount') {
      const options = getOptions(curr.assetClass, config);
      const minAmount = getMinAmount(options, curr.assetCode);
      if (value < minAmount) {
        throw new Error(`it should be greater than or equal to ${minAmount}`);
      }
    } else {
      if (value < 1) {
        throw new Error(`it should be greater than zero`);
      }
    }
  } else if (missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_TOPUP_AMOUNT) {
    if (value < 1000) {
      throw new Error(`it should be greater than or equal to 1000`);
    }
  } else if (missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.SINGLE_BUY_TRANSACTION) {
    const options = getOptions(curr.assetClass, config);
    const minAmount = getMinAmount(options, curr.assetCode);
    if (value < minAmount) {
      throw new Error(`it should  be greater than or equal to ${minAmount}`);
    }
  } else if (missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.SINGLE_BUY_AND_HOLD || missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_BUY_AND_HOLD) {
    if (curr.assetClass === MISSION_REWARD_TRIGGER_ASSET_CLASS.CRYPTOCURRENCY && value < 10000) {
      throw new Error(`it should  be greater than or equal to 10000`);
    } else if (value < 1) {
      throw new Error(`it should  be greater than or equal to 1`);
    }
  }
  return value;
};

const checkAssetCodeValidation = (value, helpers) => {
  const {
    state: { ancestors }
  } = helpers;
  const missionTriggerType = ancestors[1].missionTriggerType;
  if (missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_BUY_AND_HOLD || missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.SINGLE_BUY_AND_HOLD) { 
    if (value) {
      throw new Error('Asset Code should be null');
    }
  } else {
    if (typeof value !== 'string') {
      throw new Error('Asset code should be of string type');
    }
  }
  return value;
};

const checkHoldingPeriodValidation = (value, helpers) => {
  const {
    state: { ancestors }
  } = helpers;
  const missionTriggerType = ancestors[1].missionTriggerType;
  if (missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_BUY_AND_HOLD || missionTriggerType === MISSION_REWARD_TRIGGER_TYPE.SINGLE_BUY_AND_HOLD) { 
    if (value > 0 && value < 46) {
      return value;
    }
    throw new Error('Holding period value should be smaller than 45 and greater than 0');
  } else {
    if (value) {
      throw new Error('Holding period not allowed');
    }
  }
  return value;
};

const getMinAmount = (options, code) => {
  const selectedCode = options?.filter((option) => {
    return option.id === code;
  });
  return selectedCode?.[0]?.minAmount || 10000;
};

const getMinRewardAmount = (options, code) => {
  const selectedCode = options?.filter((option) => {
    return option.id === code;
  });
  return selectedCode?.[0]?.minRewardAmount || 0;
};

const getOptions = (assetClass, config) => {
  switch (assetClass) {
    case MISSION_REWARD_TRIGGER_ASSET_CLASS.CRYPTOCURRENCY:
      return config['rewardCrypto'];
    case MISSION_REWARD_TRIGGER_ASSET_CLASS.MUTUAL_FUND:
      return config['mutualFund'];
    case MISSION_REWARD_TRIGGER_ASSET_CLASS.INDONESIAN_EQUITY:
      return config['indonesianEquity'];
    case MISSION_REWARD_TRIGGER_ASSET_CLASS.GLOBAL_SINGLE_STOCKS:
    case MISSION_REWARD_ASSET_CLASS.GLOBAL_SINGLE_STOCKS:
      return config['usStock'];
    case MISSION_REWARD_TRIGGER_ASSET_CLASS.GOLD:
      return config['gold'];
    default:
      return config['rewardCrypto'];
  }
};

const rewardsMinAmountValidation = (value, helpers) => {
  const {
    state: { ancestors }
  } = helpers;
  const config = ancestors[1].config;
  const curr = ancestors[0];
  const options = getOptions(curr.assetClass, config);
  const minRewardAmount = getMinRewardAmount(options, curr.assetCode);
  if (value < minRewardAmount) {
    throw new Error(`it should be >= ${minRewardAmount}`);
  }
  return value;
};

export const missionSchema = Joi.object({
  missionType: Joi.string(),
  missionCategory: Joi.string(),
  description: Joi.object({
    en: Joi.string().allow(null, ''),
    in: Joi.string().label('Description').required()
  }),
  title: Joi.object({
    en: Joi.string().allow(null, ''),
    in: Joi.string().label('Title').required()
  }),
  termsAndCondition: Joi.object({
    en: Joi.string().allow(null, ''),
    in: Joi.string()
      .label('Terms & Condition')
      .uri({ scheme: [/https/] })
      .required()
  }),
  startDate: Joi.date().required(),
  endDate: Joi.date().required(),
  missionPartnerId: Joi.alternatives().conditional('missionType', {
    is: MISSION_TYPE.SECRET,
    then: Joi.number().required(),
    otherwise: Joi.number().allow(null)
  }),
  missionCode: Joi.alternatives()
    .label('Mission Code')
    .conditional('missionType', {
      is: MISSION_TYPE.SECRET,
      then: Joi.string()
        .regex(/^[A-Z0-9]+$/)
        .required(),
      otherwise: Joi.string().allow(null, '')
    }),
  redemptionQuotaFilled: Joi.number().integer(),
  redemptionQuotaLimit: Joi.alternatives().conditional('redemptionQuotaType', {
    is: REDEMPTION_QUOTA_TYPE.UNLIMITED,
    then: Joi.number().integer().allow(null, ''),
    otherwise: Joi.number().integer().min(1)
  }),
  redemptionQuotaType: Joi.string(),
  rewardLock: Joi.number().integer().min(MISSION_REWARD_LOCK.MIN_DAYS).max(MISSION_REWARD_LOCK.MAX_DAYS),
  segment: Joi.object({
    segmentType: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
    description: Joi.string().allow(''),
    name: Joi.alternatives().conditional('segmentType', {
      is: MISSION_SEGMENT_TYPE.CUSTOM,
      then: Joi.string().label('Segment Name').required(),
      otherwise: Joi.string().allow('')
    })
  }),
  segmentId: Joi.number().allow(null),
  rewards: Joi.object({
    assetClass: Joi.string(),
    assetCode: Joi.string(),
    globalStockId: Joi.number().when('assetClass', {
      is: MISSION_REWARD_ASSET_CLASS.GLOBAL_SINGLE_STOCKS,
      then: Joi.number().integer().required()
    }),
    unit: Joi.string(),
    value: JoiNumber.normalizeValue()
      .label('Limit')
      .custom((values, helpers) => rewardsMinAmountValidation(values, helpers))
      .messages({
        'custom.invalid': 'Amount > minimumBuyAmount'
      })
  }),
  meta: Joi.object({
    missionColorAndAssetIcon: Joi.string(),
    clickAction: Joi.string(),
    notification: {
      title: Joi.string().label('Title').max(33).allow('', null),
      body: Joi.string().label('Description').max(133).allow('', null),
      redirection_url: Joi.string().label('Redirection Url').uri().allow('', null)
    },
    missionGoal: Joi.alternatives()
      .label('Mission Goal')
      .conditional('missionType', {
        is: MISSION_TYPE.SECRET,
        then: Joi.string().required(),
        otherwise: Joi.string().allow(null, '')
      })
  }),
  previousSegment: Joi.object().allow({}),
  config: Joi.object().allow({}),
  rewardTriggers: Joi.array().items(
    Joi.object({
      id: Joi.number().integer(),
      missionId: Joi.number().integer(),
      missionTriggerType: Joi.string(),
      triggerConfiguration: Joi.object({
        assetClass: Joi.string(),
        assetCode: Joi.custom((values, helpers) => checkAssetCodeValidation(values, helpers)),
        limit: JoiNumber.normalizeValue()
          .integer()
          .label('Limit')
          .custom((values, helpers) => minAmountCustomValidation(values, helpers))
          .messages({
            'custom.invalid': 'Amount > minimumBuyAmount'
          }),
        trackedBy: Joi.string(),
        paymentMethod: Joi.string(),
        kycType: Joi.string(),
        holdingPeriod: Joi.number().custom((values, helpers) => checkHoldingPeriodValidation(values, helpers)),
        buyAmount: JoiNumber.normalizeValue()
        .integer()
        .label('buyAmount')
        .custom((values, helpers) => minAmountCustomValidation(values, helpers))
        .messages({
          'custom.invalid': 'Amount > minimumBuyAmount'
        }),
        currency: Joi.string().valid(...Object.values(MISSION_REWARD_UNIT)),
      })
    })
  ),
  missionRedemptionMedium: Joi.string().when('missionType', {
    is: MISSION_TYPE.SECRET,
    then: Joi.string().when('meta.missionGoal', {
      is: MISSION_GOAL.ACQUISITION,
      then: Joi.string()
        .valid(...Object.values(MISSION_REDEMPTION_MEDIUM))
        .required()
    })
  }),
  missionStatus: Joi.string().allow(null),
  createdById: Joi.number().integer().allow(null),
  verifiedById: Joi.number().integer().allow(null),
  approved: Joi.boolean(),
  verified: Joi.array()
});
