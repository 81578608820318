import Joi from 'joi';
import { JoiNumber } from 'helpers/joiExtensions';

export const searchUserTopupSchema = Joi.object({
  userId: Joi.string().required().label('User Id')
});

export const topupSchema = Joi.object({
  topUpAmount: JoiNumber.normalizeValue()
    .min(0)
    .precision(2)
    .label('Minimum Top Up Amount')
    .required(),
  sourceBank: Joi.string().required(),
  sourceBankAccountNumber: Joi.string().required(),
  proofOfTransfer: Joi.any().invalid('', null).required(),
  topUpForm: Joi.any().invalid('', null).required()
});

export const topupEditSchema = Joi.object({
  id: Joi.number().integer().required().label('Id'),
  userId: Joi.number().integer().required().label('User Id'),
  topUpAmount: JoiNumber.normalizeValue()
    .min(0)
    .precision(2)
    .label('Minimum Top Up Amount')
    .required(),
  sourceBank: Joi.string().required(),
  sourceBankAccountNumber: Joi.string().required(),
  proofOfTransfer: Joi.any().required(),
  topUpForm: Joi.any().required()
});

export const reconciledBankStatementSchema = Joi.object({
  finalAmount: JoiNumber.normalizeValue().min(0).precision(2).label('Final Amount').required(),
  bankStatementTransactionId: Joi.string().required(),
  bankStatementTransactionProof: Joi.any().invalid('', null).required()
});
