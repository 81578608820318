export const ADMIN_CONTACT_METHOD = Object.freeze({
  EMAIL: 'EMAIL',
  WHATSAPP: 'WHATSAPP'
});

export const MEMBER_QUOTA_TYPE = Object.freeze({
  LIMITED: 'LIMITED',
  UNLIMITED: 'UNLIMITED'
});

export const FEE_ASSET_CATEGORY = Object.freeze({
  GSS: 'GSS',
  FOREX: 'FOREX',
  CRYPTO: 'CRYPTO'
});

export const memberQuotaTypeOptions = [
  { id: MEMBER_QUOTA_TYPE.LIMITED, name: 'Limited' },
  { id: MEMBER_QUOTA_TYPE.UNLIMITED, name: 'Unlimited' }
];

export const adminContactMethodOptions = [
  { label: 'Email', value: ADMIN_CONTACT_METHOD.EMAIL },
  { label: 'Whatsapp', value: ADMIN_CONTACT_METHOD.WHATSAPP }
];
