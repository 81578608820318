import { Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';
import { useStateValue } from 'utils/redux';
import { HIDE_NOTIFICATION } from 'utils/redux/actions';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = () => {
  const [appState, dispatch] = useStateValue();

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: HIDE_NOTIFICATION
    });
  };

  const renderAlertBody = (body) => {
    if (typeof body === 'string') return <Typography>{body}</Typography>;
    else return body?.map((msg) => <Typography key={msg}>{msg}</Typography>);
  };

  return (
    <Snackbar
      sx={{ maxWidth: '350px' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={appState.showNotification}
      autoHideDuration={2000}
      onClose={handleClose}>
      <Alert severity={appState.notificationType} sx={{ width: '100%', wordBreak: 'break-word' }}>
        <Typography>{appState.notificationMessage?.title}</Typography>
        {renderAlertBody(appState.notificationMessage?.body)}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
