import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from 'services/APIService';
import { useLoader, useNotification } from '.';

const useKyc = () => {
  const [, setLoader] = useLoader();
  const { showNotification } = useNotification();

  const getCitiesBasedOnProvince = (ocrState, setOcrState) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/ktpOcr/cities`,
      params: {
        provinceCode: ocrState?.province?.provinceCode || ''
      }
    })
      .then((result) => {
        if (result.data?.data?.length > 0) {
          let city = ocrState.city;
          if (
            typeof ocrState.city === 'string' &&
            ocrState.province &&
            ocrState.province.provinceCode
          ) {
            city =
              result.data.data.find((city) => {
                return city.cityName === ocrState.city;
              }) || '';
          }
          setOcrState((prevState) => ({
            ...prevState,
            cityCollection: result.data.data,
            city: city
          }));
        } else {
          showNotification('No City found in this province');
        }
      })
      .finally(() => setLoader(false));
  };

  const getDistrictsBasedOnCity = (ocrState, setOcrState) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/ktpOcr/districts`,
      params: {
        cityCode: ocrState.city.cityCode
      }
    })
      .then((result) => {
        if (result.data?.data?.length > 0) {
          let district = ocrState.district;
          if (typeof ocrState.district === 'string' && ocrState.city.cityCode) {
            district =
              result.data.data.find((district) => {
                return district.districtName === ocrState.district;
              }) || '';
          }
          setOcrState((prevState) => ({
            ...prevState,
            districtCollection: result.data.data,
            district: district
          }));
        } else {
          showNotification('No District found for this city');
        }
      })
      .finally(() => setLoader(false));
  };

  const getSubDistrictsBasedOnDistrict = (ocrState, setOcrState) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/ktpOcr/subDistricts`,
      params: {
        districtId: ocrState?.district?.id || null
      }
    })
      .then((result) => {
        if (result.data?.data?.length > 0) {
          let subDistrict = ocrState.subDistrict;
          if (
            typeof ocrState.subDistrict === 'string' &&
            ocrState.district &&
            ocrState.district.id
          ) {
            subDistrict =
              result.data.data.find((subDistrict) => {
                return subDistrict.subDistrictName === ocrState.subDistrict;
              }) || '';
          }
          setOcrState((prevState) => ({
            ...prevState,
            subDistrictCollection: result.data.data,
            subDistrict: subDistrict
          }));
        } else {
          showNotification('No Subdistrict found for this district');
        }
      })
      .finally(() => setLoader(false));
  };

  const getKycStateTransitionData = (userId) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.KYC1}/stateTransition/filter`,
      params: {
        userId
      }
    })
      .then((result) => result)
      .finally(() => {
        setLoader(false);
      });
  };

  const getRejectionJson = (rejectionState) => {
    const json = { id: [], en: [] };
    if (rejectionState.englishOptions && rejectionState.englishOptions.length) {
      json.en = rejectionState.englishOptions;
    }
    if (rejectionState.bahasaOptions && rejectionState.bahasaOptions.length) {
      json.id = rejectionState.bahasaOptions;
    }
    if (json.en && json.en.length) {
      json.en.forEach((item) => {
        if (!item.position) {
          item.position = 'banner';
        }
      });
    }
    if (json.id && json.id.length) {
      json.id.forEach((item) => {
        if (!item.position) {
          item.position = 'banner';
        }
      });
    }
    return json;
  };

  const generateReason = (lang, rejectionState) => {
    let reasons;
    if (lang === 'en') {
      reasons =
        rejectionState.englishOptions && rejectionState.englishOptions.map((item) => item.value);
    } else {
      reasons =
        rejectionState.bahasaOptions && rejectionState.bahasaOptions.map((item) => item.value);
    }

    if (!reasons || !reasons.length) return '';

    if (reasons.length === 1) {
      if (lang === 'en') {
        return `Your verification is rejected. ${reasons[0]}`;
      }

      return `Mohon Maaf, verifikasi anda ditolak. ${reasons[0]}`;
    }

    const combinedReason = `${reasons.slice(0, -1).join(', ')} and ${reasons[reasons.length - 1]}`;

    if (lang === 'en') {
      return `Your verification is rejected because ${combinedReason}`;
    }
    return `Mohon Maaf, verifikasi anda ditolak karena ${combinedReason}`;
  };

  return {
    getCitiesBasedOnProvince,
    getDistrictsBasedOnCity,
    getSubDistrictsBasedOnDistrict,
    getKycStateTransitionData,
    getRejectionJson,
    generateReason
  };
};

export default useKyc;
