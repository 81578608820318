import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import BasicTable from 'components/molecules/BasicTable';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTask from 'components/molecules/KycTask';
import ListDialog from 'components/molecules/ListDialog';
import PrivyDigitalCertificate from 'components/molecules/PrivyDigitalCertificate';
import RejectionReasonContent from 'components/molecules/RejectionReason';
import { kyc2RejectionOptionsInEn, kyc2RejectionOptionsInId } from 'constants/rejectionOptions';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeRequest } from 'services';
import { useKyc, useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';

const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];
const PersonalData = ({
  startTask,
  startInterval,
  endTask,
  escalateTask,
  ktpOcrData: ktpOcr,
  escalate,
  kyc2Data: kycData,
  kyc1Data,
  setKyc2Data,
  setEscalate,
  isAutoAssign = true
}) => {
  const [, setLoader] = useLoader();
  const [personalState, setPersonalState] = useState({
    kycData: {},
    kyc1Data: {},
    userId: '',
    bahasaOptions: [],
    englishOptions: [],
    signature: '',
    nationality: '',
    rejectionReasonLogData: []
  });
  const [showDetail, setShowDetail] = useState(false);
  const [, dispatch] = useStateValue();
  const [showRejectionModal, setRejectionModal] = useState(false);
  const { showNotification } = useNotification();
  const { getRejectionJson, generateReason } = useKyc();

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const getRejectionReasonLog = () => {
    if (!personalState.rejectionReasonLogData.length > 0) {
      getRejectionReasonData(kycData.userId).then((result) => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          setPersonalState((prevState) => ({
            ...prevState,
            rejectionCount: result.data.data.length,
            rejectionReasonLogData: result.data.data
          }));
          openRejectionReasonDialog(result.data.data);
        } else {
          showNotification('error', 'No rejection reason logs found');
        }
      });
    } else openRejectionReasonDialog(personalState.rejectionReasonLogData);
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };

  const getRejectionReasonData = (userId) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.KYC2}/personalDataStateTransition/`,
      params: {
        userId,
        toState: 'REJECTED'
      }
    })
      .then((result) => result)
      .finally(() => setLoader(false));
  };

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const updatedStatus = (kycStatus) => {
    let reasonEn = generateReason('en', personalState);
    let reasonIn = generateReason('in', personalState);
    const rejectionReasonJson = getRejectionJson(personalState);

    if (kycStatus === 'REJECTED' && !reasonEn && !reasonIn) {
      showNotification('error', 'Rejection reason is required');
      return;
    }
    let data = {
      userId: kycData.userId,
      kyc2Status: kycData.status,
      rejectionReason: reasonEn,
      rejectionReasonId: reasonIn,
      personalDataStatus: kycStatus,
      rejectionReasonJson: JSON.stringify(rejectionReasonJson),
      nationality: kyc1Data.nationality,
      clientId: kycData.clientId
    };
    if (isAutoAssign) {
      data['isKycAutoAssignPage'] = true;
    }
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC2}/personalDataStatus/`,
      method: 'PUT',
      data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          showNotification('success', 'Status updated');
          setKyc2Data((prevState) => ({
            ...prevState,
            personalDataStatus: kycStatus
          }));

          endTask?.();
          startInterval?.();
          handleDropdown(false);
        }
      })
      .finally(() => setLoader(false));
  };

  const escalatePersonalTask = () => {
    escalateTask('PERSONAL_VERIFICATION', () => {
      setEscalate((prevState) => ({
        ...prevState,
        iskyc2Escalated: true
      }));
    });
  };

  const closeRejectionDialog = () => {
    setRejectionModal(false);
    setPersonalState((prevState) => ({
      ...prevState,
      englishOptions: [],
      bahasaOptions: []
    }));
  };

  const personalTableData = [
    { label: 'Occupation', value: kycData ? kycData.occupation : '' },
    { label: 'Income Level', value: kycData ? kycData.incomeLevel : '' },
    { label: 'Marital Status', value: kycData ? kycData.maritalStatus : 'N/A' },
    { label: 'Fund Source', value: kycData ? kycData.sourceOfFund : '' },
    { label: 'Religion', value: kycData ? kycData.religion : 'N/A' },
    {
      label: 'Investment Reason Purpose',
      value: kycData ? kycData.reasonAndPurposeOfInvestment : 'N/A'
    },
    {
      label: 'Rejection Reason',
      value:
        kycData.status !== 'APPROVED' && kycData.personalDataRejectionReasonJson
          ? getRejectionReasonInIndonesianLang(kycData.personalDataRejectionReasonJson)
          : ''
    },
    { label: 'Gender', value: kycData ? kycData.gender : '' },
    { label: 'Education Background', value: kycData ? kycData.educationBackground : '' },
    { label: 'SID', value: kycData ? kycData.sid : 'N/A' },
    { label: 'IFUA', value: kycData ? kycData.ifua : 'N/A' },
    {
      label: 'Last Upload Time',
      value: kycData.lastUploadTime ? dateFormatter(kycData.lastUploadTime) : ''
    },
    { label: 'Last Update Time', value: kycData.updatedAt ? dateFormatter(kycData.updatedAt) : '' }
  ];

  const shouldEnable =
    kyc1Data.status === 'UPLOADED' &&
    isAutoAssign &&
    !escalate.iskyc1Escalated &&
    kyc1Data.dualApprovalStatus !== 'NEED_APPROVAL';

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.iskyc2Escalated && (
        <Typography sx={{ border: 1, color: 'red', mr: 2, p: '2px' }}>ESCALATED</Typography>
      )}
      {kycData && kycData.personalDataStatus === 'VERIFIED' ? (
        <Typography sx={{ color: 'green' }}>{kycData.personalDataStatus}</Typography>
      ) : (
        <Typography>{kycData.personalDataStatus}</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    if (kycData.personalDataStatus === 'PENDING' && !escalate.iskyc2Escalated && isAutoAssign) {
      startTask('PERSONAL_VERIFICATION');
    }
    handleDropdown(showDetail);
  };

  const isTaskDisabled =
    kycData.status === 'NOT UPLOADED' || (shouldEnable && kycData.personalDataStatus === 'PENDING');
  const isPersonalDataIncomplete = kycData.personalDataStatus === 'PARTIAL_UPLOAD';

  return (
    <KycTask
      title="Personal Data"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={personalTableData} />
      <Box my={2}>
        <Box sx={{ margin: '0 auto', textAlign: 'center' }}>
          {kycData.personalDataStatus !== 'VERIFIED' && (
            <Button
              color="success"
              size="small"
              variant="contained"
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL,
                  modalContent: (
                    <AlertDialog
                      title="Verify Personal Data"
                      alertMessage="Are you sure want to verify?"
                      onConfirm={() => updatedStatus('VERIFIED')}
                    />
                  )
                })
              }
              disabled={(escalate.iskyc2Escalated && isAutoAssign) || isPersonalDataIncomplete}>
              Approve
            </Button>
          )}

          <Button
            sx={{ ml: 2 }}
            color="error"
            size="small"
            variant="contained"
            onClick={() => setRejectionModal(true)}
            disabled={(escalate.iskyc2Escalated && isAutoAssign) || isPersonalDataIncomplete}>
            Reject
          </Button>
          {kycData.personalDataStatus === 'PENDING' && isAutoAssign && (
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              size="small"
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL,
                  modalContent: (
                    <AlertDialog
                      title="Escalate Personal Data"
                      alertMessage="Are you sure want to escalate?"
                      onConfirm={escalatePersonalTask}
                    />
                  )
                })
              }
              disabled={escalate.iskyc2Escalated}>
              {escalate.iskyc2Escalated ? 'Escalated' : 'Escalate'}
            </Button>
          )}
          <Button sx={{ ml: 2 }} variant="contained" size="small" onClick={getRejectionReasonLog}>
            Rejection reason logs
          </Button>
        </Box>
      </Box>
      <Typography sx={{ mt: 3 }} variant="h6">
        Signature
      </Typography>
      {kyc1Data?.digitalSignatureStatus && <PrivyDigitalCertificate kycData={kyc1Data} />}
      {kycData.id > 0 ? (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={6}>
            {kycData.signature && <ImageMagnifier url={kycData.signature} />}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ color: 'red', textAlign: 'center', py: 2 }}>No Data Found</Box>
      )}
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: '800px',
              maxWidth: '800px'
            }
          }
        }}
        open={showRejectionModal}
        onClose={closeRejectionDialog}>
        <AlertDialog
          title="Reject Personal Data"
          alertMessage="Reason?"
          onClose={closeRejectionDialog}
          onConfirm={() => updatedStatus('REJECTED')}>
          <RejectionReasonContent
            englishOptions={personalState.englishOptions}
            bahasaOptions={personalState.bahasaOptions}
            rejectionOptionsInEn={kyc2RejectionOptionsInEn}
            rejectionOptionsInId={kyc2RejectionOptionsInId}
            setRejectionReasonState={setPersonalState}
          />
        </AlertDialog>
      </Dialog>
    </KycTask>
  );
};

PersonalData.propTypes = {
  startTask: PropTypes.func,
  endTask: PropTypes.func,
  startInterval: PropTypes.func,
  escalateTask: PropTypes.func,
  escalate: PropTypes.object,
  kyc2Data: PropTypes.object,
  ktpOcrData: PropTypes.object,
  kyc1Data: PropTypes.object,
  setKyc2Data: PropTypes.func,
  setEscalate: PropTypes.func,
  isAutoAssign: PropTypes.bool
};

export default PersonalData;
