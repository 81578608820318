import Joi from 'joi';

export const gssPalnSchema = Joi.object({
  globalStockIndex: Joi.string().required(),
  globalStockId: Joi.number().integer().required(),
  margin: Joi.number().min(0).max(10).precision(2).required(),
  commissionPercentage: Joi.number().required(),
  ppnPercentage: Joi.number().required(),
  exchangeFee: Joi.number().required(),
  regFeeMultiplier: Joi.number().required(),
  tafFeeMultiplier: Joi.number().required(),
  promo: Joi.number().required()
});
