import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from './APIService';

let PartnerAPIKeyService = {};

PartnerAPIKeyService.generateApiKey = (partnerId) => {
  return new Promise((resolve, reject) => {
    makeRequest({
      method: 'post',
      url: REMOTE.API_KEYS.replace(':partnerId', partnerId)
    })
      .then((result) => {
        resolve({ apiKey: result.data.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

PartnerAPIKeyService.deleteApiKey = (partnerId, apiKeyId) => {
  return new Promise((resolve, reject) => {
    makeRequest({
      method: 'delete',
      url: REMOTE.API_KEYS.replace(':partnerId', partnerId) + '/:id'.replace(':id', apiKeyId)
    })
      .then((result) => {
        if (result.data.success) {
          resolve();
        } else {
          reject(new Error('Ops! Something went wrong'));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

PartnerAPIKeyService.getApiKeys = (partnerId) => {
  return new Promise((resolve, reject) => {
    makeRequest({
      url: REMOTE.API_KEYS.replace(':partnerId', partnerId)
    })
      .then((result) => {
        resolve({ apiKeys: result.data.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default PartnerAPIKeyService;
