import { LOCAL } from 'constants/clientRoutes';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useStateValue } from 'utils/redux';

const RequireAuth = () => {
  const [appState] = useStateValue();
  const location = useLocation();

  return appState.loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={LOCAL.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
