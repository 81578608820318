import Joi from 'joi';

export const indonesiaStocksSchema = Joi.object({
  stockType: Joi.string().valid('Regular Stock', 'Warrant', 'Structured Warrant').required(),
  icon: Joi.any().invalid('').required(),
  sellIcon: Joi.any().invalid('').required(),
  splitIcon: Joi.any().invalid('').required(),
  buyIcon: Joi.any().invalid('').required(),
  bonusIcon: Joi.any().invalid('').required(),
  name: Joi.string().required(),
  ipoPrice: Joi.string().required(),
  sector: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.strip(),
    otherwise: Joi.string().required()
  }),
  registeredInAccelerationBoard: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.strip(),
    otherwise: Joi.boolean().required()
  }),
  code: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.string()
      .custom((value, helpers) => {
        const trimmedValue = value.trim();
        if (trimmedValue.length !== 10) {
          return helpers.message('Structured Warrant code should be 10 characters long');
        }
        const characters = trimmedValue.split('');
        const warrantType = characters[6];
        const expiryMonth = characters[7];
        const expiryYear = characters[8];
        if (['C', 'P'].indexOf(warrantType) === -1) {
          return helpers.message(
            'Structured Warrant code should have C (call) or P (put) as the 7th character'
          );
        }
        if (
          ['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z'].indexOf(expiryMonth) === -1
        ) {
          return helpers.message(
            'Structured Warrant code should have valid month as the 8th character'
          );
        }
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(expiryYear) === -1) {
          return helpers.message(
            'Structured Warrant code should have valid year as the 9th character'
          );
        }
        return value;
      })
      .required(),
    otherwise: Joi.string().required()
  }),
  listedShares: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.strip(),
    otherwise: Joi.string().required()
  }),
  isBank: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.strip(),
    otherwise: Joi.boolean().required()
  }),
  tradeStartDate: Joi.when('stockType', {
    is: Joi.string().valid('Warrant', 'Structured Warrant'),
    then: Joi.date().required(),
    otherwise: Joi.strip()
  }),
  tradeEndDate: Joi.when('stockType', {
    is: Joi.string().valid('Warrant', 'Structured Warrant'),
    then: Joi.date().required(),
    otherwise: Joi.strip()
  }),
  exerciseStartDate: Joi.when('stockType', {
    is: Joi.string().valid('Warrant'),
    then: Joi.date().required(),
    otherwise: Joi.strip()
  }),
  exerciseEndDate: Joi.when('stockType', {
    is: Joi.string().valid('Warrant'),
    then: Joi.date().required(),
    otherwise: Joi.strip()
  }),
  exercisePrice: Joi.when('stockType', {
    is: Joi.string().valid('Warrant'),
    then: Joi.number().required(),
    otherwise: Joi.strip()
  }),
  expiryDate: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.date().required(),
    otherwise: Joi.strip()
  }),
  executionPrice: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.number().required(),
    otherwise: Joi.strip()
  }),
  executionRatio: Joi.when('stockType', {
    is: Joi.string().valid('Structured Warrant'),
    then: Joi.string()
      .custom((value, helpers) => {
        const [numerator, denominator] = value.trim().split(':');
        if (!numerator || !denominator) {
          return helpers.message('Execution ratio must be in format of numerator:denominator');
        }
        if (isNaN(Number(numerator.trim())) || isNaN(Number(denominator.trim()))) {
          return helpers.message('Execution ratio must be in format of number:number');
        }
        if (numerator && denominator) {
          return value;
        }
        return helpers.message('Execution ratio must be in format of numerator:denominator');
      })
      .required(),
    otherwise: Joi.strip()
  })
});
