import { Box, Drawer, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';
import SidebarMenu from './SidebarMenu';

const Sidebar = ({ drawerWidth }) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        '@media print': { display: 'none' },
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          backgroundColor: '#303336',
          color: '#adadad'
        },
        [`.MuiBox-root`]: { paddingTop: '20px' }
      }}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <SidebarMenu />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  drawerWidth: PropTypes.number.isRequired
};

export default Sidebar;
