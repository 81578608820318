import { Box, Typography } from '@mui/material';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import PropTypes from 'prop-types';

const NoContentFound = ({ title, description, src }) => {
  return (
    <Container sx={{ px: 4 }} align="center">
      <Item xs={12}>
        <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 }
          }}
          alt="Error image"
          src={src}
        />
      </Item>
      {title && (
        <Item xs={12} sx={{ mt: 1 }}>
          <Typography gutterBottom variant="h4">
            {title}
          </Typography>
        </Item>
      )}
      <Item xs={12} sx={{ mt: 1 }}>
        <Typography variant="body1">{description}</Typography>
      </Item>
    </Container>
  );
};

NoContentFound.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  src: PropTypes.string
};

export default NoContentFound;
