import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import { REMOTE } from 'constants/serverRoutes';
import PropTypes from 'prop-types';
import { makeRequest } from 'services';
import { useLoader } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';

const AutoAssignHeader = ({ caseId, getCasesId, timer, exitCase, resetState, loading }) => {
  const [, setLoader] = useLoader();
  const [, dispatch] = useStateValue();

  const taskMap = {
    BANK_ACCOUNT: 'Bank Accounts',
    BASIC_VERIFICATION: 'Basic Verification',
    OCCUPATION: 'Occupation',
    OCR_VERIFICATION: 'OCR Verification',
    PERSONAL_VERIFICATION: 'Personal Verification'
  };

  const handleResponse = (data) => {
    let alertText = null;
    if (data.startsWith('{')) {
      const resObject = JSON.parse(data);
      const unfinishedTasks = Object.keys(resObject)
        .filter((k) => !resObject[k])
        .filter((x) => x !== 'isKycCaseFinished');
      alertText = (
        <>
          <Typography>You have not yet finished the following tasks:</Typography>
          {unfinishedTasks.map((task) => (
            <Typography key={task}>{taskMap[task]}</Typography>
          ))}
        </>
      );
    } else alertText = <Typography>{data}</Typography>;
    return alertText;
  };

  const finishCase = () => {
    setLoader(true);
    return makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC_AUTO}/finishKycCase/userId/${caseId}`,
      data: {
        isKycAutoAssignPage: true
      }
    })
      .then((result) => {
        if (result.data.data && result.data.data.isKycCaseFinished) {
          timer.cleanUp();
          resetState();
        } else {
          const alertString = handleResponse(JSON.stringify(result.data.data));
          dispatch({
            type: OPEN_MODAL,
            modalContent: (
              <AlertDialog
                alertMessage={alertString}
                showCancel={false}
                confirmText="OK"
                onConfirm={() => null}
              />
            )
          });
        }
      })
      .finally(() => setLoader(false));
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              size="small"
              disabled={caseId !== null || loading}
              onClick={() => getCasesId(true)}
              variant="contained">
              Get Case
            </Button>
            <Button
              color="error"
              size="small"
              variant="contained"
              disabled={caseId === null}
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL,
                  modalContent: (
                    <AlertDialog
                      title="Exit Case"
                      alertMessage="Are you sure want to exit the case?"
                      onConfirm={exitCase}
                    />
                  )
                })
              }>
              Exit
            </Button>
          </Box>
          <Button size="small" variant="contained" disabled={caseId === null} onClick={finishCase}>
            Finish Case
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

AutoAssignHeader.propTypes = {
  caseId: PropTypes.number,
  getCasesId: PropTypes.func.isRequired,
  timer: PropTypes.object.isRequired,
  exitCase: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default AutoAssignHeader;
