import { Done, PriorityHigh } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeRequest } from 'services';
import { useLoader } from 'services/hooks';
import BasicTable from '../BasicTable';

const PrivyDigitalCertificate = ({ kycData }) => {
  const [vendorRegistrationResponse, setVendorRegistrationResponse] = useState(
    kycData?.vendorRegistrationResponse
  );
  const [, setLoader] = useLoader();

  const checkPrivyRegistrationStatus = () => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/privyRegistrationStatus`,
      params: {
        userId: kycData?.userId,
        transactionId: kycData?.vendorTransactionId
      }
    })
      .then((result) => {
        if (result?.data?.data) {
          setVendorRegistrationResponse((prevState) => ({
            ...prevState,
            success: { data: result?.data?.data }
          }));
        } else {
          setVendorRegistrationResponse((prevState) => ({
            ...prevState,
            error: result?.data?.message
          }));
        }
      })
      .finally(() => setLoader(false));
  };

  const signatureData = [
    { label: 'Digital Certificate ID', value: vendorRegistrationResponse?.success?.data?.privyId || '-' },
    { label: 'User Token', value: vendorRegistrationResponse?.success?.data?.userToken || '-' },
    {
      label: 'Processed At',
      value: dateFormatter(vendorRegistrationResponse?.success?.data?.processedAt) || '-'
    },
    {
      label: 'Message',
      value: vendorRegistrationResponse?.success?.message || vendorRegistrationResponse?.error
    }
  ];

  const DigitalCertificateStatus = () => (
    <>
      {kycData?.digitalSignatureStatus === 'SUCCESS' ? (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {vendorRegistrationResponse?.success ? (
            <>
              <Done color="success" size="small" />
              <Typography color="green" variant="subtitle1">
                User is verified with Digital Certificate Verified
              </Typography>
            </>
          ) : (
            <>
              <PriorityHigh color="error" size="small" />
              <Typography color="error" variant="subtitle1">
                Action needed to obtain Digital Certificate details
              </Typography>
            </>
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <PriorityHigh color="error" size="small" />
          <Typography color="error" variant="subtitle1">
            Signature manual verification is needed
          </Typography>
        </Box>
      )}
    </>
  );

  return (
    <>
      <DigitalCertificateStatus />
      <Divider sx={{ my: 2 }} />
      {(kycData?.digitalSignatureStatus === 'SUCCESS' ||
        kycData?.digitalSignatureStatus === 'FAILED') &&
        (vendorRegistrationResponse?.success || vendorRegistrationResponse?.error ? (
          <BasicTable tableData={signatureData} />
        ) : (
          <>
            {kycData?.digitalSignatureStatus === 'SUCCESS' && (
              <Button variant="outlined" size="small" onClick={checkPrivyRegistrationStatus}>
                Get Digital Certificate Details
              </Button>
            )}
          </>
        ))}
    </>
  );
};

PrivyDigitalCertificate.propTypes = {
  kycData: PropTypes.object.isRequired
};

export default PrivyDigitalCertificate;
