import { Box } from '@mui/material';
import React from 'react';

const RedStar = () => {
  return (
    <Box component="span" sx={{ color: 'red' }}>
      *
    </Box>
  );
};

export default RedStar;
