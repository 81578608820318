import { MAX_INT_VALIDATION } from 'config';
import {
  ADMIN_CONTACT_METHOD,
  FEE_ASSET_CATEGORY,
  MEMBER_QUOTA_TYPE
} from 'containers/as/influencerFees/constants';
import { JoiNumber } from 'helpers/joiExtensions';
import Joi from 'joi';

const feeCheckValidation = (value, helpers, feeAssetCategory) => {
  const { state } = helpers;
  const { ancestors } = state;
  if (ancestors[0].feeAssetCategory.includes(feeAssetCategory)) {
    if (value === undefined || value === '') {
      return helpers.error('any.required');
    }
    if (value < 0) {
      return helpers.error('invalid');
    }
    return Number(value);
  }
  return value;
};

const upsertCommunityCode = {
  minimumNivIdr: JoiNumber.normalizeValue().positive(),
  commitmentDays: Joi.number().integer().positive().max(2000),
  adminContactMethod: Joi.string().valid(...Object.values(ADMIN_CONTACT_METHOD)),
  adminEmail: Joi.alternatives().conditional('adminContactMethod', [
    {
      is: ADMIN_CONTACT_METHOD.EMAIL,
      then: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
      otherwise: Joi.string()
        .email({ tlds: { allow: false } })
        .allow('', null)
    }
  ]),
  adminWhatsapp: Joi.alternatives().conditional('adminContactMethod', [
    {
      is: ADMIN_CONTACT_METHOD.WHATSAPP,
      then: Joi.string()
        .pattern(/^[+0-9]+$/)
        .min(8)
        .max(15)
        .required(),
      otherwise: Joi.string().allow('', null)
    }
  ]),
  memberQuotaLimit: Joi.alternatives().conditional('memberQuotaType', {
    is: MEMBER_QUOTA_TYPE.UNLIMITED,
    then: Joi.number().integer().default(MAX_INT_VALIDATION),
    otherwise: Joi.number().integer().min(1).max(MAX_INT_VALIDATION)
  }),
  memberQuotaType: Joi.string().valid(...Object.values(MEMBER_QUOTA_TYPE))
};

export const addCommunityCodeSchema = Joi.object({
  communityName: Joi.string(),
  code: Joi.string().length(10).alphanum().uppercase(),
  feeAssetCategory: Joi.array()
    .items(Joi.string().valid(...Object.values(FEE_ASSET_CATEGORY)))
    .min(1)
    .required(),
  gssFeePercent: Joi.custom((value, helpers) =>
    feeCheckValidation(value, helpers, FEE_ASSET_CATEGORY.GSS)
  ).messages({
    invalid: 'Gss fee rate cannot be a negative number'
  }),
  forexFeePercent: Joi.custom((value, helpers) =>
    feeCheckValidation(value, helpers, FEE_ASSET_CATEGORY.FOREX)
  ).messages({
    invalid: 'Forex fee rate cannot be a negative number'
  }),
  instantOrderFeePercent: Joi.custom((value, helpers) =>
    feeCheckValidation(value, helpers, FEE_ASSET_CATEGORY.CRYPTO)
  ).messages({
    invalid: 'Instant order fee rate cannot be a negative number'
  }),
  makerTransactionOrderFeePercent: Joi.custom((value, helpers) =>
    feeCheckValidation(value, helpers, FEE_ASSET_CATEGORY.CRYPTO)
  ).messages({
    invalid: 'Maker transaction order fee rate cannot be a negative number'
  }),
  takerTransactionOrderFeePercent: Joi.custom((value, helpers) =>
    feeCheckValidation(value, helpers, FEE_ASSET_CATEGORY.CRYPTO)
  ).messages({
    invalid: 'Taker transaction order fee rate cannot be a negative number'
  }),
  ...upsertCommunityCode
});

export const editCommunityCodeSchema = Joi.object({
  ...upsertCommunityCode
});
