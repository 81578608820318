/* eslint-disable */
function ktpOcrBasicValidation(data) {
  const { nameOnIdentityCard, identityNumber, countryOfBirth, dateOfBirth, isForeigner } = data;
  function checkAge(userDOB) {
    const today = new Date();
    const inputDate = new Date(userDOB);
    let age = today.getFullYear() - inputDate.getFullYear();
    const m = today.getMonth() - inputDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < inputDate.getDate())) {
      age--;
    }
    return age;
  }
  const errors = [];
  const dateRegex = new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'g');
  const nameRegex = new RegExp(/^[a-zA-Z \-\,\.\']+$/, 'g');
  if (!nameRegex.test(nameOnIdentityCard)) {
    errors.push({ title: 'Invalid name', body: 'Invalid name on identity card' });
  }
  if (!countryOfBirth) {
    errors.push({
        title: `Country of Birth is ${countryOfBirth}`,
        body: 'Please first provide the Country of Birth in OCR data'
    });
  }
  if (!isForeigner) {
    const nikRegex = new RegExp(/^[0-9]{16}$/, 'g');
    if (!nikRegex.test(identityNumber)) {
      errors.push({ title: 'Invalid NIK', body: 'Invalid identity number' });
    }

    if (!dateOfBirth) {
      errors.push({ title: 'Invalid date of birth', body: "Date of birth can't be empty" });
    }
    if(dateOfBirth && !dateRegex.test(dateOfBirth)){
      errors.push({ title: 'Invalid date of birth' });
    }
  } else {
    const passportRegex = new RegExp(/^[A-Za-z0-9]{6,9}$/, 'g');
    if (!passportRegex.test(identityNumber)) {
      errors.push({ title: 'Invalid Passport Number', body: 'Invalid passport number' });
    }

    if (!dateOfBirth) {
      errors.push({ title: 'Invalid date of birth', body: "Date of birth can't be empty" });
    }

    if(dateOfBirth && !dateRegex.test(dateOfBirth)){
      errors.push({ title: 'Invalid date of birth' });
    }

    const userAge = checkAge(dateOfBirth);
    if (userAge < 17) {
      errors.push({
        title: 'Invalid date of birth',
        body: 'User must be greater than or equal to 17'
      });
    }
  }
  return errors;
}

export default ktpOcrBasicValidation;
