import { MIN_IDR_AMOUNT, REWARD_TYPE } from 'containers/ops/referral/constant';
import Joi from 'joi';

const minAmountCustomValidation = (value, helpers) => {
  const {
    state: { ancestors }
  } = helpers;
  const config = ancestors[3].rewardConfig;
  const curr = ancestors[0];
  const symbol = curr?.META?.SYMBOL;
  if (value < config?.[symbol]) {
    throw new Error(`it should be > ${config[symbol]}`);
  }
  return value;
};

export const referralAdjustmentSchema = Joi.object({
  type: Joi.string(),
  amount: Joi.number().min(MIN_IDR_AMOUNT).required().label('IDR_AMOUNT'),
  invitersProbability: Joi.array().items(
    Joi.object({
      IDR_REWARD: Joi.number().integer().min(1).required().label('IDR_REWARD')
    })
  ),
  invitedProbability: Joi.array().items(
    Joi.object({
      IDR_REWARD: Joi.number().integer().min(1).required().label('IDR_REWARD')
    })
  ),
  invitersValue: Joi.array().items(
    Joi.object({
      IDR_REWARD: Joi.object({
        IDR_AMOUNT: Joi.alternatives().conditional('TYPE', {
          is: REWARD_TYPE.IDR,
          then: Joi.number().min(MIN_IDR_AMOUNT).required().label('Amount'),
          otherwise: Joi.number()
            .label('Amount')
            .custom((values, helpers) => minAmountCustomValidation(values, helpers))
            .messages({
              'custom.invalid': 'Amount > minimumBuyAmount'
            })
        }),
        TYPE: Joi.string(),
        META: Joi.object({
          SYMBOL: Joi.string()
        })
      })
    })
  ),
  invitedValue: Joi.array().items(
    Joi.object({
      IDR_REWARD: Joi.object({
        IDR_AMOUNT: Joi.alternatives().conditional('TYPE', {
          is: REWARD_TYPE.IDR,
          then: Joi.number().min(MIN_IDR_AMOUNT).required().label('Amount'),
          otherwise: Joi.number()
            .label('Amount')
            .custom((values, helpers) => minAmountCustomValidation(values, helpers))
            .messages({
              'custom.invalid': 'Amount > minimumBuyAmount'
            })
        }),
        TYPE: Joi.string(),
        META: Joi.object({
          SYMBOL: Joi.string()
        })
      })
    })
  ),
  rewardConfig: Joi.object().optional()
});
