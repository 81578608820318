export const REWARD_TYPE = {
  CRYPTOCURRENCY: 'CRYPTOCURRENCY',
  IDR: 'IDR'
};

export const TRANSACTION_TYPE = {
  SINGLE: 'SINGLE',
  CUMULATIVE: 'CUMULATIVE'
};

export const PROBABILITY_MATRIX = 'PROBABILITY_MATRIX';
export const VALUES_MAP = 'VALUES_MAP';
export const MIN_IDR_AMOUNT = 5000;
