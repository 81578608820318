import { Box, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';

const BasicDetailsForm = ({
  nameOnIdentityCard,
  identityNumber,
  dateOfBirth,
  setBasicDetailsState
}) => {
  const handleChange = (event) => {
    let { name, value } = event.target;
    setBasicDetailsState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Box>
      <TextField
        fullWidth
        margin="normal"
        size="small"
        label="Name on Identity Card"
        value={nameOnIdentityCard}
        name="nameOnIdentityCard"
        onChange={(e) => handleChange(e)}
      />
      <TextField
        fullWidth
        margin="normal"
        size="small"
        label="Identity Number"
        value={identityNumber}
        name="identityNumber"
        onChange={(e) => handleChange(e)}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Date of Birth"
          inputFormat="dd/MM/yyyy"
          disableFuture={true}
          value={dateOfBirth}
          onChange={(date) => {
            setBasicDetailsState((prevState) => ({
              ...prevState,
              dateOfBirth: date
            }));
          }}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
        />
      </LocalizationProvider>
    </Box>
  );
};

BasicDetailsForm.propTypes = {
  nameOnIdentityCard: PropTypes.string,
  identityNumber: PropTypes.string,
  dateOfBirth: PropTypes.instanceOf(Date),
  setBasicDetailsState: PropTypes.func
};

export default BasicDetailsForm;
