/* eslint-disable */
function ktpOCRValidation(data) {
  const {
    id,
    citizenship,
    dateOfBirth,
    nameOnIdentityCard,
    identityNumber,
    placeOfBirth,
    occupation,
    religion,
    gender,
    bloodType,
    maritalStatus,
    addressJson,
    expirationDate,
    issueDate
  } = data;
  const errors = [];

  if (!id) {
    errors.push({ title: 'Invalid KtpOcr Id' });
  }

  const citizenshipRegex = new RegExp(/^[A-Za-z]+$/, 'g');
  if (!citizenshipRegex.test(citizenship)) {
    errors.push({ title: 'Invalid citizenship', body: 'Citizenship should be in letter only' });
  }

  if (!dateOfBirth) {
    errors.push({ title: `Value of OCR DoB is ${dateOfBirth}`, body: 'Please first fill the OCR DoB in Basic Verification data' });
  }

  const nameRegex = new RegExp(/^[a-zA-Z \-\,\.\']+$/, 'g');
  if (!nameRegex.test(nameOnIdentityCard) || nameOnIdentityCard === 'N/A') {
    errors.push({
      title: `Name on identity card - ${nameOnIdentityCard} is invalid`,
      body: 'Please correct the name in Basic Verification data'
    });
  }

  const nikRegex = new RegExp(/^[0-9]{16}$/, 'g');
  if (!nikRegex.test(identityNumber) || identityNumber === 'N/A') {
    errors.push({
      title: `NIK - ${identityNumber} is invalid`,
      body: 'Please correct the NIK in Basic Verification data'
    });
  }

  const pobRegex = new RegExp(/^[A-Za-z0-9 ]+$/, 'g');
  if (!pobRegex.test(placeOfBirth)) {
    errors.push({
      title: 'Invalid place of birth',
      body: 'Place of birth should be in alphanumeric'
    });
  }

  const addressRegex = new RegExp(/^[a-zA-Z0-9\s,.\/-]{1,}$/, 'g');
  if (!addressRegex.test(addressJson.address)) {
    errors.push({
      title: 'Invalid address',
      body: 'Address can\'t be empty and only allowed . - / , as special characters'
    });
  }

  const rtRwRegex = new RegExp(/[0-9]{3}[\/]{1}[0-9]{3}$/, 'g');
  if (!rtRwRegex.test(addressJson.rtRw)) {
    errors.push({ title: 'Invalid RT/RW', body: 'RT/RW should be in numeric and in format xxx/xxx' });
  }

  if (!addressJson.province) {
    errors.push({ title: 'Invalid provinde', body: "Province can't be empty" });
  }

  if (!addressJson.city) {
    errors.push({ title: 'Invalid city', body: "City can't be empty" });
  }

  if (!addressJson.district) {
    errors.push({ title: 'Invalid district', body: "District can't be empty" });
  }

  if (!addressJson.subDistrict) {
    errors.push({ title: 'Invalid subDistrict', body: "Subdistrict can't be empty" });
  }

  if (!occupation) {
    errors.push({ title: 'Invalid occupation', body: "Occupation can't be empty" });
  }

  if (!religion) {
    errors.push({ title: 'Invalid religion', body: "Religion can't be empty" });
  }

  if (!gender) {
    errors.push({ title: 'Invalid gender', body: "Gender can't be empty" });
  }

  const dateRegex = new RegExp(/([1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, 'g');
  if (!expirationDate || !dateRegex.test(expirationDate)) {
    errors.push({ title: 'Invalid expiration Date' });
  }
  const issueDateRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/, 'g');
  if (!issueDate || !issueDateRegex.test(issueDate)) {
    errors.push({ title: 'Invalid issue Date' });
  }

  if (!bloodType) {
    errors.push({ title: 'Invalid blood type', body: "Blood type can't be empty" });
  }

  if (!maritalStatus) {
    errors.push({ title: 'Invalid marital status', body: "Marital status can't be empty" });
  }

  return errors;
}

export default ktpOCRValidation;
