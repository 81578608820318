import { Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import KycTask from 'components/molecules/KycTask';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useState } from 'react';

const DisclosureTnc = ({ gssKycData }) => {
  const [showDetail, setShowDetail] = useState(false);

  const disclosureTncTableData = [
    {
      label: 'Disclosure Tnc',
      value: (gssKycData.disclosureTnc && gssKycData.disclosureTnc.toString()) || null
    },
    {
      label: 'Cdd Futures Brokerage Firm',
      value: gssKycData.cddFuturesBrokerageFirm ? (
        <S3DownloadLink url={gssKycData.cddFuturesBrokerageFirm} />
      ) : (
        ''
      )
    },
    {
      label: 'Cdd Statement Of Simulation',
      value: gssKycData.cddStatementOfSimulation ? (
        <S3DownloadLink url={gssKycData.cddStatementOfSimulation} />
      ) : (
        ''
      )
    },
    {
      label: 'Cdd Transaction Account Opening',
      value: gssKycData.cddTransactionAccountOpening ? (
        <S3DownloadLink url={gssKycData.cddTransactionAccountOpening} />
      ) : (
        ''
      )
    },
    {
      label: 'Cdd Notice Of Risk',
      value: gssKycData.cddNoticeOfRisk ? <S3DownloadLink url={gssKycData.cddNoticeOfRisk} /> : ''
    },
    {
      label: 'Cdd Statement Of Responsibility',
      value: gssKycData.cddStatementOfResponsibility ? (
        <S3DownloadLink url={gssKycData.cddStatementOfResponsibility} />
      ) : (
        ''
      )
    },
    {
      label: 'Cdd Mandate Agreement',
      value: gssKycData.cddMandateAgreement ? (
        <S3DownloadLink url={gssKycData.cddMandateAgreement} />
      ) : (
        ''
      )
    },
    {
      label: 'Cdd Terms Conditions',
      value: gssKycData.cddTermsConditions ? (
        <S3DownloadLink url={gssKycData.cddTermsConditions} />
      ) : (
        ''
      )
    },
    {
      label: 'Customer Registration Form',
      value: gssKycData.customerRegistrationForm ? (
        <S3DownloadLink url={gssKycData.customerRegistrationForm} />
      ) : (
        ''
      )
    },
    {
      label: 'Last Upload Time',
      value: gssKycData.lastUploadTime ? dateFormatter(gssKycData.lastUploadTime) : ''
    },
    {
      label: 'Last Update Time',
      value: gssKycData.updatedAt ? dateFormatter(gssKycData.updatedAt) : ''
    }
  ];

  const kycTaskStatus = () => (
    <Typography sx={{ color: 'green' }}>{gssKycData.disclosureTnc ? 'COMPLETED' : ''}</Typography>
  );

  const handleKycTaskClick = () => setShowDetail(!showDetail);

  const isTaskDisabled = gssKycData.status === 'NOT UPLOADED' || !gssKycData.disclosureTnc;

  return (
    <KycTask
      title="Disclosure"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={disclosureTncTableData} />
    </KycTask>
  );
};

DisclosureTnc.propTypes = {
  gssKycData: PropTypes.object
};

export default DisclosureTnc;
