import { Box, FormControl, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const RejectionReasonContent = ({
  englishOptions,
  bahasaOptions,
  rejectionOptionsInEn,
  rejectionOptionsInId,
  multiSelect = true,
  setRejectionReasonState
}) => {
  const handleEnglishChange = (value, meta) => {
    setRejectionReasonState((prevState) => ({
      ...prevState,
      englishOptions: multiSelect ? value : [value]
    }));

    if (meta.action !== 'create-option') {
      let inOptions = null;
      if (!multiSelect) {
        if (value && rejectionOptionsInId[value.index])
          inOptions = [rejectionOptionsInId[value.index]];
      } else {
        inOptions = [];
        if (value && value.length) {
          value.forEach((item) => {
            if (rejectionOptionsInId[item.index]) {
              inOptions.push(rejectionOptionsInId[item.index]);
            }
          });
        }
      }
      setRejectionReasonState((prevState) => ({
        ...prevState,
        bahasaOptions: inOptions
      }));
    }
  };

  const handleBahasChange = (value, meta) => {
    setRejectionReasonState((prevState) => ({
      ...prevState,
      bahasaOptions: multiSelect ? value : [value]
    }));
    if (meta.action !== 'create-option') {
      let enOptions = null;
      if (!multiSelect) {
        if (value && rejectionOptionsInEn[value.index])
          enOptions = [rejectionOptionsInEn[value.index]];
      } else {
        enOptions = [];
        if (value && value.length) {
          value.forEach((item) => {
            if (rejectionOptionsInEn[item.index]) {
              enOptions.push(rejectionOptionsInEn[item.index]);
            }
          });
        }
      }
      setRejectionReasonState((prevState) => ({
        ...prevState,
        englishOptions: enOptions
      }));
    }
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <InputLabel>Rejection Reason in English</InputLabel>
        <FormControl sx={{ m: 1 }} fullWidth>
          <CreatableSelect
            isMulti={multiSelect}
            value={englishOptions}
            onChange={handleEnglishChange}
            options={rejectionOptionsInEn}
          />
        </FormControl>
      </Box>
      <>
        <InputLabel>Rejection Reason in bahasa</InputLabel>
        <FormControl sx={{ m: 1 }} fullWidth>
          <CreatableSelect
            isMulti={multiSelect}
            value={bahasaOptions}
            onChange={handleBahasChange}
            options={rejectionOptionsInId}
          />
        </FormControl>
      </>
    </>
  );
};

RejectionReasonContent.propTypes = {
  englishOptions: PropTypes.array,
  bahasaOptions: PropTypes.array,
  setRejectionReasonState: PropTypes.func,
  message: PropTypes.string,
  rejectionOptionsInEn: PropTypes.array,
  rejectionOptionsInId: PropTypes.array,
  multiSelect: PropTypes.bool
};

export default RejectionReasonContent;
