import Joi from 'joi';

export const bankConfigBankAccountSchema = Joi.object({
  accountNumber: Joi.string().min(6).max(25).required().label('Account Number'),
  bankCode: Joi.string().required()
});

export const lookUpSchema = Joi.object({
  userId: Joi.string().required().label('User Id')
});
