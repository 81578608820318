import Joi from 'joi';

export const adminSchema = Joi.object({
  name: Joi.string().min(3).label('Name'),
  phone: Joi.string().min(8).max(15),
  email: Joi.string().email({ tlds: { allow: false } }),
  resetPassword: Joi.boolean(),
  password: Joi.alternatives().conditional('resetPassword', {
    is: true,
    then: Joi.string().min(8).required(),
    otherwise: Joi.string().allow('')
  }),
  confirmPassword: Joi.alternatives().conditional('resetPassword', {
    is: true,
    then: Joi.alternatives().conditional('password', {
      is: Joi.string().required(),
      then: Joi.string()
        .valid(Joi.ref('password'))
        .label('Password and confirm password must match')
        .min(8)
        .required(),
      otherwise: Joi.string().allow('')
    }),
    otherwise: Joi.string().allow('')
  }),
  roles: Joi.array().items().min(1),
  allowedPanels: Joi.array().items().min(1),
  active: Joi.string().valid('true', 'false')
});

export const adminRolesSchema = Joi.object({
  role: Joi.string().min(4).required()
});
