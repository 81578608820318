import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button, Link } from '@mui/material';
import FormTextField from 'components/molecules/FormTextField';
import { LOCAL } from 'constants/clientRoutes';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { login } from 'services/AuthService';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { USER_STATE } from 'utils/redux/actions';
import { loginSchema } from 'utils/schemas';

const Login = () => {
  const [, dispatch] = useStateValue();
  const { showNotification } = useNotification();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const methods = useForm({
    defaultValues: { email: '', password: '' },
    resolver: joiResolver(loginSchema)
  });

  const handleLogin = ({ email, password }) => {
    setIsButtonDisabled(true);
    login(email, password)
      .then((data) => {
        dispatch({
          type: USER_STATE,
          user: data.user,
          loggedIn: true
        });
        setIsButtonDisabled(false);
        showNotification('success', 'You are now logged in!', `Welcome ${data.user.name}`);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        showNotification('error', error?.error_message);
      });
  };

  return (
    <Box component="form" onSubmit={methods.handleSubmit(handleLogin)}>
      <FormTextField methods={methods} label="Email address" name="email" type="email" />
      <FormTextField methods={methods} label="Password" name="password" type="password" />
      <Box mt={1}>
        <Button size="small" variant="contained" type="submit" disabled={isButtonDisabled}>
          Submit
        </Button>
        <Link sx={{ ml: 3 }} component={RouterLink} to={LOCAL.FORGOT} underline="hover">
          Forgot Password?
        </Link>
      </Box>
    </Box>
  );
};

export default Login;
