import Joi from 'joi';

export const districtSubDistrictSchema = Joi.object({
  provinceCode: Joi.number().integer().required(),
  cityCode: Joi.number().integer().required(),
  tab: Joi.number().integer(),
  districtName: Joi.alternatives().conditional('tab', {
    is: 0,
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  districtId: Joi.alternatives().conditional('tab', {
    is: 0,
    then: Joi.alternatives().try(Joi.string(), Joi.number()).allow(''),
    otherwise: Joi.number().integer().required()
  }),
  subDistrictId: Joi.alternatives().conditional('tab', {
    is: 2,
    then: Joi.number().integer().required(),
    otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow('')
  }),
  subDistrictName: Joi.alternatives().conditional('tab', {
    is: 1,
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  postalCode: Joi.alternatives().conditional('tab', {
    is: 0,
    then: Joi.string().allow(''),
    otherwise: Joi.string().length(6).required()
  })
});
