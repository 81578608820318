import Joi from 'joi';

export const questionsSchema = Joi.object({
  questionAnswersData: Joi.array().items(
    Joi.object({
      questions: Joi.object({
        en: Joi.string().required().label('Queston').trim(),
        in: Joi.string().required().label('Queston').trim()
      }),
      answers: Joi.object({
        en: Joi.string().required().label('Answer').trim(),
        in: Joi.string().required().label('Answer').trim()
      })
    })
  ),
  newQuestionAnswerData: Joi.array()
    .items(
      Joi.object({
        questions: Joi.object({
          en: Joi.string().required().label('Queston').trim(),
          in: Joi.string().required().label('Queston').trim()
        }),
        answers: Joi.object({
          en: Joi.string().required().label('Answer').trim(),
          in: Joi.string().required().label('Answer').trim()
        })
      })
    )
    .required()
});
