import Joi from 'joi';

export const editBankAccountSchema = Joi.object({
  accountNumber: Joi.string(),
  bankCode: Joi.string(),
  accountHolderName: Joi.string(),
  userId: Joi.number().integer(),
  accountId: Joi.number().integer(),
  beneficiaryFullName: Joi.string(),
  irisName: Joi.string()
});
