import { BUTTON_ACTION, COINLABELS } from 'containers/fi/cryptoCurrency/coinStates/constants';
import Joi from 'joi';

const inputValidation = (value, helpers, inputFieldEn, inputFieldId) => {
  const {
    state: { ancestors, path }
  } = helpers;
  if (
    (ancestors[0][inputFieldEn] === '' && ancestors[0][inputFieldId] === '') ||
    (ancestors[0][inputFieldEn] !== '' && ancestors[0][inputFieldId] !== '')
  ) {
    return value;
  }
  return helpers.error('custom.invalid');
};

export const cryptoCurrencySafetyLabelSchema = Joi.object({
  label: Joi.string()
    .valid(...Object.values(COINLABELS))
    .required(),
  active: Joi.boolean().required(),
  endDate: Joi.date().required(),
  assetPageAnnouncement: Joi.object({
    active: Joi.boolean(),
    titleEn: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
      .messages({
        'custom.invalid': `Both the title,En and Id fields should be either empty or filled`
      }),
    titleId: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
      .messages({
        'custom.invalid': `Both the title, En and Id fields should be either empty or filled`
      }),
    endDate: Joi.date().required()
  })
    .allow(null)
    .required(),
  tradingButtonsAnnouncement: Joi.object({
    active: Joi.boolean().required(),
    titleEn: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
      .messages({
        'custom.invalid': `Both the title,En and Id fields should be either empty or filled`
      }),
    titleId: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
      .messages({
        'custom.invalid': `Both the title,En and Id fields should be either empty or filled`
      }),
    url: Joi.string().uri().allow(null, '').label('Url').required(),
    endDate: Joi.date().required()
  })
    .allow(null)
    .required(),
  fullPageAnnouncement: Joi.object({
    active: Joi.boolean().required(),
    endDate: Joi.date().required(),
    url: Joi.string().uri().allow(null, '').label('Url').required(),
    titleEn: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
      .messages({
        'custom.invalid': `Both the title,En and Id fields should be either empty or filled`
      }),
    titleId: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'titleEn', 'titleId'))
      .messages({
        'custom.invalid': `Both the title,En and Id fields should be either empty or filled`
      }),
    descriptionEn: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'descriptionEn', 'descriptionId'))
      .messages({
        'custom.invalid': `Both the description,En and Id fields should be either empty or filled`
      }),
    descriptionId: Joi.string()
      .allow('')
      .custom((value, helpers) => inputValidation(value, helpers, 'descriptionEn', 'descriptionEn'))
      .messages({
        'custom.invalid': `Both the description,En and Id fields should be either empty or filled`
      }),
    actionStepsDescId: Joi.string()
      .allow('')
      .custom((value, helpers) =>
        inputValidation(value, helpers, 'actionStepsDescEn', 'actionStepsDescId')
      )
      .messages({
        'custom.invalid': `Both the body,En and Id fields should be either empty or filled`
      }),
    actionStepsDescEn: Joi.string()
      .allow('')
      .custom((value, helpers) =>
        inputValidation(value, helpers, 'actionStepsDescEn', 'actionStepsDescId')
      )
      .messages({
        'custom.invalid': `Both the body,En and Id fields should be either empty or filled`
      }),
    actionStepsTitleEn: Joi.string()
      .allow('')
      .custom((value, helpers) =>
        inputValidation(value, helpers, 'actionStepsTitleEn', 'actionStepsTitleId')
      )
      .messages({
        'custom.invalid': `Both the sub-title,En and Id fields should be either empty or filled`
      }),
    actionStepsTitleId: Joi.string()
      .allow('')
      .custom((value, helpers) =>
        inputValidation(value, helpers, 'actionStepsTitleEn', 'actionStepsTitleId')
      )
      .messages({
        'custom.invalid': `Both the sub-title,En and Id fields should be either empty or filled`
      }),
    buttonAction: Joi.string()
      .valid(...Object.values(BUTTON_ACTION))
      .required()
  })
    .allow(null)
    .required(),
  hiddenScreens: Joi.object({
    explore: Joi.boolean().required(),
    all: Joi.boolean().required()
  }).required()
});
