const kycRejectionOptionsInId = [
  { index: 0, value: 'Foto KTP buram/kabur', label: 'Foto KTP buram/kabur', position: 'idPhoto' },
  {
    index: 1,
    value: 'Foto KTP terlalu gelap. Mohon mencari tempat yang lebih terang',
    label: 'Foto KTP terlalu gelap. Mohon mencari tempat yang lebih terang.',
    position: 'idPhoto'
  },
  {
    index: 2,
    value: 'Foto KTP terkena pantulan cahaya. Pastikan semua data dapat terbaca',
    label: 'Foto KTP terkena pantulan cahaya. Pastikan semua data dapat terbaca',
    position: 'idPhoto'
  },
  { index: 3, value: 'Selfie buram/kabur', label: 'Selfie buram/kabur', position: 'selfiePhoto' },
  {
    index: 4,
    value: 'Selfie terlihat gelap. Mohon mencari tempat yang lebih terang.',
    label: 'Selfie terlihat gelap. Mohon mencari tempat yang lebih terang.',
    position: 'selfiePhoto'
  },
  {
    index: 5,
    value: 'Selfie terlalu terang. Pastikan wajah terlihat jelas.',
    label: 'Selfie terlalu terang. Pastikan wajah terlihat jelas.',
    position: 'selfiePhoto'
  },
  {
    index: 6,
    value: 'Wajah tertutup masker',
    label: 'Wajah tertutup masker',
    position: 'selfiePhoto'
  },
  {
    index: 7,
    value: 'Foto KTP diambil dari foto atau layar digital',
    label: 'Foto KTP diambil dari foto atau layar digital',
    position: 'idPhoto'
  },
  {
    index: 8,
    value: 'Selfie diambil dari foto atau layar digital',
    label: 'Selfie diambil dari foto atau layar digital',
    position: 'selfiePhoto'
  },
  {
    index: 9,
    value: 'Wajah di selfie tidak sesuai wajah di KTP',
    label: 'Wajah di selfie tidak sesuai wajah di KTP',
    position: 'banner'
  },
  {
    index: 10,
    value: 'KTP sudah tidak berlaku atau fotokopi',
    label: 'KTP sudah tidak berlaku atau fotokopi',
    position: 'idPhoto'
  },
  {
    index: 11,
    value: 'Foto KTP tidak terunggah',
    label: 'Foto KTP tidak terunggah',
    position: 'idPhoto'
  },
  {
    index: 12,
    value: 'Foto KTP pada selfie terpotong',
    label: 'Foto KTP pada selfie terpotong',
    position: 'selfiePhoto'
  },
  {
    index: 13,
    value: 'Selfie tidak terunggah',
    label: 'Selfie tidak terunggah',
    position: 'selfiePhoto'
  },
  {
    index: 14,
    value: 'Selfie menggunakan KTP yang sudah tidak berlaku atau fotokopi',
    label: 'Selfie menggunakan KTP yang sudah tidak berlaku atau fotokopi',
    position: 'selfiePhoto'
  },
  {
    index: 15,
    value: 'Selfie tidak menggunakan KTP',
    label: 'Selfie tidak menggunakan KTP',
    position: 'selfiePhoto'
  },
  {
    index: 16,
    value: 'Wajah tertutup atau tidak terlihat',
    label: 'Wajah tertutup atau tidak terlihat',
    position: 'selfiePhoto'
  },
  {
    index: 17,
    value: 'Selfie wajah terpotong',
    label: 'Selfie wajah terpotong',
    position: 'selfiePhoto'
  },
  {
    index: 18,
    value: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    label: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    position: 'banner'
  },
  {
    index: 19,
    value:
      'KTP kamu tidak terbaca atau rusak. Silakan tambahkan dokumen lain seperti SIM/Paspor bersama dengan KTP-mu',
    label:
      'KTP kamu tidak terbaca atau rusak. Silakan tambahkan dokumen lain seperti SIM/Paspor bersama dengan KTP-mu',
    position: 'banner'
  },
  {
    index: 20,
    value: 'Nama tidak sesuai data kependudukan',
    label: 'Nama tidak sesuai data kependudukan',
    position: 'name'
  },
  {
    index: 21,
    value: 'NIK tidak sesuai data kependudukan',
    label: 'NIK tidak sesuai data kependudukan',
    position: 'nik'
  },
  {
    index: 22,
    value: 'Tanggal lahir tidak sesuai data kependudukan',
    label: 'Tanggal lahir tidak sesuai data kependudukan',
    position: 'dob'
  },
  {
    index: 23,
    value: 'Nomor KTP sudah terdaftar di Pluang',
    label: 'Nomor KTP sudah terdaftar di Pluang',
    position: 'nik'
  },
  {
    index: 24,
    value: 'Akun dinonaktifkan atas permohonan dari pemilik akun',
    label: 'Akun dinonaktifkan atas permohonan dari pemilik akun',
    position: 'banner'
  },
  {
    index: 25,
    value: 'Akun ini dinonaktifkan karena memiliki lebih dari satu akun Pluang',
    label: 'Akun ini dinonaktifkan karena memiliki lebih dari satu akun Pluang',
    position: 'banner'
  },
  {
    index: 26,
    value: 'Surat keterangan orang tua tidak sesuai. Mohon ikuti petunjuk yang disediakan.',
    label: 'Surat keterangan orang tua tidak sesuai. Mohon ikuti petunjuk yang disediakan.',
    position: 'banner'
  }, // do not change this rejection reason index because it is used for filter data
  {
    index: 27,
    value: 'Kartu Keluarga tidak sesuai. Mohon ikuti petunjuk yang disediakan.',
    label: 'Kartu Keluarga tidak sesuai. Mohon ikuti petunjuk yang disediakan.',
    position: 'banner'
  }, // do not change this rejection reason index because it is used for filter data
  {
    index: 28,
    value: 'Nasabah masih di bawah umur. Mohon unggah data tambahan yang dibutuhkan.',
    label: 'Nasabah masih di bawah umur. Mohon unggah data tambahan yang dibutuhkan.',
    position: 'banner'
  }, // do not change this rejection reason index because it is used for filter data
  {
    index: 29,
    value: 'Tanda tangan pada KTP kamu tidak terlihat.',
    label: 'Tanda tangan pada KTP kamu tidak terlihat.',
    position: 'idPhoto'
  },
  {
    index: 30,
    value: 'Foto KTP kamu tertutup. Pastikan fotomu dapat terlihat jelas.',
    label: 'Foto KTP kamu tertutup. Pastikan fotomu dapat terlihat jelas.',
    position: 'idPhoto'
  },
  {
    index: 31,
    value: 'Foto dokumen yang kamu upload bukan foto KTP.',
    label: 'Foto dokumen yang kamu upload bukan foto KTP.',
    position: 'idPhoto'
  },
  {
    index: 32,
    value: 'Foto yang kamu upload bukan foto KTP.',
    label: 'Foto yang kamu upload bukan foto KTP.',
    position: 'idPhoto'
  },
  {
    index: 33,
    value: 'Foto selfie dengan dokumen yang bukan KTP',
    label: 'Foto selfie dengan dokumen yang bukan KTP',
    position: 'selfiePhoto'
  },
  {
    index: 34,
    value: 'Foto yang kamu upload bukan foto selfie dengan KTP.',
    label: 'Foto yang kamu upload bukan foto selfie dengan KTP.',
    position: 'selfiePhoto'
  },
  {
    index: 35,
    value: 'Foto KTP kamu tidak terbaca atau rusak',
    label: 'Foto KTP kamu tidak terbaca atau rusak',
    position: 'idPhoto'
  },
  {
    index: 36,
    value: 'Mohon maaf, foto KTP Anda terbalik',
    label: 'Mohon maaf, foto KTP Anda terbalik',
    position: 'idPhoto'
  },
  {
    index: 37,
    value: 'KTP Anda terpotong',
    label: 'KTP Anda terpotong',
    position: 'idPhoto'
  }
];

const kycRejectionOptionsInEn = [
  {
    index: 0,
    value: 'ID card photo is too blurry',
    label: 'ID card photo is too blurry',
    position: 'idPhoto'
  },
  {
    index: 1,
    value: 'Your ID card photo is too dark. Please look for a brighter area',
    label: 'Your ID card photo is too dark. Please look for a brighter area',
    position: 'idPhoto'
  },
  {
    index: 2,
    value: 'Your ID card is exposed to light reflection. Make sure all data is readable',
    label: 'Your ID card is exposed to light reflection. Make sure all data is readable',
    position: 'idPhoto'
  },
  {
    index: 3,
    value: 'Your selfie is too blurry',
    label: 'Your selfie is too blurry',
    position: 'selfiePhoto'
  },
  {
    index: 4,
    value: 'Your selfie seems dark. Please look for a brighter area',
    label: 'Your selfie seems dark. Please look for a brighter area',
    position: 'selfiePhoto'
  },
  {
    index: 5,
    value: 'Your selfie is too bright. Make sure your face is clear',
    label: 'Your selfie is too bright. Make sure your face is clear',
    position: 'selfiePhoto'
  },
  {
    index: 6,
    value: 'Your face is covered by a mask',
    label: 'Your face is covered by a mask',
    position: 'selfiePhoto'
  },
  {
    index: 7,
    value: 'ID card photo is taken from an image or digital screen',
    label: 'ID card photo is taken from an image or digital screen',
    position: 'idPhoto'
  },
  {
    index: 8,
    value: 'Selfie is taken from an image or digital screen',
    label: 'Selfie is taken from an image or digital screen',
    position: 'selfiePhoto'
  },
  {
    index: 9,
    value: 'Your selfie does not match the photo on your ID card',
    label: 'Your selfie does not match the photo on your ID card',
    position: 'banner'
  },
  {
    index: 10,
    value: 'ID card expired or is a copy',
    label: 'ID card expired or is a copy',
    position: 'idPhoto'
  },
  {
    index: 11,
    value: 'ID card photo is not uploaded',
    label: 'ID card photo is not uploaded',
    position: 'idPhoto'
  },
  {
    index: 12,
    value: 'ID card photo on your selfie is cropped',
    label: 'ID card photo on your selfie is cropped',
    position: 'selfiePhoto'
  },
  {
    index: 13,
    value: 'Selfie is not uploaded',
    label: 'Selfie is not uploaded',
    position: 'selfiePhoto'
  },
  {
    index: 14,
    value: 'Selfie using an expired or a copy of ID card',
    label: 'Selfie using an expired or a copy of ID card',
    position: 'selfiePhoto'
  },
  {
    index: 15,
    value: 'Selfie without ID card',
    label: 'Selfie without ID card',
    position: 'selfiePhoto'
  },
  {
    index: 16,
    value: 'Part of your face is covered',
    label: 'Part of your face is covered',
    position: 'selfiePhoto'
  },
  {
    index: 17,
    value: 'Your selfie is cropped',
    label: 'Your selfie is cropped',
    position: 'selfiePhoto'
  },
  {
    index: 18,
    value: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    label: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    position: 'banner'
  },
  {
    index: 19,
    value:
      'Your ID card is unreadable or damaged. Please add other documents such as Drivers License/Passport with your ID',
    label:
      'Your ID card is unreadable or damaged. Please add other documents such as Drivers License/Passport with your ID',
    position: 'banner'
  },
  {
    index: 20,
    value: 'Name does not match with the valid data',
    label: 'Name does not match with the valid data',
    position: 'name'
  },
  {
    index: 21,
    value: 'NIK does not match with the valid data',
    label: 'NIK does not match with the valid data',
    position: 'nik'
  },
  {
    index: 22,
    value: 'Date of birth does not match with the valid data',
    label: 'Date of birth does not match with the valid data',
    position: 'dob'
  },
  {
    index: 23,
    value: 'Your ID number has been registered before',
    label: 'Your ID number has been registered before',
    position: 'nik'
  },
  {
    index: 24,
    value: 'Account has been deactivated by account owner request',
    label: 'Account has been deactivated by account owner request',
    position: 'banner'
  },
  {
    index: 25,
    value: 'Account has been deactivated due to owning more than one Pluang account',
    label: 'Account has been deactivated due to owning more than one Pluang account',
    position: 'banner'
  },
  {
    index: 26,
    value: 'Incorrect parental consent letter. Please follow the instructions provided',
    label: 'Incorrect parental consent letter. Please follow the instructions provided',
    position: 'banner'
  }, // do not change this rejection reason index because it is used for filter data
  {
    index: 27,
    value: 'Family Card does not match. Please follow the instructions provided',
    label: 'Family Card does not match. Please follow the instructions provided',
    position: 'banner'
  }, // do not change this rejection reason index because it is used for filter data
  {
    index: 28,
    value: 'You are still underage. Please upload the additional data required',
    label: 'You are still underage. Please upload the additional data required',
    position: 'banner'
  }, // do not change this rejection reason index because it is used for filter data
  {
    index: 29,
    value: 'The signature on your ID card cannot be seen.',
    label: 'The signature on your ID card cannot be seen.',
    position: 'idPhoto'
  },
  {
    index: 30,
    value: 'Your ID card is covered. Please make sure that the photo is visible.',
    label: 'Your ID card is covered. Please make sure that the photo is visible.',
    position: 'idPhoto'
  },
  {
    index: 31,
    value: 'The document photo you uploaded is not an ID card.',
    label: 'The document photo you uploaded is not an ID card.',
    position: 'idPhoto'
  },
  {
    index: 32,
    value: 'The photo you uploaded is not an ID card.',
    label: 'The photo you uploaded is not an ID card.',
    position: 'idPhoto'
  },
  {
    index: 33,
    value: 'The document photo that you uploaded is not a selfie with ID card.',
    label: 'The document photo that you uploaded is not a selfie with ID card.',
    position: 'selfiePhoto'
  },
  {
    index: 34,
    value: 'The photo you uploaded is not a selfie with ID card.',
    label: 'The photo you uploaded is not a selfie with ID card.',
    position: 'selfiePhoto'
  },
  {
    index: 35,
    value: 'Your ID card is unreadable or damaged',
    label: 'Your ID card is unreadable or damaged',
    position: 'idPhoto'
  },
  {
    index: 36,
    value: 'Sorry, your photo ID is reversed',
    label: 'Sorry, your photo ID is reversed',
    position: 'idPhoto'
  },
  {
    index: 37,
    value: 'Your ID is cropped',
    label: 'Your ID is cropped',
    position: 'idPhoto'
  }
];

const kycForeignerRejectionOptionsInId = [
  {
    index: 0,
    value: 'Foto Paspor buram/kabur',
    label: 'Foto Paspor buram/kabur',
    position: 'idPhoto'
  },
  {
    index: 1,
    value: 'Foto paspor terlalu gelap. Mohon mencari tempat yang lebih terang.',
    label: 'Foto paspor terlalu gelap. Mohon mencari tempat yang lebih terang.',
    position: 'idPhoto'
  },
  {
    index: 2,
    value: 'Foto paspor terkena pantulan cahaya. Pastikan semua data dapat terbaca.',
    label: 'Foto paspor terkena pantulan cahaya. Pastikan semua data dapat terbaca.',
    position: 'idPhoto'
  },
  { index: 3, value: 'Selfie buram/kabur', label: 'Selfie buram/kabur', position: 'selfiePhoto' },
  {
    index: 4,
    value: 'Selfie terlihat gelap. Mohon mencari tempat yang lebih terang.',
    label: 'Selfie terlihat gelap. Mohon mencari tempat yang lebih terang.',
    position: 'selfiePhoto'
  },
  {
    index: 5,
    value: 'Selfie terlalu terang. Pastikan wajah terlihat jelas.',
    label: 'Selfie terlalu terang. Pastikan wajah terlihat jelas.',
    position: 'selfiePhoto'
  },
  {
    index: 6,
    value: 'Wajah di selfie tidak sesuai wajah di paspor',
    label: 'Wajah di selfie tidak sesuai wajah di paspor',
    position: 'banner'
  },
  {
    index: 7,
    value: 'Paspor sudah tidak berlaku atau fotokopi',
    label: 'Paspor sudah tidak berlaku atau fotokopi',
    position: 'idPhoto'
  },
  {
    index: 8,
    value: 'Foto paspor tidak terunggah',
    label: 'Foto paspor tidak terunggah',
    position: 'idPhoto'
  },
  {
    index: 9,
    value: 'Foto paspor pada selfie terpotong',
    label: 'Foto paspor pada selfie terpotong',
    position: 'selfiePhoto'
  },
  {
    index: 10,
    value: 'Foto paspor diambil dari foto atau layar digital',
    label: 'Foto paspor diambil dari foto atau layar digital',
    position: 'idPhoto'
  },
  {
    index: 11,
    value: 'Wajah tertutup masker',
    label: 'Wajah tertutup masker',
    position: 'selfiePhoto'
  },
  {
    index: 12,
    value: 'Wajah tertutup atau tidak terlihat',
    label: 'Wajah tertutup atau tidak terlihat',
    position: 'selfiePhoto'
  },
  {
    index: 13,
    value: 'Selfie tidak terunggah',
    label: 'Selfie tidak terunggah',
    position: 'selfiePhoto'
  },
  {
    index: 14,
    value: 'Selfie tidak menggunakan paspor',
    label: 'Selfie tidak menggunakan paspor',
    position: 'selfiePhoto'
  },
  {
    index: 15,
    value: 'Selfie wajah menggunakan paspor yang sudah tidak berlaku atau fotocopy',
    label: 'Selfie wajah menggunakan paspor yang sudah tidak berlaku atau fotocopy',
    position: 'selfiePhoto'
  },
  {
    index: 16,
    value: 'Selfie diambil dari foto atau layar digital',
    label: 'Selfie diambil dari foto atau layar digital',
    position: 'selfiePhoto'
  },
  {
    index: 17,
    value: 'Selfie wajah terpotong',
    label: 'Selfie wajah terpotong',
    position: 'selfiePhoto'
  },
  {
    index: 18,
    value: 'Nama tidak sesuai dengan paspor',
    label: 'Nama tidak sesuai dengan paspor',
    position: 'name'
  },
  {
    index: 19,
    value: 'Nomor paspor tidak sesuai dengan paspor',
    label: 'Nomor paspor tidak sesuai dengan paspor',
    position: 'nik'
  },
  {
    index: 20,
    value: 'Tanggal lahir tidak sesuai dengan paspor',
    label: 'Tanggal lahir tidak sesuai dengan paspor',
    position: 'dob'
  },
  {
    index: 21,
    value: 'Nomor paspor sudah terdaftar di Pluang',
    label: 'Nomor paspor sudah terdaftar di Pluang',
    position: 'nik'
  },
  {
    index: 22,
    value: 'Akun di nonaktifkan atas permohonan dari pemilik akun',
    label: 'Akun di nonaktifkan atas permohonan dari pemilik akun',
    position: 'banner'
  },
  {
    index: 23,
    value: 'Akun ini di nonaktifkan karena memiliki lebih dari satu akun Pluang',
    label: 'Akun ini di nonaktifkan karena memiliki lebih dari satu akun Pluang',
    position: 'banner'
  },
  {
    index: 24,
    value: 'Silahkan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    label: 'Silahkan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    position: 'banner'
  }
];

const kycForeignerRejectionOptionsInEn = [
  {
    index: 0,
    value: 'Passport photo is too blurry',
    label: 'Passport photo is too blurry',
    position: 'idPhoto'
  },
  {
    index: 1,
    value: 'Your passport photo is too dark. Please look for a brighter area',
    label: 'Your passport photo is too dark. Please look for a brighter area',
    position: 'idPhoto'
  },
  {
    index: 2,
    value: 'Your passport is exposed to light reflection. Make sure all data is readable',
    label: 'Your passport is exposed to light reflection. Make sure all data is readable',
    position: 'idPhoto'
  },
  {
    index: 3,
    value: 'Your selfie is too blurry',
    label: 'Your selfie is too blurry',
    position: 'selfiePhoto'
  },
  {
    index: 4,
    value: 'Your selfie seems dark. Please look for a brighter area',
    label: 'Your selfie seems dark. Please look for a brighter area',
    position: 'selfiePhoto'
  },
  {
    index: 5,
    value: 'Your selfie is too bright. Make sure your face is clear',
    label: 'Your selfie is too bright. Make sure your face is clear',
    position: 'selfiePhoto'
  },
  {
    index: 6,
    value: `Your selfie doesn't match the photo on your passport`,
    label: `Your selfie doesn't match the photo on your passport`,
    position: 'banner'
  },
  {
    index: 7,
    value: 'Passport expired or is a copy',
    label: 'Passport expired or is a copy',
    position: 'idPhoto'
  },
  {
    index: 8,
    value: 'Pasport photo is not uploaded',
    label: 'Pasport photo is not uploaded',
    position: 'idPhoto'
  },
  {
    index: 9,
    value: 'Passport photo on your selfie is cropped',
    label: 'Passport photo on your selfie is cropped',
    position: 'selfiePhoto'
  },
  {
    index: 10,
    value: 'Passport photo is taken from an image or digital screen',
    label: 'Passport photo is taken from an image or digital screen',
    position: 'idPhoto'
  },
  {
    index: 11,
    value: 'Your face is covered by a mask',
    label: 'Your face is covered by a mask',
    position: 'selfiePhoto'
  },
  {
    index: 12,
    value: 'Part of your face is covered',
    label: 'Part of your face is covered',
    position: 'selfiePhoto'
  },
  {
    index: 13,
    value: 'Selfie picture is not uploaded',
    label: 'Selfie picture is not uploaded',
    position: 'selfiePhoto'
  },
  {
    index: 14,
    value: 'Selfie without passport',
    label: 'Selfie without passport',
    position: 'selfiePhoto'
  },
  {
    index: 15,
    value: 'Selfie using an expired or a copy of passport',
    label: 'Selfie using an expired or a copy of passport',
    position: 'selfiePhoto'
  },
  {
    index: 16,
    value: 'Selfie is taken from an image or digital screen',
    label: 'Selfie is taken from an image or digital screen',
    position: 'selfiePhoto'
  },
  {
    index: 17,
    value: 'Your selfie is cropped',
    label: 'Your selfie is cropped',
    position: 'selfiePhoto'
  },
  {
    index: 18,
    value: 'Name does not match with passport',
    label: 'Name does not match with passport',
    position: 'name'
  },
  {
    index: 19,
    value: 'Pasport number does not match with passport',
    label: 'Pasport number does not match with passport',
    position: 'nik'
  },
  {
    index: 20,
    value: 'Date of birth does not match with passport',
    label: 'Date of birth does not match with passport',
    position: 'dob'
  },
  {
    index: 21,
    value: 'Your passport number has been registered before',
    label: 'Your passport number has been registered before',
    position: 'nik'
  },
  {
    index: 22,
    value: 'Account has been deactivated by account owner request',
    label: 'Account has been deactivated by account owner request',
    position: 'banner'
  },
  {
    index: 23,
    value: 'Account has been deactivated due to owning more than one Pluang account',
    label: 'Account has been deactivated due to owning more than one Pluang account',
    position: 'banner'
  },
  {
    index: 24,
    value: 'Please contact Pluang CS by phone at 02180630065 or email at tanya@pluang.com',
    label: 'Please contact Pluang CS  by phone at 02180630065 or email at tanya@pluang.com',
    position: 'banner'
  }
];

const kyc2RejectionOptionsInId = [
  { index: 0, value: 'KYC 1 ditolak', label: 'KYC 1 ditolak', position: 'banner' },
  {
    index: 1,
    value: 'Bank utama reksa dana ditolak',
    label: 'Bank utama reksa dana ditolak',
    position: 'banner'
  },
  {
    index: 2,
    value: 'Tanda tangan masih belum memenuhi persyaratan/standar',
    label: 'Tanda tangan masih belum memenuhi persyaratan/standar',
    position: 'signature'
  },
  {
    index: 3,
    value: 'Email belum terverifikasi',
    label: 'Email belum terverifikasi',
    position: 'banner'
  },
  {
    index: 4,
    value: 'KTP sudah terdaftar pada akun lain',
    label: 'KTP sudah terdaftar pada akun lain',
    position: 'banner'
  },
  {
    index: 5,
    value: 'Profil risiko belum terisi',
    label: 'Profil risiko belum terisi',
    position: 'banner'
  },
  {
    index: 6,
    value:
      'Maaf, proses KYC II Anda ditolak. Silakan hubungi customer service kami untuk informasi lebih lengkapnya. Terima kasih.',
    label:
      'Maaf, proses KYC II Anda ditolak. Silakan hubungi customer service kami untuk informasi lebih lengkapnya. Terima kasih.',
    position: 'popup'
  },
  {
    index: 7,
    value: 'Tanda tangan tidak sesuai dengan KTP',
    label: 'Tanda tangan tidak sesuai dengan KTP',
    position: 'signature'
  }
];

const kyc2RejectionOptionsInEn = [
  { index: 0, value: 'KYC 1 rejected', label: 'KYC 1 rejected', position: 'banner' },
  {
    index: 1,
    value: 'Mutual fund default bank rejected',
    label: 'Mutual fund default bank rejected',
    position: 'banner'
  },
  {
    index: 2,
    value: 'e-signature does not meet requirement/standard',
    label: 'e-signature does not meet requirement/standard',
    position: 'signature'
  },
  {
    index: 3,
    value: 'email address has not been verified',
    label: 'email address has not been verified',
    position: 'banner'
  },
  {
    index: 4,
    value: 'ID number has been registered by another account',
    label: 'ID number has been registered by another account',
    position: 'banner'
  },
  {
    index: 5,
    value: 'Investor profile has not been filled',
    label: 'Investor profile has not been filled',
    position: 'banner'
  },
  {
    index: 6,
    value:
      'Sorry, your KYC II process was rejected. Please contact our customer service for more information. Thank you.',
    label:
      'Sorry, your KYC II process was rejected. Please contact our customer service for more information. Thank you.',
    position: 'popup'
  },
  {
    index: 7,
    value: 'Signature does not match with ID Card',
    label: 'Signature does not match with ID Card',
    position: 'signature'
  }
];

const personalDataRejectionOptionsInEn = [
  {
    index: 0,
    value: 'Signature does not match with ID Card',
    label: 'Signature does not match with ID Card',
    position: 'banner'
  },
  {
    index: 1,
    value: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    label: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    position: 'banner'
  },
  {
    index: 2,
    value: 'Your KTP data does not match with the Dukcapil data',
    label: 'Your KTP data does not match with the Dukcapil data',
    position: 'banner'
  },
  {
    index: 3,
    value: 'Sorry, the account cannot be processed further because it already has a VINTRA',
    label: 'Sorry, the account cannot be processed further because it already has a VINTRA',
    position: 'banner'
  },
  {
    index: 4,
    value: 'Sorry, please update your data at BCA',
    label: 'Sorry, please update your data at BCA',
    position: 'banner'
  },
  {
    index: 5,
    value: 'Account has been deactivated due to owning more than 1 Pluang account',
    label: 'Account has been deactivated due to owning more than 1 Pluang account',
    position: 'banner'
  }
];

const personalDataRejectionOptionsInId = [
  {
    index: 0,
    value: 'Tanda tangan tidak sesuai dengan KTP',
    label: 'Tanda tangan tidak sesuai dengan KTP',
    position: 'banner'
  },
  {
    index: 1,
    value: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    label: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    position: 'banner'
  },
  {
    index: 2,
    value: 'Data KTP-mu tidak sesuai dengan data Dukcapil',
    label: 'Data KTP-mu tidak sesuai dengan data Dukcapil',
    position: 'banner'
  },
  {
    index: 3,
    value: 'Mohon maaf, akun tidak bisa diproses lebih lanjut karena sudah memiliki akun VINTRA',
    label: 'Mohon maaf, akun tidak bisa diproses lebih lanjut karena sudah memiliki akun VINTRA',
    position: 'banner'
  },
  {
    index: 4,
    value: 'Mohon maaf, silahkan lakukan pengkinian data pada bank BCA',
    label: 'Mohon maaf, silahkan lakukan pengkinian data pada bank BCA',
    position: 'banner'
  },
  {
    index: 5,
    value: 'Akun anda telah di nonaktifkan karena mempunyai lebih dari 1 akun pluang',
    label: 'Akun anda telah di nonaktifkan karena mempunyai lebih dari 1 akun pluang',
    position: 'banner'
  }
];

const bankRejectionOptionsInId = [
  {
    index: 0,
    value:
      'Verifikasi akun bank gagal karena nama terdaftar di Pluang berbeda dengan nama akun bank',
    label:
      'Verifikasi akun bank gagal karena nama terdaftar di Pluang berbeda dengan nama akun bank'
  },
  {
    index: 1,
    value: 'Verifikasi akun bank gagal karena akun yang didaftarkan merupakan virtual account.',
    label: 'Verifikasi akun bank gagal karena akun yang didaftarkan merupakan virtual account.'
  },
  {
    index: 2,
    value:
      'Verifikasi akun bank gagal karena akun yang didaftarkan merupakan e-wallet/rekening ponsel',
    label:
      'Verifikasi akun bank gagal karena akun yang didaftarkan merupakan e-wallet/rekening ponsel'
  },
  {
    index: 3,
    value: 'Verifikasi akun bank gagal karena akun anda tidak aktif',
    label: 'Verifikasi akun bank gagal karena akun anda tidak aktif'
  },
  {
    index: 4,
    value: 'Verifikasi akun bank gagal karena pengajuan KYC anda ditolak',
    label: 'Verifikasi akun bank gagal karena pengajuan KYC anda ditolak'
  },
  {
    index: 5,
    value: 'Verifikasi akun bank gagal karena nomor bank anda tidak terdaftar',
    label: 'Verifikasi akun bank gagal karena nomor bank anda tidak terdaftar'
  }
];

const bankRejectionOptionsInEn = [
  {
    index: 0,
    value: 'Bank verification rejected because Pluang username different with bank account name',
    label: 'Bank verification rejected because Pluang username different with bank account name'
  },
  {
    index: 1,
    value: 'Bank verification rejected because the registered account is virtual account',
    label: 'Bank verification rejected because the registered account is virtual account'
  },
  {
    index: 2,
    value: 'Bank verification rejected because the registered account is e-wallet',
    label: 'Bank verification rejected because the registered account is e-wallet'
  },
  {
    index: 3,
    value: 'Bank verification rejected because your account is inactive',
    label: 'Bank verification rejected because your account is inactive'
  },
  {
    index: 4,
    value: 'Bank verification rejected because your KYC status is rejected',
    label: 'Bank verification rejected because your KYC status is rejected'
  },
  {
    index: 5,
    value: 'Bank verification rejected because your bank account is not registered',
    label: 'Bank verification rejected because your bank account is not registered'
  }
];

const cashoutRejectionOptionsInId = [
  {
    index: 0,
    value:
      'Maaf! Penarikan dana yang kamu lakukan gagal karena adanya kesalahan jaringan. Mohon coba kembali.',
    label:
      'Maaf! Penarikan dana yang kamu lakukan gagal karena adanya kesalahan jaringan. Mohon coba kembali.'
  },
  {
    index: 1,
    value: 'Maaf! Penarikan dana gagal karena terdapat kesalahan teknis. Mohon coba kembali.',
    label: 'Maaf! Penarikan dana gagal karena terdapat kesalahan teknis. Mohon coba kembali.'
  },
  {
    index: 2,
    value:
      'Maaf! Penarikan dana kamu belum dapat diproses. Silakan menghubungi Customer Service kami ke nomor 02180630065 atau email ke tanya@pluang.com untuk informasi lebih lanjut.',
    label:
      'Maaf! Penarikan dana kamu belum dapat diproses. Silakan menghubungi Customer Service kami ke nomor 02180630065 atau email ke tanya@pluang.com untuk informasi lebih lanjut.'
  },
  {
    index: 3,
    value: 'Penarikan dana dibatalkan sesuai permintaan pengguna.',
    label: 'Penarikan dana dibatalkan sesuai permintaan pengguna.'
  },
  {
    index: 4,
    value: 'Penarikan dana gagal karena nomor GoPay tujuan tidak ditemukan.',
    label: 'Penarikan dana gagal karena nomor GoPay tujuan tidak ditemukan.'
  },
  {
    index: 5,
    value: 'Penarikan dana gagal karena telah mencapai batas limit saldo GoPay.',
    label: 'Penarikan dana gagal karena telah mencapai batas limit saldo GoPay.'
  },
  {
    index: 6,
    value: 'Penarikan dana gagal karena kamu telah mencapai batas limit transaksi per bulan GoPay',
    label: 'Penarikan dana gagal karena kamu telah mencapai batas limit transaksi per bulan GoPay'
  },
  {
    index: 7,
    value:
      'Penarikan dana gagal, nama pada akun Pluang anda berbeda dengan nama pada akun bank anda',
    label:
      'Penarikan dana gagal, nama pada akun Pluang anda berbeda dengan nama pada akun bank anda'
  },
  {
    index: 8,
    value:
      'Penarikan dana gagal  karena pengajuan KYC anda ditolak, silahkan lengkapi Dokumen KYC Level 1',
    label:
      'Penarikan dana gagal  karena pengajuan KYC anda ditolak, silahkan lengkapi Dokumen KYC Level 1'
  },
  {
    index: 9,
    value: 'Penarikan dana gagal, akun bank yang kamu gunakan adalah Rekening Ponsel Atau Wallet',
    label: 'Penarikan dana gagal, akun bank yang kamu gunakan adalah Rekening Ponsel Atau Wallet'
  }
];

const cashoutRejectionOptionsInEn = [
  {
    index: 0,
    value: 'Your cashout failed because timeout error by bank, please try again!',
    label: 'Your cashout failed because timeout error by bank, please try again!'
  },
  {
    index: 1,
    value: 'Your cashout failed because technical problem, please try again!',
    label: 'Your cashout failed because technical problem, please try again!'
  },
  {
    index: 2,
    value: `Your cashout can't be processed, Please contact our customer service by phone at 02180630065 or email at tanya@pluang.com`,
    label: `Your cashout can't be processed, Please contact our customer service by phone at 02180630065 or email at tanya@pluang.com`
  },
  {
    index: 3,
    value: 'Cashout canceled by user request',
    label: 'Cashout canceled by user request'
  },
  {
    index: 4,
    value: 'Cashout failed because GoPay destination not found',
    label: 'Cashout failed because GoPay destination not found'
  },
  {
    index: 5,
    value: `Cashout failed because user have reached GoPay's balance limit`,
    label: `Cashout failed because user have reached GoPay's balance limit`
  },
  {
    index: 6,
    value: `Cashout failed because user have reached monthly GoPay's transaction limit`,
    label: `Cashout failed because user have reached monthly GoPay's transaction limit`
  },
  {
    index: 7,
    value:
      'Cashout rejected because your pluang account name is different with your bank account name',
    label:
      'Cashout rejected because your pluang account name is different with your bank account name'
  },
  {
    index: 8,
    value:
      'Cashout rejected because your KYC status is rejected, please complete your KYC 1 Document',
    label:
      'Cashout rejected because your KYC status is rejected, please complete your KYC 1 Document'
  },
  {
    index: 9,
    value: 'Cashout rejected because the registered account is virtual account or e-wallet',
    label: 'Cashout rejected because the registered account is virtual account or e-wallet'
  }
];

const occupationRejectionOptionsInEn = [
  {
    index: 0,
    value: 'NPWP number does not match with the valid data',
    label: 'NPWP number does not match with the valid data',
    position: 'npwp'
  },
  {
    index: 1,
    value: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    label: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    position: 'banner'
  },
  {
    index: 2,
    value:
      'Please reconfirm your No NPWP Statement Letter letter because there is an update in your data',
    label:
      'Please reconfirm your No NPWP Statement Letter letter because there is an update in your data',
    position: 'banner'
  },
  {
    index: 3,
    value: 'Users NIK is already associated with different user having GSS verified',
    label: 'Users NIK is already associated with different user having GSS verified',
    position: 'npwp'
  },
  {
    index: 4,
    value: 'User does not have Indonesian KTP (Foreigner)',
    label: 'User does not have Indonesian KTP (Foreigner)',
    position: 'npwp'
  },
  {
    index: 5,
    value: 'Sorry, your office name and office address do not exist',
    label: 'Sorry, your office name and office address do not exist',
    position: 'banner'
  },
  {
    index: 6,
    value: 'Sorry, please update your working data',
    label: 'Sorry, please update your working data',
    position: 'banner'
  }
];

const occupationRejectionOptionsInId = [
  { index: 0, value: 'Nomor NPWP tidak valid', label: 'Nomor NPWP tidak valid', position: 'npwp' },
  {
    index: 1,
    value: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    label: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    position: 'banner'
  },
  {
    index: 2,
    value:
      'Mohon konfirmasi ulang Surat Pernyataan tidak memiliki NPWP karena ada pembaruan di datamu',
    label:
      'Mohon konfirmasi ulang Surat Pernyataan tidak memiliki NPWP karena ada pembaruan di datamu',
    position: 'banner'
  },
  {
    index: 3,
    value: 'NIK pengguna telah dikaitkan dengan pengguna lain yang telah diverifikasi GSS',
    label: 'NIK pengguna telah dikaitkan dengan pengguna lain yang telah diverifikasi GSS',
    position: 'npwp'
  },
  {
    index: 4,
    value: 'Pengguna tidak memiliki KTP Indonesia (WNA)',
    label: 'Pengguna tidak memiliki KTP Indonesia (WNA)',
    position: 'npwp'
  },
  {
    index: 5,
    value: 'Mohon maaf, Nama kantor dan alamat kantor anda tidak ada',
    label: 'Mohon maaf, Nama kantor dan alamat kantor anda tidak ada',
    position: 'banner'
  },
  {
    index: 6,
    value: 'Mohon maaf, silahkan update data pekerjaan anda',
    label: 'Mohon maaf, silahkan update data pekerjaan anda',
    position: 'banner'
  }
];

const generalRejectionOptionsInId = [
  {
    index: 0,
    value: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com',
    label: 'Silakan hubungi CS Pluang di 02180630065 atau email ke tanya@pluang.com'
  }
];
const generalRejectionOptionsInEn = [
  {
    index: 0,
    value: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com',
    label: 'Please contact Pluang CS by phone at 02180630065 or email to tanya@pluang.com'
  }
];

const ForexTopupRejectionOptions = [
  {
    index: 0,
    en: 'Source bank account owner is different from your account detail.',
    in: 'Pemilik rekening bank sumber berbeda dengan detail akun kamu.',
    key: 'mismatch_account'
  },
  {
    index: 1,
    en: 'Amount received is different from top up request.',
    in: 'Jumlah yang diterima berbeda dengan permintaan top up.',
    key: 'mismatch_amount'
  },
  {
    index: 2,
    en: 'The maximum time limit has passed since the transfer slip was received.',
    in: 'Batas waktu maksimum telah terlewat sejak slip transfer diterima.',
    key: 'top_up_expired'
  },
  {
    index: 3,
    en: 'We cannot process your top up of USD cash balance at this moment. Please contact your RM for more details.',
    in: 'Kami tidak bisa memproses top up saldo USD kamu sekarang. Silakan hubungi RM kamu untuk detail selengkapnya.',
    key: 'others'
  }
];

const ForexCashOutRejectionOptions = [
  {
    index: 0,
    en: 'Source bank account owner is different from your account detail.',
    in: 'Pemilik rekening bank sumber berbeda dengan detail akun kamu.',
    key: 'mismatch_account'
  },
  {
    index: 1,
    en: 'We were unable to get your confirmation for the withdrawal request',
    in: 'Kami tidak bisa mendapatkan konfirmasi kamu untuk permintaan penarikan saldo.',
    key: 'customer_verification_failed'
  },
  {
    index: 2,
    en: 'We cannot process your withdrawal of USD cash balance at this moment. Please contact your RM for more details.',
    in: 'Kami tidak bisa memproses penarikan saldo USD kamu sekarang. Silakan hubungi RM kamu untuk detail selengkapnya.',
    key: 'others'
  }
];

const BcaTopupRejectionOptions = [
  {
    index: 0,
    en: 'Your top up failed because the sender’s name does not match the registered name. Please contact CS for the refund process.',
    in: 'Top up kamu gagal karena nama pengirim tidak sesuai dengan nama yang terdaftar. Silakan hubungi CS untuk proses pengembalian dana.',
    key: 'mismatch_name'
  },
  {
    index: 1,
    en: 'Your top up failed because the amount you transferred did not match the unique code in Pluang app. Please contact CS for the refund process.',
    in: 'Top up kamu gagal karena nominal yang ditransfer tidak sesuai dengan kode unik di aplikasi. Silakan hubungi CS untuk proses pembalian dana.',
    key: 'mismatch_amount'
  },
  {
    index: 2,
    en: 'Your top up failed because we did not receive your transfer in Pluang’s bank account. Please contact CS for further assistance.',
    in: 'Top up kamu gagal karena kami tidak menerima pembayaran kamu di rekening Pluang. Silakan hubungi CS untuk bantuan lebih lanjut.',
    key: 'no_transfer_received'
  },
  {
    index: 3,
    en: 'We can’t process your IDR Cash top up at the moment. Please contact CS for further assistance.',
    in: 'Kami tidak bisa memproses top up saldo IDR kamu saat ini. Silakan hubungi CS untuk bantuan lebih lanjut.',
    key: 'general'
  }
];

export {
  kycRejectionOptionsInEn,
  kycRejectionOptionsInId,
  kycForeignerRejectionOptionsInId,
  kycForeignerRejectionOptionsInEn,
  kyc2RejectionOptionsInId,
  kyc2RejectionOptionsInEn,
  bankRejectionOptionsInEn,
  bankRejectionOptionsInId,
  cashoutRejectionOptionsInEn,
  cashoutRejectionOptionsInId,
  occupationRejectionOptionsInEn,
  occupationRejectionOptionsInId,
  generalRejectionOptionsInId,
  generalRejectionOptionsInEn,
  personalDataRejectionOptionsInEn,
  personalDataRejectionOptionsInId,
  ForexTopupRejectionOptions,
  ForexCashOutRejectionOptions,
  BcaTopupRejectionOptions
};
