import { Box, Card, CardContent, Container } from '@mui/material';
import Notification from 'components/molecules/Notification';
import { LOCAL } from 'constants/clientRoutes';
import { IMAGES } from 'constants/images';
import { Navigate, Route, Routes } from 'react-router-dom';
import Forgot from './forgot';
import Login from './login';
import Reset from './reset';

const Auth = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${IMAGES.PLUANG_LOGIN_BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
      <Container sx={{ width: '540px' }}>
        <Card sx={{ borderRadius: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <img src={IMAGES.PLUANG_LOGIN_LOGO} />
            </Box>
            <Routes>
              <Route path={LOCAL.LOGIN} element={<Login />} />
              <Route path={LOCAL.FORGOT} element={<Forgot />} />
              <Route path={`${LOCAL.RESET}`} element={<Reset />} />
              <Route path="*" element={<Navigate to={LOCAL.LOGIN} />} />
            </Routes>
          </CardContent>
        </Card>
      </Container>
      <Notification />
    </Box>
  );
};

export default Auth;
