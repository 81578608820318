import {
  dividendSchema,
  eddUploadSchema,
  sInvestForeignerUploadSchema,
  sInvestUploadSchema
} from 'utils/schemas';
import { pepStatusUploadSchema } from 'utils/schemas/pepStatus-schema.joi';
import { refundUploadSchema, uploadBcaCallbackSchema } from 'utils/schemas/topup-schema.joi';

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validateIrisName = (name) => {
  name = name.replace(/[^a-zA-Z0-9 ]/g, '');
  return name.toLowerCase();
};

export const validateUrl =
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

const validateCsvData = (fixedHeaders, csvRows, schema) => {
  let [csvHeaders, ...csvValues] = csvRows;
  csvHeaders = csvHeaders.split(',').map((header) => header.trim());
  const missingHeaders = validateCsvHeaders(fixedHeaders, csvHeaders);
  if (missingHeaders.length)
    return {
      data: null,
      errors: [`${missingHeaders.join(', ')} should be present in CSV headers`]
    };

  return validateCsvRows(csvHeaders, csvValues, schema);
};

const validateCsvHeaders = (fixedHeaders, csvHeaders) => {
  let missingHeaders = [];
  for (let i = 0; i < fixedHeaders.length; i++) {
    if (csvHeaders.indexOf(fixedHeaders[i]) === -1) {
      missingHeaders.push(fixedHeaders[i]);
    }
  }
  return missingHeaders;
};

const validateCsvRows = (csvHeaders, csvValues, schema) => {
  const errors = [];
  const data = [];
  csvValues.forEach((value, row) => {
    const obj = {};
    value.split(',').forEach((item, index) => {
      obj[csvHeaders[index]] = item;
    });
    const { error } = schema.validate(obj);
    if (error) {
      errors.push(`Error in row ${row + 1}: ${error.message}.`);
    }
    data.push(obj);
  });
  return { data, errors };
};

export const validateUploadMFData = (uploadMFDataRows) => {
  if (uploadMFDataRows.length <= 1 || uploadMFDataRows[0].split(',').length !== 4) {
    return false;
  }
  return true;
};

export const validateUploadNav = (uploadNavDataRows) => {
  const headers = uploadNavDataRows[0].split(',');
  if (uploadNavDataRows.length <= 1 || headers.length < 3) {
    return false;
  }
  return (
    headers.includes('Fund Code') &&
    headers.includes('NAV Date') &&
    headers.includes('NAV per Unit')
  );
};

export const validateDividendData = (uploadDividendRows) => {
  const headers = uploadDividendRows[0].split(',');
  if (uploadDividendRows.length <= 1 || headers.length < 3) {
    return false;
  }
  return (
    headers.includes('Fund Code') &&
    headers.includes('Payment Date') &&
    headers.includes('Dividend')
  );
};

export const validateAumData = (uploadAumData) => {
  const headers = uploadAumData[0].split(',');
  if (uploadAumData.length <= 1 || headers.length < 3) {
    return false;
  }
  return headers.includes('Fund Code') && headers.includes('Period') && headers.includes('AUM');
};

export const validateUploadEIPOData = (uploadEIPORows) => {
  const headers = uploadEIPORows[0].split(',');
  if (uploadEIPORows.length <= 1 || headers.length < 3) {
    return false;
  }
  return (
    headers.includes('sid') &&
    headers.includes('offering_price') &&
    headers.includes('allotment_qty')
  );
};

export const validateUserTagsData = (uploadUserTagRows) => {
  const headers = uploadUserTagRows[0].split(',');
  if (uploadUserTagRows.length <= 1 || headers.length < 1) {
    return false;
  }
  return headers.includes('userIds');
};

export const validateBulkDividendData = (bulkDividendRows) => {
  const fixedHeaders = [
    'accountId',
    'userId',
    'quantity',
    'amount',
    'unitPrice',
    'currentPriceDate',
    'fundCode',
    'paymentReceipt'
  ];
  return validateCsvData(fixedHeaders, bulkDividendRows, dividendSchema);
};

export const validateSInvestUploadData = (sInvestUploadRows) => {
  const fixedHeaders = ['Nik', 'SID', 'IFUA', 'Reason Id', 'Reason En'];
  return validateCsvData(fixedHeaders, sInvestUploadRows, sInvestUploadSchema);
};

export const validateSInvestForeignerUploadData = (sInvestForeignerUploadRows) => {
  const fixedHeaders = ['Passport Number', 'Country', 'SID', 'IFUA', 'Reason Id', 'Reason En'];
  return validateCsvData(fixedHeaders, sInvestForeignerUploadRows, sInvestForeignerUploadSchema);
};

export const validateEDDUploadData = (eddDataUploadRows) => {
  const fixedHeaders = [
    'user_id',
    'occupation',
    'occupation_field',
    'position',
    'workplace_address',
    'workplace_sub_district',
    'workplace_city',
    'workplace_province',
    'income_level',
    'source_of_fund',
    'investment_objective',
    'workplace_district',
    'occupation_details'
  ];
  return validateCsvData(fixedHeaders, eddDataUploadRows, eddUploadSchema);
};

export const validatePepStatusUploadData = (pepStatusUploadRows) => {
  const fixedHeaders = ['userId', 'pepCurrentStatus', 'pepNewStatus'];
  return validateCsvData(fixedHeaders, pepStatusUploadRows, pepStatusUploadSchema);
};

export const validateRefundUploadData = (refundUploadRows) => {
  const fixedHeaders = ['referenceNumber'];
  return validateCsvData(fixedHeaders, refundUploadRows, refundUploadSchema);
};

export const validateBcaCallbackUploadData = (rowData) => {
  const fixedHeaders = ['transactionId', 'callBackReferenceId'];
  return validateCsvData(fixedHeaders, rowData, uploadBcaCallbackSchema);
};
