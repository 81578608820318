import { Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import KycTask from 'components/molecules/KycTask';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const InvestorProfile = ({ userData }) => {
  const [investorProfile, setInvestorProfile] = useState('');
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    getInvestorProfile(userData.investorScore);
  }, [userData]);

  const getInvestorProfile = (score) => {
    let investorProfile = '';
    if (score > 0 && score < 50) {
      investorProfile = 'Risk Averse';
    }
    if (score >= 50 && score < 90) {
      investorProfile = 'Income';
    }
    if (score >= 90 && score < 130) {
      investorProfile = 'Balance';
    }
    if (score >= 130) {
      investorProfile = 'Growth';
    }
    setInvestorProfile(investorProfile);
  };

  const investorProfileTableData = [{ label: 'Investor Profile', value: investorProfile }];

  const kycTaskStatus = () => (
    <Typography sx={{ color: 'green' }}>{investorProfile ? 'COMPLETED' : ' '}</Typography>
  );

  const handleKycTaskClick = () => setShowDetail(!showDetail);

  const isTaskDisabled = !investorProfile;

  return (
    <KycTask
      title="Investor Profile"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      disabled={isTaskDisabled}
      open={showDetail}>
      <BasicTable tableData={investorProfileTableData} />
    </KycTask>
  );
};

InvestorProfile.propTypes = {
  userData: PropTypes.object
};

export default InvestorProfile;
