import { List } from '@mui/material';
import SidebarMenuItem from 'components/molecules/SidebarMenuItem';
import { REMOTE } from 'constants/serverRoutes';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeRequest } from 'services';
import { useLoader } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { sidebarMenuItems, superAdminContent } from './menuItems';

const menuItemsClone = (sidebarMenuItems) =>
  sidebarMenuItems.map((item) =>
    item.items?.length
      ? Object.assign(
          {},
          { Icon: item.Icon, name: item.name, items: Array.from(menuItemsClone(item.items)) }
        )
      : Object.assign({}, item)
  );

const SidebarMenu = () => {
  const location = useLocation();
  const [appState] = useStateValue();
  const [content, setContent] = useState([]);
  const [, setLoader] = useLoader();

  useEffect(() => {
    if (appState.user?.roleIds) {
      if (appState.user?.roles?.indexOf('SuperAdmin') > -1) {
        setContent([...superAdminContent, ...sidebarMenuItems]);
      } else {
        const menuFilter = (content, permissionList) => {
          return content.filter((menu) => {
            if (menu.items) {
              menu.items = menuFilter(menu.items, permissionList);
              return menu.items.length > 0;
            }
            if (menu.permissionKeys) {
              return menu.permissionKeys.some((key) => permissionList[key]);
            }
            return !!permissionList[String(menu.name).replace(/\s+/g, '').trim().toLowerCase()];
          });
        };
        setLoader(true);
        makeRequest({
          url: `${REMOTE.ADMIN_RESOURCES}/permission?roleId=${appState.user?.roleIds}`
        })
          .then((result) => {
            const updatedContent = [];
            const permissionList = result.data.data.permissions;
            const menuItems = menuItemsClone(sidebarMenuItems);
            menuItems.forEach((menu) => {
              if (menu.items) {
                menu.items = menuFilter(menu.items, permissionList);
                if (menu.items.length > 0) {
                  updatedContent.push(menu);
                }
              }
            });
            setContent([...updatedContent]);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    }
  }, [appState.user]);

  return (
    <List component="nav" disablePadding>
      {content.map((item, index) => (
        <SidebarMenuItem child={1} location={location.pathname} {...item} key={index} />
      ))}
    </List>
  );
};

export default SidebarMenu;
