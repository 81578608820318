import Joi from 'joi';

export const addKycTwoSchema = Joi.object({
  phoneNumber: Joi.string().min(8).max(20).required().label('Phone Number'),
  gender: Joi.string(),
  religion: Joi.string(),
  maritalStatus: Joi.string(),
  occupation: Joi.string(),
  educationBackground: Joi.string(),
  sourceOfFund: Joi.string(),
  reasonAndPurposeOfInvestment: Joi.string(),
  incomeLevel: Joi.string(),
  signature: Joi.object().label('Signature'),
  investorProfile: Joi.string()
});

export const addUserSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  phone: Joi.string().min(8).max(15).required(),
  referralCode: Joi.string().required(),
  nameOnIdentityCard: Joi.string().min(3).required(),
  identityCardNumber: Joi.string().min(16).max(16).required(),
  idCardPicture: Joi.object().label('ID Card Picture').required(),
  selfPortraitPicture: Joi.object().label('Self Portrait Picture').required(),
  accountHolderName: Joi.string().required(),
  accountNumber: Joi.string().required(),
  bankCode: Joi.string().required(),
  dateOfBirth: Joi.date()
});

export const agentNetworkBankSchema = Joi.object({
  accountHolderName: Joi.string().required(),
  accountNumber: Joi.string().required(),
  bankCode: Joi.string().required()
});

export const KycOneSchema = Joi.object({
  nameOnIdentityCard: Joi.string().min(3).required(),
  identityCardNumber: Joi.string().min(16).max(16).required(),
  dateOfBirth: Joi.date(),
  idCardPicture: Joi.object().allow(null, ''),
  selfPortraitPicture: Joi.object().allow(null, '')
});

export const agentNetworkInvestorSchema = Joi.object({
  investorProfile: Joi.string().required()
});

export const editKycTwoSchema = Joi.object({
  gender: Joi.string(),
  religion: Joi.string(),
  maritalStatus: Joi.string(),
  occupation: Joi.string(),
  educationBackground: Joi.string(),
  sourceOfFund: Joi.string(),
  reasonAndPurposeOfInvestment: Joi.string(),
  incomeLevel: Joi.string(),
  signature: Joi.object().allow(null, '')
});
