import Joi from 'joi';

export const loginSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required()
});

export const forgotSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
});

export const resetSchema = Joi.object({
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.string()
    .valid(Joi.ref('password'))
    .label('Password and confirm password must match')
});
