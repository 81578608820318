import { useStateValue } from 'utils/redux';
import { SHOW_NOTIFICATION } from 'utils/redux/actions';

const useNotification = () => {
  const [, dispatch] = useStateValue();

  const showNotification = (type, body, title) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      notificationType: type,
      notificationMessage: {
        body,
        title
      }
    });
  };

  return {
    showNotification
  };
};

export default useNotification;
