import { SEGMENT_DEFAULT } from 'containers/ops/marketAlerts/constants';
import Joi from 'joi';

export const marketAlertsSchema = Joi.object({
  name: Joi.string().trim().required(),
  baseLine: Joi.number().min(0).allow(null, ''),
  coolingPeriod: Joi.number().min(1).max(180).required(),
  segment: Joi.alternatives(
    Joi.string().valid(SEGMENT_DEFAULT).trim().required(),
    Joi.number().required()
  ),
  redirectionLink: Joi.string().uri().trim().required()
}).options({ allowUnknown: true });
