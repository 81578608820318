import { WarningAmber } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useStateValue } from 'utils/redux';
import { CLOSE_MODAL } from 'utils/redux/actions';

const AlertDialog = ({
  title,
  alertMessage,
  showCancel = true,
  showConfirm = true,
  cancelText = 'No',
  confirmText = 'Yes',
  onConfirm,
  children = null,
  onClose
}) => {
  const [, dispatch] = useStateValue();
  const onConfirmClick = () => {
    if (onConfirm) onConfirm();
    closeModal();
  };

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
    if (onClose) {
      onClose();
    }
  };

  return (
    <Box sx={{ padding: 5, textAlign: 'center' }}>
      <WarningAmber sx={{ fontSize: 80, color: 'orange' }} />
      {title && <DialogTitle variant="h4">{title}</DialogTitle>}
      <DialogContent sx={{ minHeight: '300px', maxHeight: 'auto' }}>
        <DialogContentText component="div">{alertMessage}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        {showCancel && (
          <Button color="info" variant="outlined" onClick={closeModal}>
            {cancelText}
          </Button>
        )}
        {showConfirm && (
          <Button color="info" variant="contained" onClick={onConfirmClick} autoFocus>
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Box>
  );
};

AlertDialog.propTypes = {
  title: PropTypes.string,
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
  onClose: PropTypes.func
};

export default AlertDialog;
