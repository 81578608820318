import Joi from 'joi';
import JoiDate from '@joi/date';

export const dividendSchema = Joi.object({
  accountId: Joi.number().integer().required().label('Account ID'),
  userId: Joi.number().integer().required().label('User ID'),
  quantity: Joi.number().required().label('Quantity'),
  amount: Joi.number().required().label('Amount'),
  unitPrice: Joi.number().required().label('Unit Price'),
  currentPriceDate: Joi.extend(JoiDate)
    .date()
    .format('YYYY-MM-DD')
    .required()
    .label('Current Price Date'),
  fundCode: Joi.string().required().label('Fund Code'),
  paymentReceipt: Joi.string().uri().required().label('Payment Receipt')
});
