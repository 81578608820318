import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import App from 'containers/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApiInterceptor } from 'services';
import { StateProvider } from 'utils/redux/store';
import theme from 'theme';
import { globalStyles } from 'theme/globalStyles';
import ErrorBoundary from 'components/molecules/ErrorBoundary';

const styles = <GlobalStyles styles={globalStyles} />;

const Main = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <StateProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <ApiInterceptor>
                  {styles}
                  <CssBaseline />
                  <App />
                </ApiInterceptor>
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </StateProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
