import { Close } from '@mui/icons-material';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useStateValue } from 'utils/redux';
import { CLOSE_MODAL } from 'utils/redux/actions';

const ListDialog = ({ title, data: jsonArrayData, headers }) => {
  const [, dispatch] = useStateValue();
  return (
    <Box sx={{ width: '100%' }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={() => dispatch({ type: CLOSE_MODAL })}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  {header.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {jsonArrayData.map((data, k) => (
              <TableRow key={k}>
                {headers.map((header) => {
                  if (header === 'created' || header === 'createdAt') {
                    return <TableCell key={data[header]}>{dateFormatter(data[header])}</TableCell>;
                  }
                  return <TableCell key={data[header]}>{data[header]}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Box>
  );
};

ListDialog.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  headers: PropTypes.array
};

export default ListDialog;
