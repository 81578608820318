import React from 'react';
import PropTypes from 'prop-types';
import ReactImageMagnify from 'react-image-magnify-newtest';

const ImageMagnifier = ({
  url,
  width,
  height,
  largeImgContainerWidth,
  largeImgContainerHeight
}) => {
  return (
    <ReactImageMagnify
      {...{
        style: {
          backgroundColor: 'darkgray'
        },
        smallImage: {
          isFluidWidth: true,
          src: url
        },
        largeImage: {
          src: url,
          width: width,
          height: height
        },
        enlargedImageContainerDimensions: {
          width: largeImgContainerWidth,
          height: largeImgContainerHeight
        },
        enlargedImageContainerStyle: {
          zIndex: '2'
        }
      }}
    />
  );
};

ImageMagnifier.defaultProps = {
  width: 1200,
  height: 1800,
  largeImgContainerWidth: '100%',
  largeImgContainerHeight: '100%'
};

ImageMagnifier.propTypes = {
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  largeImgContainerWidth: PropTypes.string,
  largeImgContainerHeight: PropTypes.string
};

export default ImageMagnifier;
