import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormFooter from 'components/molecules/FormFooter';
import FormTextField from 'components/molecules/FormTextField';
import { REMOTE } from 'constants/serverRoutes';
import { addMonths, endOfDay } from 'date-fns';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';
import { purchaseOrderSchema } from 'utils/schemas';

export const VOUCHER_DENOMINATIONS = [
  { displayValue: '0.5K', value: 500 },
  { displayValue: '1,2K', value: 1200 },
  { displayValue: '2.5K', value: 2500 },
  { displayValue: '5K', value: 5000 },
  { displayValue: '10K', value: 10000 },
  { displayValue: '15K', value: 15000 },
  { displayValue: '20K', value: 20000 },
  { displayValue: '25K', value: 25000 },
  { displayValue: '30K', value: 30000 },
  { displayValue: '50K', value: 50000 },
  { displayValue: '88.888', value: 88888 },
  { displayValue: '100K', value: 100000 },
  { displayValue: '200K', value: 200000 },
  { displayValue: '250K', value: 250000 },
  { displayValue: '500K', value: 500000 },
  { displayValue: '750K', value: 750000 },
  { displayValue: '888.888', value: 888888 },
  { displayValue: `1jt'`, value: 1000000 },
  { displayValue: `5jt'`, value: 5000000 },
  { displayValue: `10jt'`, value: 10000000 },
  { displayValue: '88.888.888', value: 88888888 },
  { displayValue: `100jt'`, value: 100000000 }
];

const PurchaseOrderMerchantEdit = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { merchantId } = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const methods = useForm({
    defaultValues: {
      orderReferenceNumber: '',
      ...VOUCHER_DENOMINATIONS.reduce((a, { value }) => {
        return { ...a, [value]: '' };
      }, {}),
      description: '',
      phone: '',
      expires: addMonths(new Date(), 6)
    },
    resolver: joiResolver(purchaseOrderSchema)
  });

  const placePurchaseOrder = (values) => {
    let denominations = {};
    for (const key in values) {
      if (!isNaN(parseInt(key)) && values[key] !== '') denominations[key] = parseInt(values[key]);
      if (!isNaN(parseInt(key))) delete values[key];
    }
    const data = { ...values, denominations };
    data.expires = endOfDay(values.expires);
    if (Object.keys(denominations).length === 0)
      return showNotification('error', 'Select at least one denomination');
    setIsButtonDisabled(true);

    makeRequest({
      method: 'POST',
      url: `${REMOTE.PURCHASE_ORDER_MERCHANT}`,
      data: {
        id: merchantId,
        ...data
      }
    })
      .then((result) => {
        if (result.data.success) {
          showNotification('success', 'We will process the order shortly!', 'Order Received');
          navigate(-1);
        }
      })
      .catch((error) => {
        if (error.error_message) {
          showNotification('error', error.error_message);
        } else {
          showNotification('error', 'Oops! Something went wrong');
        }
      })
      .finally(() => setIsButtonDisabled(false));
  };

  const isMerchant = () => {
    let MerchantId = [17];
    return MerchantId.includes(merchantId);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Create Voucher Purchase Order</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container>
          <Grid item xs={6}>
            <Box component="form" onSubmit={methods.handleSubmit(placePurchaseOrder)}>
              <FormTextField
                methods={methods}
                label="Order Reference Number"
                name="orderReferenceNumber"
              />
              {isMerchant() && (
                <FormTextField methods={methods} label="Mobile Number" name="phone" />
              )}
              <Typography sx={{ mt: 2 }} variant="6">
                Voucher Denominations
              </Typography>
              <Box sx={{ mt: 2 }}>
                {VOUCHER_DENOMINATIONS.map(({ displayValue, value }, index) => (
                  <Grid container key={index} alignItems="center">
                    <Grid item xs={3} textAlign="center">
                      <Typography variant="body1">{displayValue}</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign="center">
                      <Typography variant="body1">X</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                      <FormTextField methods={methods} name={value.toString()} type="number" />
                    </Grid>
                  </Grid>
                ))}
              </Box>
              <FormTextField
                methods={methods}
                required={false}
                multiline={true}
                minRows={2}
                maxRows={5}
                label="Description"
                name="description"
              />
              <Controller
                name="expires"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Expires At"
                      value={value}
                      onChange={(newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} margin="normal" size="small" />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              <FormFooter isButtonDisabled={isButtonDisabled} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PurchaseOrderMerchantEdit;
