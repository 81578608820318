import { ArrowBack, Save } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const FormFooter = ({ isButtonDisabled, showCancelButton = true }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
      <Button
        size="small"
        variant="contained"
        type="submit"
        startIcon={<Save />}
        disabled={isButtonDisabled}>
        Save
      </Button>
      {showCancelButton && (
        <Button
          size="small"
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}>
          Cancel
        </Button>
      )}
    </Box>
  );
};

FormFooter.propTypes = {
  isButtonDisabled: PropTypes.bool,
  showCancelButton: PropTypes.bool
};

export default FormFooter;
