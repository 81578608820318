import { JoiNumber } from 'helpers/joiExtensions';
import Joi from 'joi';

const precisionMethod = (value, helpers) => {
  const {
    state: {
      ancestors: [{ precision }]
    }
  } = helpers;
  if (
    Math.floor(value) !== parseFloat(value) &&
    value.toString().split('.')[1].length > precision
  ) {
    return helpers.error('custom.invalid');
  }
  return value;
};

export const globalStockIndexSchema = Joi.object({
  precision: Joi.number().integer().min(1).max(4),
  minimumBuyQuantity: JoiNumber.normalizeValue()
    .label('Minimum Buy Quantity')
    .min(0)
    .custom((values, helpers) => precisionMethod(values, helpers))
    .messages({
      'custom.invalid': `Minimum Buy Quantity must be greater than or Equal to 0 & only supports till {precision} decimal places`
    }),
  minimumSellQuantity: JoiNumber.normalizeValue()
    .label('Minimum Sell Quantity')
    .min(0)
    .custom((values, helpers) => precisionMethod(values, helpers))
    .messages({
      'custom.invalid': `Minimum Sell Quantity must be greater than or Equal to 0 & only supports till {precision} decimal places`
    }),
  maximumSellQuantity: JoiNumber.normalizeValue()
    .label('Maximum Sell Quantity')
    .min(0)
    .custom((values, helpers) => precisionMethod(values, helpers))
    .messages({
      'custom.invalid': `Maximum Sell Quantity must be greater than or Equal to 0 & only supports till {precision} decimal places`
    }),
  maximumBuyQuantity: JoiNumber.normalizeValue()
    .label('Maximum Buy Quantity')
    .min(0)
    .custom((values, helpers) => precisionMethod(values, helpers))
    .messages({
      'custom.invalid': `Maximum Buy Quantity must be greater than or Equal to 0 & only supports till {precision} decimal places`
    }),
  enableBuy: Joi.boolean(),
  enableSell: Joi.boolean(),
  disableAllTransaction: Joi.boolean()
});
