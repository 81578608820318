import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import ListDialog from 'components/molecules/ListDialog';
import RejectionReasonContent from 'components/molecules/RejectionReason';
import { bankRejectionOptionsInEn, bankRejectionOptionsInId } from 'constants/rejectionOptions';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { validateIrisName } from 'helpers/validators';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { useIdleTimer, useLoader } from 'services/hooks';
import useNotification from 'services/hooks/useNotification';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';
import ImageViewer from 'components/molecules/ImageViewer';

const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];

const BankDetails = () => {
  const [, setLoader] = useLoader();
  const [bankDetailState, setBankDetailState] = useState({
    name: '',
    status: '',
    errors: {
      name: '',
      kyc: ''
    },
    kycData: null,
    rejectionReasonLogData: [],
    englishOptions: [],
    bahasaOptions: [],
    viewImage: '',
    deg: 0,
    scale: 1,
    flip: 1
  });
  const [expired, setExpired] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRejectionModal, setRejectionModal] = useState(false);
  const [, dispatch] = useStateValue();
  const { showNotification } = useNotification();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const caseId = location.state?.caseId;

  useEffect(() => {
    if (!caseId) return pushBack();
    if (expired) {
      exitCase(caseId);
      setExpired(false);
    }
  }, [expired]);

  useEffect(() => {
    if (params.userBankId) {
      getBankAccount(params.userBankId);
    }
    timer.startInterval();
  }, []);

  const onExpired = () => {
    if (localStorage.getItem('_expiredTime')) {
      dispatch({
        type: OPEN_MODAL,
        modalContent: (
          <AlertDialog
            title="Idle Warning"
            alertMessage="You are idle for more than 15 minutes, you will be redirected to home page"
            showCancel={false}
            confirmText="OK"
            onConfirm={() => setExpired(true)}
          />
        )
      });
    }
  };

  const timer = useIdleTimer({
    timeout: 900,
    onExpired: onExpired
  });

  const exitCase = () => {
    setLoader(true);
    return makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC_AUTO}/caseExit/userId/${caseId}`,
      data: {
        isKycAutoAssignPage: true
      }
    })
      .then((result) => {
        if (result.data.data && result.data.data.isKycCaseExit) {
          // setCaseId(null);
          timer.cleanUp();
        }
      })
      .finally(() => setLoader(false));
  };

  const handleSubmit = (e) => {
    let data = {
      accountNumber: bankDetailState.bankAccountData.accountNumber,
      bankCode: bankDetailState.bankAccountData.bank.code,
      accountHolderName: bankDetailState.accountHolderName,
      userId: bankDetailState.bankAccountData.userId,
      accountId: bankDetailState.bankAccountData.accountId,
      isKycAutoAssignPage: true
    };
    let errors = Object.keys(bankDetailState.errors).filter(
      (x) => bankDetailState.errors[x] !== ''
    );
    if (errors.length > 0) return;
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.BANK_ACCOUNTS}/${bankDetailState.bankAccountData.id}`,
      data
    })
      .then((result) => {
        if (result.data && result.data.data) {
          getBankAccount(params.userBankId);
          timer.cleanUp();
          timer.startInterval();
          showNotification('success', 'Status updated');
        } else {
          pushBack();
        }
      })
      .catch((error) => {
        showNotification('error', error.error_message);
      })
      .finally(() => setLoader(false));
  };

  const rejectBankAccount = () => {
    const {
      bankId,
      accountNumber,
      accountName,
      userId,
      accountId,
      defaultIdssUserBank,
      defaultUserBank,
      status,
      kseiStatus
    } = bankDetailState.bankAccountData;
    const reasonEn = bankDetailState.englishOptions?.[0]?.value;
    const reasonId = bankDetailState.bahasaOptions?.[0]?.value;
    setLoader(true);
    if (!reasonEn || !reasonId) {
      showNotification('error', 'Rejection reason is required');
      return;
    }
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.BANK_ACCOUNTS}/reject/${bankDetailState.bankAccountData.id}`,
      data: {
        bankId,
        accountNumber,
        accountName,
        userId,
        accountId,
        defaultIdssUserBank,
        defaultUserBank,
        kseiStatus,
        status,
        rejectionReason: reasonEn,
        rejectionReasonId: reasonId,
        isKycAutoAssignPage: true
      }
    })
      .then((result) => {
        if (result.data && result.data.data) {
          getBankAccount(params.userBankId);

          timer.cleanUp();
          timer.startInterval();
          showNotification('success', 'Status updated');
        } else {
          pushBack();
        }
        pushBack();
      })
      .catch((error) => {
        showNotification('error', error.error_message);
      })
      .finally(() => setLoader(false));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBankDetailState((prevState) => ({
      ...prevState,
      [name]: value
    }));
    return false;
  };

  const validateChange = (event) => {
    const { name, value } = event.target;
    let errors = bankDetailState.errors;
    let regex = /^[A-Za-z0-9,. ]+$/;

    switch (name) {
      case 'name':
        errors.name =
          value.length < 5 || !value.match(regex)
            ? 'Name must be 5 characters long! & special ' +
              `characters except ',' & '.' are not allowed`
            : '';
        break;
      default:
        break;
    }
    setBankDetailState((prevState) => ({
      ...prevState,
      errors: errors
    }));
  };

  const getUser = (accountId) => {
    if (!accountId) pushBack();
    setLoader(true);
    makeRequest({
      url: `${REMOTE.USER}/search`,
      params: {
        id: accountId
      }
    })
      .then((result) => {
        if (result.data && result.data.data && result.data.data.length === 1) {
          let user = result.data.data[0];
          let errors = bankDetailState.errors;
          getKyc1(user.user.id);
          if (user.user.kycStatus === 'REJECTED') {
            errors.kyc = 'Cannot Approve. Kyc1 is Rejected ';
            setBankDetailState((prevState) => ({
              ...prevState,
              userData: user,
              errors
            }));
          } else {
            setBankDetailState((prevState) => ({
              ...prevState,
              userData: user
            }));
          }
        }
      })
      .finally(() => setLoader(false));
  };

  const getIrisValidation = (bankName, accountNo) => {
    setLoader(true);
    if (!bankName && !accountNo) pushBack();
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/validation`,
      params: {
        bankName: bankName,
        accountNo: accountNo
      }
    })
      .then((result) => {
        if (result.data && result.data.data) {
          let irisData = result.data.data;
          setBankDetailState((prevState) => ({
            ...prevState,
            irisData: irisData,
            name: irisData.account_name
          }));
        }
      })
      .finally(() => setLoader(false));
  };

  const getBankAccount = (userBankId) => {
    if (!userBankId) pushBack();
    setLoader(true);
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/${userBankId}`
    })
      .then((result) => {
        if (result.data && result.data.data && result.data.data.length === 1) {
          let bankAccountData = result.data.data[0];
          setBankDetailState((prevState) => ({
            ...prevState,
            bankAccountData: bankAccountData,
            status: bankAccountData.status,
            accountHolderName: bankAccountData.accountName
          }));
          if (bankAccountData && bankAccountData.bank) {
            getIrisValidation(bankAccountData.bank.code, bankAccountData.accountNumber);
            getUser(bankAccountData.accountId);
          } else {
            pushBack();
          }
        } else {
          pushBack();
        }
      })
      .catch((error) => {
        pushBack();
      })
      .finally(() => setLoader(false));
  };

  const getKyc1 = (userId) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/filter`,
      params: {
        userId: userId
      }
    })
      .then((result) => {
        if (result.data && result.data.data && result.data.data.length === 1) {
          setBankDetailState((prevState) => ({
            ...prevState,
            kycData: result.data.data[0]
          }));
        }
      })
      .finally(() => setLoader(false));
  };

  const getRejectionReasonLog = (userBankId) => {
    if (!userBankId) pushBack();
    setLoader(true);
    if (!bankDetailState.rejectionReasonLogData.length > 0) {
      makeRequest({
        url: `${REMOTE.BANK_ACCOUNTS}/stateTransition/filter`,
        params: {
          userBankId,
          toState: 'REJECTED'
        }
      })
        .then((result) => {
          if (result.data && result.data.data && result.data.data.length > 0) {
            setBankDetailState((prevState) => ({
              ...prevState,
              rejectionReasonLogData: result.data.data
            }));
            openRejectionReasonDialog(result.data.data);
          } else {
            showNotification('error', 'No rejection reason logs found');
          }
        })
        .finally(() => setLoader(false));
    } else openRejectionReasonDialog(bankDetailState.rejectionReasonLogData);
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };

  const pushBack = () => {
    timer.cleanUp();
    timer.startInterval();
    navigate(-1, { replace: true });
  };

  const updateKseiOrderForUsdBank = () => {
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.BANK_ACCOUNTS}/update/usdBank`,
      data: {
        userBankId: bankDetailState.bankAccountData.id,
        userId: bankDetailState.bankAccountData.userId
      }
    })
      .then((result) => {
        if (result.data && result.data.success) {
          showNotification('success', 'KSEI order updated successfully');
          getBankAccount(params.userBankId);
        } else {
          pushBack();
        }
      })
      .catch(() => {
        pushBack();
      })
      .finally(() => setLoader(false));
  };

  const closeRejectionDialog = () => {
    setRejectionModal(false);
    setBankDetailState((prevState) => ({
      ...prevState,
      englishOptions: [],
      bahasaOptions: []
    }));
  };

  const showButtons = () => {
    const { name, errors } = bankDetailState;
    const { bankAccountData, irisData, kycData } = bankDetailState;
    return irisData && bankAccountData && bankAccountData.accountName !== irisData.account_name ? (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 2 }}>
        <TextField
          size="small"
          variant="outlined"
          type="text"
          label="Update Name"
          value={name}
          name="name"
          onBlur={(e) => validateChange(e)}
          onChange={(e) => handleChange(e)}
          error={errors.name !== ''}
          helperText={errors.name}
        />
        {errors.kyc}
        <Button
          color="success"
          size="small"
          variant="contained"
          disabled={Object.keys(errors).filter((x) => errors[x] !== '').length > 0}
          onClick={() =>
            dispatch({
              type: OPEN_MODAL,
              modalContent: (
                <AlertDialog
                  title="Update & Accept Bank Account"
                  alertMessage="Are you sure want to accept?"
                  onConfirm={handleSubmit}
                />
              )
            })
          }>
          Update & Approve
        </Button>
        <Button
          color="error"
          size="small"
          variant="contained"
          onClick={() => setRejectionModal(true)}>
          Reject Bank Account
        </Button>
      </Box>
    ) : (
      <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
        {errors.kyc}
        {bankDetailState.status !== 'VERIFIED' ? (
          <Button
            color="success"
            size="small"
            variant="contained"
            disabled={
              Object.keys(errors).filter((x) => errors[x] !== '').length > 0 ||
              bankDetailState.disableButton
            }
            onClick={() =>
              dispatch({
                type: OPEN_MODAL,
                modalContent: (
                  <AlertDialog
                    title="Accept Bank Account"
                    alertMessage="Are you sure want to accept?"
                    onConfirm={handleSubmit}
                  />
                )
              })
            }>
            Accept Bank Account
          </Button>
        ) : (
          ''
        )}
        {bankDetailState.status !== 'REJECTED' && (
          <Button
            color="error"
            size="small"
            variant="contained"
            disabled={bankDetailState.disableButton}
            onClick={() => setRejectionModal(true)}>
            Reject Bank Account
          </Button>
        )}
        {kycData?.idCardPicture && (
          <Button color="info" size="small" onClick={() => setShowModal(true)} variant="contained">
            Compare KTP Photo
          </Button>
        )}
      </Box>
    );
  };

  const { bankAccountData, userData, status, irisData, kycData } = bankDetailState;
  return (
    <>
      <Card>
        <CardContent>
          <div className="mainHeading">
            <Box display="flex" justifyContent="space-between" pt={2}>
              <Typography variant="h5">Bank Account Details</Typography>
              <Typography variant="h6">{status}</Typography>
            </Box>
          </div>
          <Table>
            <TableBody>
              {bankAccountData && userData && userData.user ? (
                <>
                  <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell>{userData.user.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>KYC Status</TableCell>
                    {kycData ? (
                      <TableCell>
                        {kycData.status}
                        <Link to={`/ops/kyc/agent`}>
                          <Button sx={{ ml: 2 }} variant="contained" color="primary" size="small">
                            KYC1 Details
                          </Button>
                        </Link>
                      </TableCell>
                    ) : (
                      <TableCell>No Data Found</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>{bankAccountData.bank ? bankAccountData.bank.name : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Account No.</TableCell>
                    <TableCell>{bankAccountData.accountNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Beneficiary Name</TableCell>
                    <TableCell>{bankAccountData.accountName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Pluang Name</TableCell>
                    <TableCell>
                      {kycData && kycData.status === 'VERIFIED' && kycData.nameOnIdentityCard
                        ? kycData.nameOnIdentityCard
                        : userData.user.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IRIS Validation Status</TableCell>
                    {bankAccountData.accountName &&
                    irisData &&
                    irisData.account_name &&
                    userData.user &&
                    userData.user.name &&
                    bankAccountData.accountName.toLowerCase() ===
                      validateIrisName(irisData.account_name) &&
                    (kycData && kycData.status === 'VERIFIED' && kycData.nameOnIdentityCard
                      ? validateIrisName(irisData.account_name) ===
                        kycData.nameOnIdentityCard.toLowerCase()
                      : validateIrisName(irisData.account_name) ===
                        userData.user.name.toLowerCase()) ? (
                      <TableCell>
                        <Chip size="small" label="VERIFIED" color="success" />
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Chip size="small" label="MISMATCH" color="error" />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Similarity Score</TableCell>
                    <TableCell>{bankAccountData.nameSimilarityScore}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auto Updated</TableCell>
                    <TableCell>{bankAccountData.autoUpdated ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mf Status</TableCell>
                    <TableCell>{bankAccountData.kseiStatus}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mf Bank Order</TableCell>
                    <TableCell>{bankAccountData.kseiBankOrder}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forex Bank</TableCell>
                    <TableCell>{bankAccountData.forexBank ? 'True' : 'False'}</TableCell>
                  </TableRow>
                  {bankAccountData && bankAccountData.status === 'REJECTED' && (
                    <TableRow>
                      <>
                        <TableCell>Rejection Reason (Bahasa)</TableCell>
                        <TableCell>{bankAccountData.rejectionReasonId}</TableCell>
                      </>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Last Upload Time</TableCell>
                    <TableCell>{dateFormatter(bankAccountData.lastUploadTime)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Verified By</TableCell>
                    <TableCell>{bankAccountData.verifiedById}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Updated Time</TableCell>
                    <TableCell>{dateFormatter(bankAccountData.updatedAt)}</TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="noFound">
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <>
            {showButtons()}
            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
              {bankAccountData &&
                bankAccountData.status === 'VERIFIED' &&
                bankAccountData.forexBank &&
                !bankAccountData.kseiStatus && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => updateKseiOrderForUsdBank()}
                    size="small">
                    Set Mutual Fund Bank
                  </Button>
                )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => getRejectionReasonLog(bankAccountData.id)}
                size="small">
                Rejection reason logs
              </Button>
              <Button color="warning" size="small" variant="contained" onClick={() => pushBack()}>
                Back
              </Button>
            </Box>
          </>
        </CardContent>
      </Card>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: '800px',
              maxWidth: '800px'
            }
          }
        }}
        open={showRejectionModal}
        onClose={closeRejectionDialog}>
        <AlertDialog
          title="Reject KYC"
          alertMessage="Reason?"
          onClose={closeRejectionDialog}
          onConfirm={() => rejectBankAccount('REJECTED')}>
          <RejectionReasonContent
            multiSelect={false}
            englishOptions={bankDetailState.englishOptions}
            bahasaOptions={bankDetailState.bahasaOptions}
            rejectionOptionsInEn={bankRejectionOptionsInEn}
            rejectionOptionsInId={bankRejectionOptionsInId}
            setRejectionReasonState={setBankDetailState}
          />
        </AlertDialog>
      </Dialog>
      <Dialog maxWidth="sm" onClose={() => setShowModal(false)} open={showModal}>
        <ImageViewer imgSrc={kycData && kycData.idCardPicture} fullWidth={true} />
      </Dialog>
    </>
  );
};

export default BankDetails;
