import Joi from 'joi';

export const goldPriceSchema = Joi.object({
  sellPrice: Joi.number().integer().min(500000).required().label('Sell Price'),
  buyBackPrice: Joi.number().integer().min(500000).required().label('Buy Back Price'),
  installment: Joi.number().integer().min(500000).required().label('Installment Price')
});

export const goldPriceAddFutureLotsSchema = Joi.object({
  fxRatePremium: Joi.number().integer().positive().required().label('Fx Rate Premium'),
  physicalWeight: Joi.number().integer().min(2500).max(300000).required().label('Physical Weight'),
  antamPricePremiumAdjustment: Joi.number()
    .integer()
    .min(-100000)
    .max(100000)
    .required()
    .label('Antam Price Premium Adjustment'),
  antamPriceOverride: Joi.number()
    .integer()
    .min(700000)
    .max(5000000)
    .required()
    .label('"Antam Price Override'),
  priceOverride: Joi.array().items('Price Override').allow('')
});
