import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

const ListItemNavLink = forwardRef((props, ref) => {
  return <NavLink {...props} reloadDocument={props.reload} ref={ref} />;
});

ListItemNavLink.displayName = 'ListItemNavLink';

const SidebarMenuComponent = (props) => {
  const { onClick, link, children, selected, reload } = props;

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        sx={{
          padding: '3px 16px',
          '&.Mui-selected': {
            background: '#3a93ff'
          },
          ':hover': {
            background: '#3a93ff'
          },
          '&.Mui-selected:hover': {
            background: '#3a93ff'
          }
        }}
        button
        selected={selected}
        onClick={onClick}>
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      sx={{
        padding: '3px 16px',
        '&.active': {
          background: '#3a93ff'
        },
        ':hover': {
          background: '#3a93ff'
        }
      }}
      reload={reload}
      component={ListItemNavLink}
      to={link}>
      {children}
    </ListItem>
  );
};

ListItemNavLink.propTypes = {
  reload: PropTypes.bool
};

SidebarMenuComponent.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  selected: PropTypes.bool,
  reload: PropTypes.bool
};

export default SidebarMenuComponent;
