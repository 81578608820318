export const MISSION_QUOTA_UNLIMITED_VALUE = 2147483647;
export const MIN_TRIGGER_LIMIT = 10000;
export const REDEMPTION_QUOTA_TYPE = Object.freeze({
  LIMITED: 'LIMITED',
  UNLIMITED: 'UNLIMITED'
});

export const MISSION_CATEGORY = Object.freeze({
  GENERAL: 'GENERAL',
  GOLD: 'GOLD',
  MUTUAL_FUNDS: 'MUTUAL_FUNDS',
  CRYPTO: 'CRYPTO',
  GLOBAL_STOCKS: 'GLOBAL_STOCKS'
});

export const MISSION_REWARD_TRIGGER_TRACKED_BY = Object.freeze({
  COUNT: 'count',
  AMOUNT: 'amount'
});

export const MISSION_REWARD_ASSET_CLASS = Object.freeze({
  CRYPTOCURRENCY: 'cryptocurrency',
  GLOBAL_SINGLE_STOCKS: 'global_stock'
});

export const MISSION_REWARD_CLAIM_STATUS = {
  PENDING: 'PENDING',
  DISBURSED: 'DISBURSED',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  CLAIMED: 'CLAIMED'
};

export const MISSION_REWARD_TRIGGER_ASSET_CLASS = Object.freeze({
  CRYPTOCURRENCY: 'cryptocurrency',
  MUTUAL_FUND: 'fund',
  INDONESIAN_EQUITY: 'INDONESIAN_EQUITY',
  GLOBAL_EQUITY: 'stock_index',
  GLOBAL_SINGLE_STOCKS: 'global_single_stocks',
  GOLD: 'gold',
  ANY: 'any'
});

export const MISSION_REWARD_TRIGGER_TYPE = Object.freeze({
  CUMULATIVE_BUY_TRANSACTION: 'CUMULATIVE_BUY_TRANSACTION',
  CUMULATIVE_TOPUP_AMOUNT: 'CUMULATIVE_TOPUP_AMOUNT',
  FIRST_ORDER: 'FIRST_ORDER',
  KYC_COMPLETION: 'KYC_COMPLETION',
  SINGLE_BUY_TRANSACTION: 'SINGLE_BUY_TRANSACTION',
  SINGLE_BUY_AND_HOLD: 'SINGLE_BUY_AND_HOLD',
  CUMULATIVE_BUY_AND_HOLD: 'CUMULATIVE_BUY_AND_HOLD',
});

export const MISSION_REWARD_TRIGGER_PAYEMT_METHOD = Object.freeze({
  LINKAJA_WALLET: 'linkaja wallet',
  GOPAY_WALLET: 'gopay wallet',
  DANA_WALLET: 'DANA Wallet',
  PLUANG_VOUCHER: 'pluang voucher',
  BANK_TRANSFER: 'Bank Transfer',
  ANY: 'any'
});

export const MISSION_REWARD_TRIGGER_KYC_TYPE = Object.freeze({
  KYC1: 'KYC1',
  KYC2: 'KYC2',
  GSS_KYC: 'GSS_KYC'
});

export const MISSION_REWARD_UNIT = Object.freeze({
  IDR: 'IDR',
  USD: 'USD'
});

export const MISSION_STATUS = {
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  INACTIVE: 'INACTIVE'
};

export const MISSION_TYPE = Object.freeze({
  REGULAR: 'REGULAR',
  SECRET: 'SECRET'
});

export const MISSION_CTA_LINK = {
  CRYPTO_HOME_SCREEN: '{ "type": "SCREEN", "target": "EXPLORE_CRYPTO_SCREEN" }',
  GLOBAL_STOCK_HOME_SCREEN: '{  "type": "SCREEN", "target": "EXPLORE_US_STOCK_SCREEN" }',
  MUTUAL_FUND_PRODUCT_SCREEN: '{ "type": "SCREEN", "target": "EXPLORE_MUTUAL_FUND_SCREEN" }',
  GOLD_SCREEN: '{ "type": "SCREEN", "target": "EXPLORE_GOLD_SCREEN" }',
  WALLET_SCREEN: '{  "type": "SCREEN", "target": "wallet_screen"}',
  IDENTITY_VERIFICATION_SCREEN: '{  "type": "SCREEN", "target": "identity_verification_screen" }',
  KYC2_SCREEN: '{ "type": "SCREEN", "target": "KYC2_SCREEN" }',
  GSS_KYC_SCREEN: '{ "type": "SCREEN", "target": "GSS_KYC_SCREEN" }'
};

export const upcomingMissionFilters = [
  {
    id: 'title',
    type: 'text',
    label: 'Mission Title'
  },
  {
    id: 'missionPartnerId',
    type: 'dropdown',
    label: 'Partner',
    default: '',
    options: [{ text: 'Partner Name', value: '' }]
  },
  {
    id: 'missionType',
    type: 'dropdown',
    label: 'Status',
    default: '',
    options: [
      { text: 'Type', value: '' },
      { text: 'Normal', value: 'REGULAR' },
      { text: 'Secret', value: 'SECRET' }
    ]
  }
];

export const pastMissionFilters = [
  {
    id: 'title',
    type: 'text',
    label: 'Mission Title'
  },
  {
    id: 'missionPartnerId',
    type: 'dropdown',
    label: 'Partner',
    default: '',
    options: [{ text: 'Partner Name', value: '' }]
  },
  {
    id: 'missionType',
    type: 'dropdown',
    label: 'Status',
    default: '',
    options: [
      { text: 'Type', value: '' },
      { text: 'Normal', value: 'REGULAR' },
      { text: 'Secret', value: 'SECRET' }
    ]
  }
];

export const MISSION_COLOR_AND_ASSET_ICON = Object.freeze({
  GOLD_GOLD: 'GOLD',
  CYAN_MUTUAL_FUND: 'MUTUAL_FUND',
  PURPLE_CRYPTO: 'CRYPTO',
  GREEN_GSS: 'GSS',
  DARK_GREEN_IDR: 'IDR',
  BLUE_GENERAL: 'GENERAL'
});

export const missionTypeOptions = [
  { id: MISSION_TYPE.REGULAR, name: 'Normal' },
  { id: MISSION_TYPE.SECRET, name: 'Secret' }
];

export const missionCategoryTypeOptions = [
  { id: MISSION_CATEGORY.GENERAL, name: 'General' },
  { id: MISSION_CATEGORY.GOLD, name: 'Gold' },
  { id: MISSION_CATEGORY.MUTUAL_FUNDS, name: 'Mutual funds' },
  { id: MISSION_CATEGORY.CRYPTO, name: 'Cryptocurrencies' },
  { id: MISSION_CATEGORY.GLOBAL_STOCKS, name: 'Global Single Stocks' }
];

export const missionColorAndAssetIconTypeOptions = [
  { id: MISSION_COLOR_AND_ASSET_ICON.GOLD_GOLD, name: 'Gold - Gold ' },
  { id: MISSION_COLOR_AND_ASSET_ICON.CYAN_MUTUAL_FUND, name: 'Cyan - Mutual Fund' },
  { id: MISSION_COLOR_AND_ASSET_ICON.PURPLE_CRYPTO, name: 'Purple - Crypto' },
  { id: MISSION_COLOR_AND_ASSET_ICON.GREEN_GSS, name: 'Green - GSS' },
  { id: MISSION_COLOR_AND_ASSET_ICON.DARK_GREEN_IDR, name: 'Dark Green - IDR' },
  { id: MISSION_COLOR_AND_ASSET_ICON.BLUE_GENERAL, name: 'Blue - General' }
];

export const missionCtaLinkTypeOptions = [
  { id: MISSION_CTA_LINK.CRYPTO_HOME_SCREEN, name: 'Crypto' },
  { id: MISSION_CTA_LINK.GLOBAL_STOCK_HOME_SCREEN, name: 'US stocks' },
  { id: MISSION_CTA_LINK.MUTUAL_FUND_PRODUCT_SCREEN, name: 'Mutual Fund' },
  { id: MISSION_CTA_LINK.GOLD_SCREEN, name: 'GOLD' },
  { id: MISSION_CTA_LINK.WALLET_SCREEN, name: 'Wallet Page' },
  { id: MISSION_CTA_LINK.IDENTITY_VERIFICATION_SCREEN, name: 'KYC-1' },
  { id: MISSION_CTA_LINK.KYC2_SCREEN, name: 'KYC-2' },
  { id: MISSION_CTA_LINK.GSS_KYC_SCREEN, name: 'GSS KYC' }
];

export const MISSION_SEGMENT_TYPE = Object.freeze({
  OPEN_FOR_ALL: 'OPEN_FOR_ALL',
  CUSTOM: 'CUSTOM',
  PREVIOUS_CUSTOM_SEGMENT: 'PREVIOUS_CUSTOM_SEGMENT'
});

export const MISSION_CTA_LINK_MAPPING = {
  EXPLORE_CRYPTO_SCREEN: '{ "type": "SCREEN", "target": "EXPLORE_CRYPTO_SCREEN" }',
  EXPLORE_US_STOCK_SCREEN: '{  "type": "SCREEN", "target": "EXPLORE_US_STOCK_SCREEN" }',
  EXPLORE_MUTUAL_FUND_SCREEN: '{ "type": "SCREEN", "target": "EXPLORE_MUTUAL_FUND_SCREEN" }',
  EXPLORE_GOLD_SCREEN: '{ "type": "SCREEN", "target": "EXPLORE_GOLD_SCREEN" }',
  wallet_screen: '{  "type": "SCREEN", "target": "wallet_screen"}',
  identity_verification_screen: '{  "type": "SCREEN", "target": "identity_verification_screen" }',
  KYC2_SCREEN: '{ "type": "SCREEN", "target": "KYC2_SCREEN" }',
  GSS_KYC_SCREEN: '{ "type": "SCREEN", "target": "GSS_KYC_SCREEN" }'
};

export const missionSegmentTypeOptions = [
  { id: MISSION_SEGMENT_TYPE.CUSTOM, name: 'New Custom Segment' },
  { id: MISSION_SEGMENT_TYPE.OPEN_FOR_ALL, name: 'Open for All' },
  { id: MISSION_SEGMENT_TYPE.PREVIOUS_CUSTOM_SEGMENT, name: 'Previous Custom Segment' }
];

export const MISSION_GOAL = Object.freeze({
  REACTIVATION_CONVERSION: 'REACTIVATION/CONVERSION',
  ACQUISITION: 'ACQUISITION'
});

export const missionGoalOptions = [
  { id: MISSION_GOAL.REACTIVATION_CONVERSION, name: 'Reactivation/Conversion' },
  { id: MISSION_GOAL.ACQUISITION, name: 'Acquisition' }
];

export const missionRewardAssetClass = [
  { id: MISSION_REWARD_ASSET_CLASS.CRYPTOCURRENCY, name: 'Cryptocurrency' },
  { id: MISSION_REWARD_ASSET_CLASS.GLOBAL_SINGLE_STOCKS, name: 'Global Single Stocks' }
];

export const missionRedemptionQuotaTypeOptions = [
  { id: REDEMPTION_QUOTA_TYPE.LIMITED, name: 'Limited' },
  { id: REDEMPTION_QUOTA_TYPE.UNLIMITED, name: 'Unlimited' }
];

export const missionRewardTriggerTypeOptions = [
  {
    id: MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_BUY_TRANSACTION,
    name: 'Cumulative buy transaction'
  },
  { id: MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_TOPUP_AMOUNT, name: 'Cumulative topup amount' },
  { id: MISSION_REWARD_TRIGGER_TYPE.KYC_COMPLETION, name: 'Kyc completion' },
  { id: MISSION_REWARD_TRIGGER_TYPE.SINGLE_BUY_TRANSACTION, name: 'Single buy transaction' },
  { id: MISSION_REWARD_TRIGGER_TYPE.SINGLE_BUY_AND_HOLD, name: 'Single buy and hold' },
  { id: MISSION_REWARD_TRIGGER_TYPE.CUMULATIVE_BUY_AND_HOLD, name: 'Cumulative buy and hold' }
];

export const missionRewardKYCTypeOptions = [
  {
    id: MISSION_REWARD_TRIGGER_KYC_TYPE.KYC1,
    name: 'KYC1'
  },
  {
    id: MISSION_REWARD_TRIGGER_KYC_TYPE.KYC2,
    name: 'KYC2'
  },
  {
    id: MISSION_REWARD_TRIGGER_KYC_TYPE.GSS_KYC,
    name: 'GSS_KYC'
  }
];

export const missionRewardTriggerTrackedByOptions = [
  { id: MISSION_REWARD_TRIGGER_TRACKED_BY.COUNT, name: 'Count' },
  { id: MISSION_REWARD_TRIGGER_TRACKED_BY.AMOUNT, name: 'Amount' }
];

export const missionRewardTriggerAssetClassOptions = [
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.CRYPTOCURRENCY, name: 'Cryptocurrency' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.GLOBAL_EQUITY, name: 'Global equity' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.MUTUAL_FUND, name: 'Mutual fund' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.GOLD, name: 'Gold' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.INDONESIAN_EQUITY, name: 'Indonesian Equity' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.GLOBAL_SINGLE_STOCKS, name: 'Global Single Stocks' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.ANY, name: 'All' }
];

export const missionRewardTriggerAssetClassForBuyAndHoldOptions = [
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.CRYPTOCURRENCY, name: 'Cryptocurrency' },
  { id: MISSION_REWARD_TRIGGER_ASSET_CLASS.GLOBAL_SINGLE_STOCKS, name: 'Global Single Stocks' },
];

export const missionRewardTriggerTypePaymentMethodOptions = [
  { id: MISSION_REWARD_TRIGGER_PAYEMT_METHOD.LINKAJA_WALLET, name: 'linkaja wallet' },
  { id: MISSION_REWARD_TRIGGER_PAYEMT_METHOD.GOPAY_WALLET, name: 'gopay wallet' },
  { id: MISSION_REWARD_TRIGGER_PAYEMT_METHOD.DANA_WALLET, name: 'DANA Wallet' },
  { id: MISSION_REWARD_TRIGGER_PAYEMT_METHOD.PLUANG_VOUCHER, name: 'pluang voucher' },
  { id: MISSION_REWARD_TRIGGER_PAYEMT_METHOD.BANK_TRANSFER, name: 'Bank Transfer' },
  { id: MISSION_REWARD_TRIGGER_PAYEMT_METHOD.ANY, name: 'ALL' }
];

export const MISSION_REDEMPTION_MEDIUM = Object.freeze({
  APP_ONLY: 'APP_ONLY',
  APP_AND_WEBSITE: 'APP_AND_WEBSITE'
});

export const missionFlagOptions = [
  { label: 'App', value: MISSION_REDEMPTION_MEDIUM.APP_ONLY },
  { label: 'Website+App', value: MISSION_REDEMPTION_MEDIUM.APP_AND_WEBSITE }
];

export const ReadPermission = 'read';
export const WritePermission = 'write';

export const MISSION_REWARD_LOCK = Object.freeze({
  DEFAULT_DAYS: 45,
  MIN_DAYS: 1,
  MAX_DAYS: 120
});
