import { subMonths } from 'date-fns';
import Joi from 'joi';

const currentDate = new Date();
const acceptedMonth = subMonths(currentDate, 1).getMonth() + 1;
const acceptedYear = acceptedMonth === 12 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

export const disburseUsdYieldSchema = Joi.object({
  month: Joi.number().integer().positive().valid(acceptedMonth).required(),
  year: Joi.number().integer().positive().valid(acceptedYear).required(),
});
