import { Add, Remove } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SidebarMenuComponent from 'components/molecules/SideMenuComponent';
import PropTypes from 'prop-types';
import { useState } from 'react';

const SidebarMenuItem = (props) => {
  const { name, child, location, link, reload, Icon, items = [] } = props;
  const isExpandable = items && Array.isArray(items) && items.length > 0;
  const foundElement = items.some((item) => {
    const currentPath = location.split('/');
    if (item.items) {
      return item.items.some((item) => {
        const menuPath = item.link.split('/');
        return menuPath[1] === currentPath[1] && menuPath[2] === currentPath[2];
      });
    } else {
      const menuPath = item.link.split('/');
      return menuPath[1] === currentPath[1] && menuPath[2] === currentPath[2];
    }
  });
  const [open, setOpen] = useState(foundElement);

  const handleClick = () => setOpen(!open);

  const MenuItemRoot = (
    <SidebarMenuComponent
      button
      selected={foundElement}
      reload={reload}
      link={link}
      onClick={handleClick}>
      {!!Icon && (
        <ListItemIcon
          sx={{
            minWidth: '40px',
            [`.MuiSvgIcon-root`]: { fontSize: '20px', color: 'white' }
          }}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            color: 'white',
            fontFamily: 'Open Sans", sans-serif'
          }
        }}
        primary={name}
        inset={!Icon}
      />
      {isExpandable &&
        (open ? (
          <Remove sx={{ fontSize: '20px', color: 'white' }} />
        ) : (
          <Add sx={{ fontSize: '20px', color: 'white' }} />
        ))}
    </SidebarMenuComponent>
  );

  const MenuItemChildren = isExpandable && (
    <Collapse
      sx={{
        [`.MuiListItem-root`]: { paddingLeft: `${25 * child}px` },
        [`.MuiCollapse-wrapper`]: { background: 'rgba(255,255,255,.05)' }
      }}
      in={open}
      timeout="auto"
      unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <SidebarMenuItem child={child + 1} location={location} {...item} key={index} />
        ))}
      </List>
    </Collapse>
  );

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

SidebarMenuItem.propTypes = {
  name: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
  link: PropTypes.string,
  child: PropTypes.number,
  location: PropTypes.string,
  reload: PropTypes.bool
};

export default SidebarMenuItem;
