import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { LOCAL } from 'constants/clientRoutes';
import { IMAGES } from 'constants/images';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from 'utils/redux';

const Welcome = () => {
  const [appState] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    appState.user?.roles?.includes('SuperAdmin') && navigate(`${LOCAL.ADMIN}`);
  }, [appState]);

  return (
    <Container>
      <Card sx={{ borderRadius: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
            <img src={IMAGES.PLUANG_LOGIN_LOGO} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 3 }}>
            <Typography sx={{ my: 3 }} variant="h6">
              Welcome to Pluang Admin Panel
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Welcome;
