import { MAX_VOUCHER_QUANTITY } from 'config';
import { VOUCHER_DENOMINATIONS } from 'containers/vs/createPurchaseOrderMerchant/edit';
import Joi from 'joi';

const denominations = {};

VOUCHER_DENOMINATIONS.forEach(({ displayValue, value }) => {
  denominations[value] = Joi.number().integer().min(1).max(MAX_VOUCHER_QUANTITY).label(displayValue).allow('');
});

export const purchaseOrderSchema = Joi.object({
  ...denominations,
  orderReferenceNumber: Joi.string().alphanum().min(4).max(20).required(),
  phone: Joi.string().min(8).max(15).allow('', null),
  description: Joi.string().allow('', null),
  expires: Joi.date().required()
}).options({ allowUnknown: true });
