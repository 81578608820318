import { Flip, FlipCameraAndroid, Undo, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useLoader, useNotification } from 'services/hooks';

const ImageViewer = ({ imgSrc, fullWidth = false }) => {
  const [originalHeight, setOriginalHeight] = useState(500);
  const initialState = { deg: 0, flip: 1, height: originalHeight };
  const [imageState, setImageState] = useState(initialState);
  const imageRef = useRef();
  const [, setLoader] = useLoader();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (imgSrc) {
      setLoader(true);
    }
  }, []);

  const onImageLoad = () => {
    setOriginalHeight(imageRef.current.clientHeight);
    setImageState((prevState) => ({ ...prevState, height: imageRef.current.clientHeight }));
    setLoader(false);
  };

  const imageAction = (type) => {
    if (type === 'reset') {
      setImageState(initialState);
    } else if (type === 'zoomIn') {
      setImageState((prevState) => ({
        ...prevState,
        height: prevState.height + 100
      }));
    } else if (type === 'zoomOut' && imageState.height > 200) {
      setImageState((prevState) => ({
        ...prevState,
        height: prevState.height - 100
      }));
    } else if (type === 'flip') {
      setImageState((prevState) => ({
        ...prevState,
        flip: prevState.flip * -1
      }));
    } else if (type === 'rotate') {
      setImageState((prevState) => ({
        ...prevState,
        deg: prevState.deg + 90
      }));
    }
  };

  return (
    <Box sx={{ width: fullWidth ? '100%' : '50%' }}>
      <DialogTitle>Image Viewer</DialogTitle>
      <DialogContent sx={{ minHeight: '510px', maxHeight: '510px' }} dividers>
        <Box
          component="img"
          ref={imageRef}
          src={imgSrc}
          sx={{
            height: imageState.height,
            transform: `rotate(${imageState.deg}deg) scaleX(${imageState.flip})`
          }}
          onLoad={onImageLoad}
          onError={() => {
            showNotification('error', 'Some error occurred while loading the image');
            setLoader(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <IconButton
          disabled={imageState.height === originalHeight}
          onClick={() => imageAction('reset')}>
          <Undo />
        </IconButton>
        <IconButton onClick={() => imageAction('zoomIn')}>
          <ZoomIn />
        </IconButton>
        <IconButton onClick={() => imageAction('zoomOut')}>
          <ZoomOut />
        </IconButton>
        <IconButton onClick={() => imageAction('flip')}>
          <Flip />
        </IconButton>
        <IconButton onClick={() => imageAction('rotate')}>
          <FlipCameraAndroid />
        </IconButton>
      </DialogActions>
    </Box>
  );
};

ImageViewer.propTypes = {
  imgSrc: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default ImageViewer;
