import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button } from '@mui/material';
import FormTextField from 'components/molecules/FormTextField';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { forgot } from 'services/AuthService';
import { useNotification } from 'services/hooks';
import { forgotSchema } from 'utils/schemas';

const Forgot = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: { email: '' },
    resolver: joiResolver(forgotSchema)
  });
  const { showNotification } = useNotification();

  const sendResetRequest = ({ email }) => {
    setIsButtonDisabled(true);
    forgot(email)
      .then(() => {
        showNotification('success', 'Password reset link has been sent to your registered email');
        navigate(-1);
      })
      .catch((error) => {
        showNotification('error', error?.error_message);
      })
      .finally(() => setIsButtonDisabled(false));
  };

  return (
    <Box component="form" onSubmit={methods.handleSubmit(sendResetRequest)}>
      <FormTextField methods={methods} label="Email address" name="email" type="email" />
      <Box mt={1}>
        <Button size="small" variant="contained" type="submit" disabled={isButtonDisabled}>
          Submit
        </Button>
        <Button sx={{ ml: 2 }} onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default Forgot;
