export const LOCAL = {
  ADMIN: '/super',
  FORGOT: '/forgot',
  LOGIN: '/login',
  MAINTENANCE: '/maintenance',
  RESET: '/reset/:adminId/:secretKey',
  AC: '/ac',
  OPS: '/ops',
  CS: '/cs',
  FI: '/fi',
  VS: '/vs',
  AS: '/as',
  MF: '/mf'
};
