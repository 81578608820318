import Joi from 'joi';

export const eIPOSchema = Joi.object({
  companyTicker: Joi.string().required(),
  companyName: Joi.string().required(),
  sector: Joi.string().required(),
  subsector: Joi.string().required(),
  businessEngagement: Joi.string().required(),
  companySummary: Joi.string().required(),
  address: Joi.string().required(),
  companyWebsite: Joi.string().required(),
  numberOfSharesOffered: Joi.number().integer().positive().required(),
  percentageOfTotalShares: Joi.number().positive().required(),
  administrator: Joi.string().required(),
  underwriter: Joi.string().required(),
  bookBuildingStartDate: Joi.date().required(),
  bookBuildingEndDate: Joi.date().required(),
  bookBuildingMinimumPrice: Joi.number().positive().required(),
  bookBuildingMaximumPrice: Joi.number().positive().required(),
  publicOfferingStartDate: Joi.date().required(),
  publicOfferingEndDate: Joi.date().required(),
  publicOfferingPrice: Joi.number().positive().required(),
  allotmentDate: Joi.date().required(),
  distributionDate: Joi.date().required(),
  listingDate: Joi.date().required()
});
