export const ASSET_CATEGORY = Object.freeze({
  CRYPTOCURRENCY: 'CRYPTOCURRENCY',
  STOCK_INDEX: 'STOCK_INDEX'
});

export const ALERT_TYPE = Object.freeze({
  HIGH_LOW_SIGNAL: 'HIGH_LOW_SIGNAL',
  TOP_GAINER_LOSER: 'TOP_GAINER_LOSER',
  SIGNIFICANT_PRICE_CHANGE: 'SIGNIFICANT_PRICE_CHANGE'
});

export const CRYPTOCURRENCIES = Object.freeze({
  BTC: 'BTC',
  ETH: 'ETH'
});

export const GLOBAL_STOCKS = Object.freeze({
  SNP500: 'SNP500'
});

export const STATUS = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
});

export const ACTIVE_TIME_FRAMES = Object.freeze({
  TWENTY_FOUR_HOUR: 'TWENTY_FOUR_HOUR',
  ONE_WEEK: 'ONE_WEEK',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTH: 'THREE_MONTH',
  ONE_YEAR: 'ONE_YEAR',
  NOT_APPLICABLE: 'NOT_APPLICABLE'
});

export const assetCategoryOptions = [
  { id: ASSET_CATEGORY.CRYPTOCURRENCY, name: 'Crypto' },
  { id: ASSET_CATEGORY.STOCK_INDEX, name: 'GSS' }
];

export const alertTypeOptions = [
  { id: ALERT_TYPE.HIGH_LOW_SIGNAL, name: 'High/Low Signal' },
  { id: ALERT_TYPE.TOP_GAINER_LOSER, name: 'Top Gainers/Losers' },
  { id: ALERT_TYPE.SIGNIFICANT_PRICE_CHANGE, name: 'Significant Price Change' }
];

export const CRYPTO_OPTIONS = [
  { id: CRYPTOCURRENCIES.BTC, name: 'BTC' },
  { id: CRYPTOCURRENCIES.ETH, name: 'ETH' }
];

export const GSS_OPTIONS = [{ id: GLOBAL_STOCKS.SNP500, name: 'S&P 500' }];

export const activeTimeFrameOptions = [
  { id: ACTIVE_TIME_FRAMES.TWENTY_FOUR_HOUR, name: '24 H' },
  { id: ACTIVE_TIME_FRAMES.ONE_WEEK, name: '1 W' },
  { id: ACTIVE_TIME_FRAMES.ONE_MONTH, name: '1 M' },
  { id: ACTIVE_TIME_FRAMES.THREE_MONTH, name: '3 M' },
  { id: ACTIVE_TIME_FRAMES.ONE_YEAR, name: '1 Y' }
];

export const ASSET_NO = '{assetNo}';

export const ASSET_TICKER = '{assetTicker}';

export const PERCENTAGE = '{percentage}';

export const SEGMENT_DEFAULT = 'default';

export const initialNotificationState = {
  notificationTitleEn1: '',
  notificationTitleEn2: '',
  notificationBodyEn1: '',
  notificationBodyEn2: '',
  notificationBodyEn3: '',
  notificationTitleId1: '',
  notificationTitleId2: '',
  notificationBodyId1: '',
  notificationBodyId2: '',
  notificationBodyId3: '',
  topLoserTitleEn1: '',
  topLoserTitleEn2: '',
  topLoserBodyEn1: '',
  topLoserBodyEn2: '',
  topLoserBodyEn3: '',
  topLoserTitleId1: '',
  topLoserTitleId2: '',
  topLoserBodyId1: '',
  topLoserBodyId2: '',
  topLoserBodyId3: ''
};
