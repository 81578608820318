import { JoiNumber } from 'helpers/joiExtensions';
import Joi from 'joi';

const methodToCheckPreviousSmaller = (value, helpers, label) => {
  const {
    state: { ancestors, path }
  } = helpers;
  const index = path[1];
  if (index > 0) {
    if (value <= ancestors[1][index - 1][label]) {
      return helpers.error('custom.invalid');
    }
  }
  return value;
};

const customBuyBack = (value, helpers) => {
  const {
    state: { ancestors, path }
  } = helpers;
  if (ancestors[0].sellPrice < ancestors[0].buyBackPrice) {
    return helpers.error('custom.invalid');
  }
  return value;
};

export const fixedSchema = Joi.object({
  buyBackPrice: JoiNumber.normalizeValue()
    .integer()
    .min(1)
    .label('BuyBack Price')
    .custom((value, helpers) => customBuyBack(value, helpers))
    .messages({
      'custom.invalid': 'Buy Back Price must be smaller than sell price'
    }),
  sellPrice: JoiNumber.normalizeValue()
    .integer()
    .min(1)
    .label('Sell Price')
    .custom((value, helpers) => customBuyBack(value, helpers))
    .messages({
      'custom.invalid': 'Sell price must be greater that Buy Back Price'
    })
});

export const dynamicSchema = Joi.object({
  varTierSpreads: Joi.array()
    .items(
      Joi.object({
        tier: Joi.number().integer().positive(),
        thresholdValue: JoiNumber.normalizeValue()
          .positive()
          .min(0)
          .label('Threshold Value')
          .custom((value, helpers) =>
            methodToCheckPreviousSmaller(value, helpers, 'thresholdValue')
          )
          .messages({
            'custom.invalid': 'Threshold should be greater than previous one'
          }),
        spread: Joi.number()
          .positive()
          .min(0)
          .label('Spread')
          .custom((value, helpers) => methodToCheckPreviousSmaller(value, helpers, 'spread'))
          .messages({
            'custom.invalid': 'Spread should be greater than previous one'
          })
      })
    )
    .required()
});
