export const globalStyles = {
  '.MuiButton-root .MuiButton-startIcon': {
    marginRight: 2
  },
  '.MuiButton-root .MuiButton-endIcon': {
    marginLeft: 2
  },
  '.MuiTableCell-root.MuiTableCell-head': {
    fontWeight: 600
  },
  '.MuiInputAdornment-root p.MuiTypography-root.MuiTypography-body1': {
    fontSize: 12
  },
  'li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root': {
    fontSize: 12
  },
  '.MuiSelect-select .MuiChip-root': {
    height: 20
  },
  '.MuiRadio-root .MuiSvgIcon-root': {
    height: 18,
    width: 18
  },
  '.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root': {
    color: 'rgba(0, 0, 0, 0.26)'
  }
};
