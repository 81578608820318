import { TENURE_OPTIONS } from 'config';
import { GOLD_OPTIONS } from 'config';
import Joi from 'joi';

export const partnerSchema = Joi.object({
  id: Joi.number().integer().positive(),
  companyName: Joi.string().required(),
  companyDomain: Joi.string().required(),
  contactPerson: Joi.string().required(),
  contractUrl: Joi.string().allow(null, '').required(),
  email: Joi.string().email({ tlds: { allow: false } }),
  phone: Joi.string().min(8).max(15).required(),
  kycType: Joi.string().valid('PARTNER_RESPONSIBILITY', 'PARTNER_VERIFIED', 'MANUAL').required(),
  joinDate: Joi.date().raw().required(),
  active: Joi.boolean().required(),
  allowSell: Joi.boolean().required(),
  allowBuy: Joi.boolean().required(),
  allowInstallment: Joi.boolean().required(),
  goldOptions: Joi.array()
    .items(Joi.string().valid(...GOLD_OPTIONS))
    .required(),
  tenureOptions: Joi.array()
    .items(Joi.string().valid(...TENURE_OPTIONS))
    .required(),
  pks: Joi.string().required(),
  downPayment: Joi.number().min(0).max(100).required(),
  interestRate: Joi.number().min(0).max(100).required(),
  spread: Joi.number().min(0).max(100).required(),
  goldRedemptionFees: Joi.number().min(0).max(100).required(),
  adminFeeSell: Joi.number().min(0).max(100).required(),
  adminFeeBuy: Joi.number().min(0).max(100).required(),
  adminFeeInstallment: Joi.number().integer().min(0).required(),
  commissionSell: Joi.number().min(0).max(100).required(),
  commissionBuy: Joi.number().min(0).max(100).required(),
  commissionInstallment: Joi.number().min(0).max(100).required(),
  partnerSellPrice: Joi.number().min(-100).max(100).required(),
  partnerInstallmentPrice: Joi.number().min(-100).max(100).required(),
  partnerBuyBackPrice: Joi.number().min(0).max(100).required(),
  password: Joi.string().allow(null, '').optional(),
  latePaymentFee: Joi.number().min(0).max(100).required(),
  paymentDefault: Joi.number().min(0).max(100).required(),
  minimalGoldBuyAmount: Joi.number().min(0).max(100).required(),
  minimalGoldSellAmount: Joi.number().min(0).max(100).required(),
  minimalGoldInstallmentAmount: Joi.number().min(0).max(100).required(),
  emailNotification: Joi.boolean().required()
});
