import BaseJoi from 'joi';
import JoiExtension from '@joi/date';
const Joi = BaseJoi.extend(JoiExtension);

export const sInvestUploadSchema = Joi.object({
  Nik: Joi.string().max(50).required(),
  SID: Joi.string().required(),
  IFUA: Joi.string().required(),
  'Reason Id': Joi.string().allow(''),
  'Reason En': Joi.string().allow(''),
  'Client Code': Joi.when('Reason Id', {
    is: Joi.string(),
    then: Joi.string().allow(null, ''),
    otherwise: Joi.string().required(),
  }),
  'Opening Date': Joi.when('Reason Id', {
    is: Joi.string(),
    then: Joi.string().allow(null, ''),
    otherwise: Joi.date().format('YYYY-MM-DD').required(),
  })
}).required();

export const sInvestForeignerUploadSchema = Joi.object({
  'Passport Number': Joi.string().required(),
  Country: Joi.string().max(50).required(),
  SID: Joi.string().required(),
  IFUA: Joi.string().required(),
  'Reason Id': Joi.string().allow(''),
  'Reason En': Joi.string().allow(''),
  'Client Code': Joi.when('Reason Id', {
    is: Joi.string(),
    then: Joi.string().allow(null, ''),
    otherwise: Joi.string().required(),
  }),
  'Opening Date': Joi.when('Reason Id', {
    is: Joi.string(),
    then: Joi.string().allow(null, ''),
    otherwise: Joi.date().format('YYYY-MM-DD').required(),
  })
}).required();
