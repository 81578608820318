import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import RedStar from 'components/atoms/RedStar';
import PropTypes from 'prop-types';
import ImageViewer from '../ImageViewer';

const EditDialog = ({
  children,
  showModal,
  setShowModal,
  title,
  ktpOcrData,
  idCardPicture,
  handleSubmit
}) => {
  return (
    <Dialog maxWidth="xl" fullWidth open={showModal} onClose={() => setShowModal(false)}>
      <Box sx={{ display: 'flex' }}>
        {idCardPicture && <ImageViewer imgSrc={idCardPicture} />}
        <Divider orientation="vertical" flexItem />
        <Box sx={{ width: '50%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <DialogTitle>{title}</DialogTitle>
            {ktpOcrData && (
              <Box sx={{ pr: 3 }}>
                <Typography sx={{ fontSize: 14 }}>
                  <RedStar />
                  Represents data fetched from ocr
                </Typography>
                {ktpOcrData && ktpOcrData.verifiedById && !ktpOcrData.cityCode && (
                  <Typography sx={{ color: 'red' }}>
                    City code not found, please check your input data or update the city code
                    manually
                  </Typography>
                )}
                {ktpOcrData && ktpOcrData.verifiedById && ktpOcrData.cityCode && (
                  <Typography sx={{ color: 'green', fontSize: 14 }}>VERIFIED BY ADMIN</Typography>
                )}
              </Box>
            )}
          </Box>
          <DialogContent sx={{ minHeight: '510px', maxHeight: '510px' }} dividers>
            {children}
          </DialogContent>
          <DialogActions sx={{ mt: 0.5 }}>
            <Button color="success" size="small" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
            <Button
              sx={{ ml: 2 }}
              size="small"
              variant="outlined"
              onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

EditDialog.propTypes = {
  children: PropTypes.node.isRequired,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  idCardPicture: PropTypes.string,
  title: PropTypes.string,
  ktpOcrData: PropTypes.object,
  handleSubmit: PropTypes.func
};

export default EditDialog;
