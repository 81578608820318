import { Box, Button, Typography } from '@mui/material';
import AlertDialog from 'components/molecules/AlertDialog';
import BasicTable from 'components/molecules/BasicTable';
import KycTask from 'components/molecules/KycTask';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';

const UserBanks = ({
  startTask,
  escalateTask,
  mutualFundBankData,
  idssBankData,
  otherBanksData,
  escalate,
  setEscalate,
  caseId,
  isAutoAssign = true
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();

  const escalateBankTask = () => {
    escalateTask('BANK_ACCOUNT', () => {
      setEscalate((prevState) => ({
        ...prevState,
        isUserBanksEscalated: true
      }));
    });
  };

  const otherBanksTableData = {
    label: otherBanksData.data
      ? otherBanksData.data.map((bankData) => (
          <Box
            key={bankData.id}
            sx={{
              display: 'flex',
              mt: 0.5,
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Typography>{bankData.id}</Typography>
          </Box>
        ))
      : [],
    value: otherBanksData.data
      ? otherBanksData.data.map((bankData) => (
          <Box
            key={bankData.id}
            sx={{
              display: 'flex',
              mt: 0.5,
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            {bankData.status}
            <Button
              variant="contained"
              size="small"
              disabled={!bankData.id}
              onClick={() =>
                isAutoAssign
                  ? navigate(`/bankDetails/${bankData.id}`, { state: { caseId } })
                  : navigate(`/cs/userBankAccounts/edit/${bankData.id}`)
              }>
              Bank Detail
            </Button>
          </Box>
        ))
      : []
  };

  const banksTableData = [
    { label: 'Banks', value: 'Status' },
    {
      label: 'Mutual Fund default bank data',
      value: (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {mutualFundBankData.status}
          {mutualFundBankData.status !== 'NOT UPLOADED' && (
            <Button
              variant="contained"
              size="small"
              disabled={!mutualFundBankData.id}
              onClick={() =>
                isAutoAssign
                  ? navigate(`/bankDetails/${mutualFundBankData.id}`, { state: { caseId } })
                  : navigate(`/cs/userBankAccounts/edit/${mutualFundBankData.id}`)
              }>
              Bank Detail
            </Button>
          )}
        </Box>
      )
    },
    {
      label: 'IDSS default bank data',
      value: (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {idssBankData.status}
          {idssBankData.status !== 'NOT UPLOADED' && (
            <Button
              variant="contained"
              size="small"
              disabled={!idssBankData.id}
              onClick={() =>
                isAutoAssign
                  ? navigate(`/bankDetails/${idssBankData.id}`, { state: { caseId } })
                  : navigate(`/cs/userBankAccounts/edit/${idssBankData.id}`)
              }>
              Bank Detail
            </Button>
          )}
        </Box>
      )
    },
    otherBanksTableData
  ];

  const shouldStartTask =
    (mutualFundBankData && mutualFundBankData.status === 'pending_verification') ||
    (idssBankData && idssBankData.status === 'pending_verification') ||
    (otherBanksData && otherBanksData.data
      ? otherBanksData.data.some((x) => x.status === 'pending_verification')
      : otherBanksData.status === 'pending_verification');

  const isEscalated = escalate.isUserBanksEscalated;

  const kycTaskStatus = () => (
    <>
      {isEscalated && (
        <Typography sx={{ border: 1, color: 'red', mr: 2, p: '2px' }}>ESCALATED</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    if (shouldStartTask && !isEscalated && isAutoAssign && isAutoAssign) {
      startTask('BANK_ACCOUNT');
    }
    setShowDetail(!showDetail);
  };

  return (
    <KycTask
      title="Bank Accounts"
      status={kycTaskStatus()}
      onClick={handleKycTaskClick}
      open={showDetail}>
      <BasicTable tableData={banksTableData} />
      {isAutoAssign && (
        <Box display="flex" justifyContent="center" my={2}>
          <Button
            size="small"
            variant="outlined"
            disabled={!shouldStartTask || isEscalated}
            onClick={() =>
              dispatch({
                type: OPEN_MODAL,
                modalContent: (
                  <AlertDialog
                    title="Exit Case"
                    alertMessage="Are you sure want to exit the case?"
                    onConfirm={escalateBankTask}
                  />
                )
              })
            }>
            Escalate
          </Button>
        </Box>
      )}
    </KycTask>
  );
};

UserBanks.propTypes = {
  startTask: PropTypes.func,
  startInterval: PropTypes.func,
  endTask: PropTypes.func,
  escalateTask: PropTypes.func,
  mutualFundBankData: PropTypes.object,
  idssBankData: PropTypes.object,
  otherBanksData: PropTypes.object,
  escalate: PropTypes.object,
  setEscalate: PropTypes.func,
  caseId: PropTypes.number,
  isAutoAssign: PropTypes.bool
};

export default UserBanks;
