import Joi from 'joi';

const UNLIMITED = 'UNLIMITED';

export const dailyLimitSchema = Joi.object({
  active: Joi.boolean(),
  deposit: Joi.string().required(),
  depositLimit: Joi.alternatives()
    .label('Deposit Limit')
    .conditional('deposit', {
      is: UNLIMITED,
      then: Joi.alternatives().try(Joi.string(), Joi.number()).allow(''),
      otherwise: Joi.number().min(0).required()
    }),
  withDrawal: Joi.string().required(),
  withDrawalLimit: Joi.alternatives()
    .label('WithDrawal Limit')
    .conditional('withDrawal', {
      is: UNLIMITED,
      then: Joi.alternatives().try(Joi.string(), Joi.number()).allow(''),
      otherwise: Joi.number().min(0).required()
    })
});

export const sendReceiveConfigSchema = Joi.object({
  sendReceiveConfig: Joi.array().items(
    Joi.object({
      active: Joi.boolean().required(),
      source: Joi.string().required(),
      cryptoCurrencyId: Joi.number().integer().positive().required(),
      custodianAssetId: Joi.string().required(),
      enableDeposit: Joi.boolean().required(),
      enableWithdrawal: Joi.boolean().required(),
      network: Joi.string().required(),
      precisions: Joi.object({
        step: Joi.number().integer().required(),
        tick: Joi.number().integer()
      }).required(),
      transferFee: Joi.object({
        WITHDRAWAL: Joi.object({
          fee: Joi.number().integer().min(0).required().label('Send Fee Amount'),
          currency: Joi.string().required()
        }).required()
      }).required(),
      isEdit: Joi.boolean()
    })
  )
});
