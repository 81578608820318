import Joi from 'joi';
import { JoiNumber } from 'helpers/joiExtensions';

export const searchUserCashoutSchema = Joi.object({
  userId: Joi.string().required().label('User Id')
});

export const cashoutSchema = Joi.object({
  withdrawalAmount: JoiNumber.normalizeValue().min(0).precision(2).label('Minimum Top Up Amount'),
  destinationBankAccount: Joi.string().required(),
  destinationBankAccountOwner: Joi.string().required(),
  destinationBankAccountOwnerAddress: Joi.string().required(),
  destinationBank: Joi.string().required(),
  destinationBankSwift: Joi.string().required(),
  destinationBankAddress: Joi.string().required(),
  destinationBankCity: Joi.string().required(),
  destinationBankCountry: Joi.string().required(),
  proofOfRequest: Joi.any().invalid('', null).required(),
  proofOfBankAccountOwnership: Joi.any().optional()
});

export const csCallConfirmationLinkSchema = Joi.object({
  csCallConfirmationLink: Joi.string().uri().required()
});

export const financeConfirmationSchema = Joi.object({
  financeJiraLink: Joi.string().uri().required(),
  bankTransferSlip: Joi.any().invalid('', null).required()
});
