import Joi from 'joi';

export const refundSchema = Joi.object({
  userId: Joi.string().allow(''),
  bankName: Joi.string().required(),
  bankAccountName: Joi.string().required(),
  bankAccountNumber: Joi.string().required(),
  transferDate: Joi.date().raw().required(),
  jiraTicket: Joi.string().required()
});

export const refundUploadSchema = Joi.object({
  referenceNumber: Joi.string().required()
});

export const uploadBcaCallbackSchema = Joi.object({
  transactionId: Joi.string().required(),
  callBackReferenceId: Joi.string().allow('', null),
});
