import { Download } from '@mui/icons-material';
import { Button, Link } from '@mui/material';
import StatusChip from 'components/atoms/StatusChip';
import PropTypes from 'prop-types';

const S3DownloadLink = ({ url }) => {
  return !url ? (
    <StatusChip color="primary" label="Not available" />
  ) : (
    <Link href={url} target="_blank" underline="none">
      <Button size="small" color="success" variant="contained" startIcon={<Download />}>
        Download
      </Button>
    </Link>
  );
};

S3DownloadLink.propTypes = {
  url: PropTypes.string.isRequired
};

export default S3DownloadLink;
