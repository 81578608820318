import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import AlertDialog from 'components/molecules/AlertDialog';
import BasicTable from 'components/molecules/BasicTable';
import EditDialog from 'components/molecules/EditDialog';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTask from 'components/molecules/KycTask';
import ListDialog from 'components/molecules/ListDialog';
import PrivyDigitalCertificate from 'components/molecules/PrivyDigitalCertificate';
import RejectionReasonContent from 'components/molecules/RejectionReason';
import BasicDetailsForm from 'components/organisms/BasicDetails/BasicDetailsForm';
import { NATIONALITIES } from 'config';
import {
  kycForeignerRejectionOptionsInEn,
  kycForeignerRejectionOptionsInId,
  kycRejectionOptionsInEn,
  kycRejectionOptionsInId
} from 'constants/rejectionOptions';
import { REMOTE } from 'constants/serverRoutes';
import ktpOcrBasicValidation from 'containers/ops/kyc/ktpOcrBasicValidation';
import ktpOcrForeignerValidation from 'containers/ops/kyc/ktpOcrForeignerValidation';
import { dateFormatter } from 'helpers/dateChanger';
import { formatDateTime } from 'helpers/formatDate';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from 'services';
import { useKyc, useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';

const listHeaders = [
  'actorId',
  'actorRole',
  'createdAt',
  'fromState',
  'toState',
  'rejectionReason',
  'rejectionReasonId'
];

const BasicDetails = ({
  startTask,
  startInterval,
  endTask,
  escalateTask,
  getKyc1Data,
  ktpOcrData: ktpOcr,
  escalate,
  kyc1Data: kycData,
  userData,
  setEscalate,
  setKyc1Data,
  userAmlRiskRatingData,
  isAutoAssign = true
}) => {
  const [, setLoader] = useLoader();
  const [appState, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRejectionModal, setRejectionModal] = useState(false);
  const { showNotification } = useNotification();
  const { getKycStateTransitionData, getRejectionJson, generateReason } = useKyc();
  const [checkPartnerNikCase, setCheckPartnerNikCase] = useState(true);
  const [basicDetailsState, setBasicDetailsState] = useState({
    kycData: {},
    userData: {},
    ktpOcrData: {},
    idCardPicture: '',
    selfPortraitPicture: '',
    parentalLetter: '',
    familyCertificate: '',
    npwpPicture: '',
    nationality: '',
    fatcaDocument: null,
    nameOnIdentityCard: '',
    identityNumber: '',
    kycStateTransitionData: [],
    dateOfBirth: null,
    dateOfSubmission: '',
    count: [''],
    values: [],
    viewImage: '',
    deg: 0,
    scale: 1,
    flip: 1,
    englishOptions: [],
    bahasaOptions: [],
    isButtonDisabled: false
  });
  const { dateOfBirth, nameOnIdentityCard, identityNumber } = basicDetailsState;

  useEffect(() => {
    if (basicDetailsState.userData.bappebtiKycStatus === 'PENDING') {
      handleDropdown(basicDetailsState.showDetail);
    }
  }, []);
  useEffect(() => {
    if (Object.keys(ktpOcr).length > 0) {
      setBasicDetailsState((prevState) => ({
        ...prevState,
        nameOnIdentityCard: kycData.nameOnIdentityCard || ktpOcr.nameOnIdentityCard || '',
        identityNumber: kycData.identityCardNumber || ktpOcr.identityNumber || '',
        dateOfBirth: userData.dateOfBirth || ktpOcr.dateOfBirth || new Date()
      }));
    }
  }, [ktpOcr]);

  useEffect(() => {
    if (!checkPartnerNikCase) {
      saveDuplicateB2BModalShow();
    }
  }, [checkPartnerNikCase]);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    rejectionReasonJson = JSON.parse(rejectionReasonJson);
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const updatedStatus = (kycStatus) => {
    let reasonEn = generateReason('en', basicDetailsState);
    let reasonIn = generateReason('in', basicDetailsState);
    const rejectionReasonJson = getRejectionJson(basicDetailsState);
    if (kycStatus === 'REJECTED' && !reasonEn && !reasonIn) {
      showNotification('error', 'Rejection reason is required');
      return;
    }
    const formData = new FormData();
    formData.append('rejectionReason', reasonEn);
    formData.append('fatcaDocument', basicDetailsState.fatcaDocument);
    formData.append('rejectionReasonId', reasonIn);
    formData.append('rejectionReasonJson', JSON.stringify(rejectionReasonJson));
    formData.append('clientId', kycData.clientId);
    if (isAutoAssign) {
      formData.append('isKycAutoAssignPage', true);
    }
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/update/${kycData.userId}/status/${kycStatus}`,
      method: 'PUT',
      data: formData
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const kyc = { ...kycData, status: kycStatus };
          setKyc1Data(kyc);
          endTask?.();
          startInterval?.();
          handleDropdown(false);
          showNotification('success', 'Status updated');
        }
      })
      .catch((err) => {
        showNotification('error', err?.error_message);
      })
      .finally(() => setLoader(false));
  };

  const saveDuplicateB2BModalShow = () => {
    setCheckPartnerNikCase(false);
    if (!checkPartnerNikCase) {
      dispatch({
        type: OPEN_MODAL,
        modalContent: (
          <AlertDialog
            title="Save Duplicate Nik"
            alertMessage="Are you sure?"
            onConfirm={() => handleSubmit()}
          />
        )
      });
      setCheckPartnerNikCase(true);
    } else {
      showNotification('Please try again');
    }
  };

  const updateSanctionStatus = (sanctionStatus) => {
    const payload = {
      userId: userData.id,
      sanctionCheck: sanctionStatus
    };
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.USER_PEP_STATUS}`,
      data: payload
    })
      .then(() => {
        showNotification('success', 'Successfully Updated');
        window.location.reload();
      })
      .catch((error) => {
        showNotification('error', error?.error_message || error?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSubmit = () => {
    let data = {
      userId: ktpOcr.userId,
      citizenship: ktpOcr.citizenship,
      countryOfBirth: ktpOcr.countryOfBirth || NATIONALITIES.INDONESIA,
      placeOfBirth: ktpOcr.placeOfBirth,
      occupation: ktpOcr.occupation,
      religion: ktpOcr.religion,
      gender: ktpOcr.gender,
      bloodType: ktpOcr.bloodType,
      maritalStatus: ktpOcr.maritalStatus,
      expirationDate: ktpOcr.expirationDate && formatDateTime(ktpOcr.expirationDate),
      cityCode: ktpOcr.cityCode,
      dateOfBirth: dateOfBirth ? formatDateTime(dateOfBirth) : null,
      nameOnIdentityCard,
      identityNumber,
      meta: ktpOcr.meta,
      id: ktpOcr.id,
      nationality: kycData.nationality,
      addressJson: {
        address: (ktpOcr.address && ktpOcr.address.address) || '',
        rtRw: (ktpOcr.address && ktpOcr.address.rtRw) || '',
        subDistrict: (ktpOcr.address && ktpOcr.address.subDistrict) || '',
        subDistrictId: (ktpOcr.address && ktpOcr.address.subDistrictId) || '',
        district: (ktpOcr.address && ktpOcr.address.district) || '',
        city: (ktpOcr.address && ktpOcr.address.city) || '',
        province: (ktpOcr.address && ktpOcr.address.province) || '',
        provinceCode: (ktpOcr.address && ktpOcr.address.provinceCode) || ''
      },
      updateBasicDetail: true,
      basicDetailVerifiedById: ktpOcr.basicDetailVerifiedById,
      verifiedById: ktpOcr.verifiedById,
      checkPartnerNikCase
    };
    if (isAutoAssign) {
      data['isKycAutoAssignPage'] = true;
    }
    const errors = ktpOcrBasicValidation(data);
    if (errors.length > 0) {
      errors.forEach((errorText) => {
        showNotification('error', errorText.body, errorText.title);
      });
      return;
    }
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC1}/ktpOcr`,
      data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          let ktpOcrData = result.data;
          setBasicDetailsState((prevState) => ({
            ...prevState,
            nameOnIdentityCard: ktpOcrData.nameOnIdentityCard,
            dateOfBirth: ktpOcrData.dateOfBirth,
            identityNumber: ktpOcrData.identityNumber
          }));
          getKyc1Data();

          endTask?.();
          startInterval?.();
          setShowModal(false);
          showNotification('success', 'Status updated');
        }
      })
      .catch((err) => {
        if (err?.error_message?.includes('NikAlreadyPresent')) {
          return setCheckPartnerNikCase(false, () => {
            saveDuplicateB2BModalShow();
          });
        }
        if (err.status === 422) {
          Object.values(err.errors).forEach((error) => {
            showNotification('error', error.msg);
          });
        }
        showNotification('error', err.error_message);
      })
      .finally(() => {
        setBasicDetailsState((prevState) => ({
          ...prevState,
          isButtonDisabled: false
        }));
        setLoader(false);
      });
  };

  const handleSubmitForiegner = () => {
    let data = {
      ...ktpOcr,
      userId: ktpOcr.userId,
      citizenship: ktpOcr.citizenship,
      placeOfBirth: ktpOcr.placeOfBirth,
      occupation: ktpOcr.occupation,
      religion: ktpOcr.religion,
      gender: ktpOcr.gender,
      bloodType: ktpOcr.bloodType,
      maritalStatus: ktpOcr.maritalStatus,
      expirationDate: ktpOcr.expirationDate ? formatDateTime(ktpOcr.expirationDate) : null,
      cityCode: ktpOcr.cityCode,
      dateOfBirth: dateOfBirth ? formatDateTime(dateOfBirth) : null,
      nameOnIdentityCard,
      identityNumber,
      meta: ktpOcr.meta,
      id: ktpOcr.id,
      nationality: kycData.nationality,
      addressJson: {
        address: (ktpOcr.address && ktpOcr.address['address']) || '',
        postalCode: ktpOcr.meta.postalCode || ''
      },
      updateBasicDetail: true,
      basicDetailVerifiedById: ktpOcr.basicDetailVerifiedById,
      verifiedById: ktpOcr.verifiedById,
      checkPartnerNikCase
    };
    data.isForeigner = true;
    const errors = ktpOcrForeignerValidation(data);
    if (errors.length > 0) {
      errors.forEach((errorText) => {
        showNotification('error', errorText.body, errorText.title);
      });
      return;
    }
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.KYC1}/ktpOcr/foreigner`,
      data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          let ktpOcrData = result.data;
          setBasicDetailsState((prevState) => ({
            ...prevState,
            nameOnIdentityCard: ktpOcrData.nameOnIdentityCard,
            dateOfBirth: ktpOcrData.dateOfBirth,
            identityNumber: ktpOcrData.identityNumber
          }));
          getKyc1Data();
          setShowModal(false);
          endTask?.();
          startInterval?.();
          showNotification('success', 'Status updated');
        }
      })
      .catch((err) => {
        if (err?.error_message?.includes('NikAlreadyPresent')) {
          return setCheckPartnerNikCase(false, () => {
            saveDuplicateB2BModalShow();
          });
        }
        if (err.status === 422) {
          Object.values(err.errors).forEach((error) => {
            showNotification('error', error.msg);
          });
        }
        showNotification('error', err.error_message);
      })
      .finally(() => {
        setBasicDetailsState((prevState) => ({
          ...prevState,
          isButtonDisabled: false
        }));
        setLoader(false);
      });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setBasicDetailsState((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const getKycStateTransitionLog = () => {
    if (!basicDetailsState.kycStateTransitionData.length > 0) {
      getKycStateTransitionData(kycData.userId).then((result) => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          setBasicDetailsState((prevState) => ({
            ...prevState,
            kycStateTransitionData: result.data.data
          }));
          openKycStateTransitionDialog(result.data.data);
        } else {
          showNotification('error', 'No Kyc State transition logs found');
        }
      });
    } else openKycStateTransitionDialog(basicDetailsState.kycStateTransitionData);
  };

  const openKycStateTransitionDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: (
        <ListDialog title="Kyc State Transition logs" data={data} headers={listHeaders} />
      )
    });
  };

  const escalateBasicDetailsTask = () => {
    escalateTask('BASIC_VERIFICATION', () => {
      setEscalate((prevState) => ({
        ...prevState,
        iskyc1Escalated: true
      }));
    });
  };

  const closeRejectionDialog = () => {
    setRejectionModal(false);
    setBasicDetailsState((prevState) => ({
      ...prevState,
      englishOptions: [],
      bahasaOptions: []
    }));
  };

  const parentalLetter =
    kycData && ((kycData.meta && kycData.meta?.parentalLetter) || kycData.parentalLetter);
  const familyCertificate =
    kycData &&
    ((kycData.meta && kycData.meta?.familyCertificate) || kycData.familyCertificate || '');

  const normalKycData = [
    {
      label: 'Liveness Check',
      value: kycData?.livelinessCheckStatus ? String(kycData.livelinessCheckStatus) : ''
    },
    { label: 'Partner Id', value: kycData ? kycData.partnerId : '' },
    {
      label: 'Auto Approve Rejection Reason',
      value: kycData ? kycData.autoApprovalRejectionReason : ''
    },
    { label: 'Dukcapil Checking Response', value: kycData ? kycData.asliriResponse : '' }
  ];
  const foreignerKycData = [
    { label: 'Country of Nationality', value: ktpOcr.meta?.nationality },
    { label: 'Residence Address', value: kycData ? kycData.residentialAddress : '' },
    { label: 'Residence Country', value: kycData ? kycData.residentialCountry : '' },
    { label: 'Zip Code', value: kycData ? kycData.residentialPostalCode : '' },
    { label: 'Place of birth', value: kycData ? kycData.placeOfBirth : '' },
    { label: 'FATCA Status', value: kycData && kycData.fatcaDocument ? 'UPLOADED' : 'NOT UPLOADED' }
  ];
  const basicTableData = [
    { label: 'User Id', value: userData.id },
    { label: 'User Name', value: kycData.nameOnIdentityCard },
    { label: 'NIK', value: kycData.identityCardNumber },
    { label: 'DoB', value: userData.dateOfBirth },
    { label: 'OCR DoB', value: ktpOcr.dateOfBirth },
    {
      label: 'Last Upload Time',
      value: kycData.lastUploadTime ? dateFormatter(kycData.lastUploadTime) : ''
    },
    { label: 'Last Update Time', value: kycData.updatedAt ? dateFormatter(kycData.updatedAt) : '' },
    {
      label: 'FATCA Document',
      value: kycData.status !== 'VERIFIED' &&
        kycData.nationality?.toLowerCase() === NATIONALITIES.UNITED_STATES && (
          <input
            type="file"
            accept=".pdf"
            id="formFatcaDocument"
            name="fatcaDocument"
            style={{ opacity: 1, width: '40%' }}
            onChange={(e) => handleFileChange(e)}
          />
        )
    },
    ...(kycData.nationality === NATIONALITIES.INDONESIA ? normalKycData : foreignerKycData),
    {
      label: 'PEP Status',
      value:
        typeof userAmlRiskRatingData?.pepCheck === 'boolean'
          ? String(userAmlRiskRatingData.pepCheck).toUpperCase()
          : ''
    },
    {
      label: 'DTTOT Status',
      value:
        typeof userAmlRiskRatingData?.sanctionCheck === 'boolean' ? (
          <>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography> {String(userAmlRiskRatingData.sanctionCheck).toUpperCase()} </Typography>
              {(kycData.status === 'UPLOADED' || kycData.status === 'REJECTED') && (
                <Button
                  sx={{ ml: 2 }}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    dispatch({
                      type: OPEN_MODAL,
                      modalContent: (
                        <AlertDialog
                          title="DTTOT Status"
                          alertMessage={`Are you sure want to change Dttot Status to 
                      ${String(!userAmlRiskRatingData.sanctionCheck).toUpperCase()}?`}
                          onConfirm={() =>
                            updateSanctionStatus(!userAmlRiskRatingData.sanctionCheck)
                          }
                        />
                      )
                    })
                  }>
                  Change DTTOT Status
                </Button>
              )}
            </Box>
            {userAmlRiskRatingData.sanctionCheck && (
              <Typography color="error">
                (Matching with {userAmlRiskRatingData?.meta?.matchedData?.category || 'DTTOT'} name:{' '}
                {userAmlRiskRatingData?.meta?.matchedData?.name} Reference Code:{' '}
                {userAmlRiskRatingData?.meta?.matchedData?.densusCode} Please check carefully before
                approving or rejecting this users KYC!)
              </Typography>
            )}
          </>
        ) : (
          ''
        )
    },
    {
      label: 'Risk Rating Category',
      value: userAmlRiskRatingData?.userAmlRiskRatingCategory
        ? String(userAmlRiskRatingData.userAmlRiskRatingCategory).toUpperCase()
        : ''
    },
    {
      label: 'Rejection Reason',
      value: kycData.rejectionReasonJson
        ? getRejectionReasonInIndonesianLang(kycData.rejectionReasonJson)
        : ''
    },
    { label: 'Dual Approval Status', value: kycData.dualApprovalStatus },
    { label: 'Dual Approval Reject Reason', value: kycData.dualApprovalRejectionReason },
    { label: 'Date of checker', value: kycData ? dateFormatter(kycData.dualApprovalTime) : '' },
    {
      label: 'Dual Approval Submission Time',
      value: ktpOcr.updatedAt ? dateFormatter(ktpOcr.updatedAt) : ''
    }
  ];

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.iskyc1Escalated && (
        <Typography sx={{ border: 1, color: 'red', mr: 2, p: '2px' }}>ESCALATED</Typography>
      )}
      {kycData && kycData.status === 'VERIFIED' ? (
        <Typography sx={{ color: 'green' }}>{kycData.status}</Typography>
      ) : (
        <Typography>{kycData.status}</Typography>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    if (
      kycData.status === 'UPLOADED' &&
      !escalate.iskyc1Escalated &&
      kycData.dualApprovalStatus !== 'NEED_APPROVAL' &&
      isAutoAssign
    ) {
      startTask('BASIC_VERIFICATION');
    }
    handleDropdown(showDetail);
  };

  const isTaskDisabled = kycData.status === 'NOT UPLOADED';

  return (
    <KycTask
      title="Basic Verification Data"
      status={kycTaskStatus()}
      onClick={() => handleKycTaskClick()}
      disabled={isTaskDisabled}
      open={showDetail}>
      <Grid>
        <Grid item>
          <BasicTable tableData={basicTableData} />
          {kycData?.digitalSignatureStatus && (
            <>
              <Typography sx={{ mt: 3 }} variant="h6">
                Signature
              </Typography>
              <PrivyDigitalCertificate kycData={kycData} />
            </>
          )}
          {kycData.dualApprovalStatus === 'NEED_APPROVAL' ? (
            <>
              <Box
                sx={{ border: 1, borderRadius: 4, my: 2, p: 1.5, textAlign: 'center' }}
                bgcolor={red[50]}
                borderColor={red[200]}
                color={red[500]}>
                Dual Approval Pending
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="center" my={2}>
              {kycData.status &&
                (kycData.status === 'UPLOADED' || kycData.status === 'REJECTED') && (
                  <Box>
                    {kycData.identityCardNumber ? (
                      <Button
                        color="success"
                        size="small"
                        variant="contained"
                        onClick={() =>
                          dispatch({
                            type: OPEN_MODAL,
                            modalContent: (
                              <AlertDialog
                                title="Accept KYC"
                                alertMessage="Are you sure want to accept?"
                                onConfirm={() => updatedStatus('VERIFIED')}
                              />
                            )
                          })
                        }
                        disabled={
                          (kycData.nationality &&
                            kycData.nationality.toLowerCase() === NATIONALITIES.UNITED_STATES &&
                            basicDetailsState.fatcaDocument === null) ||
                          (escalate.iskyc1Escalated && isAutoAssign)
                        }>
                        Accept
                      </Button>
                    ) : (
                      <Box>Please, verify OCR data to accept the KYC</Box>
                    )}
                    <Button
                      sx={{ ml: 2 }}
                      color="error"
                      size="small"
                      variant="contained"
                      onClick={() => setRejectionModal(true)}
                      disabled={escalate.iskyc1Escalated && isAutoAssign}>
                      Reject
                    </Button>
                    {kycData.status === 'UPLOADED' && isAutoAssign && (
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          dispatch({
                            type: OPEN_MODAL,
                            modalContent: (
                              <AlertDialog
                                title="Escalate Basic Verification Data"
                                alertMessage="Are you sure want to escalate?"
                                onConfirm={escalateBasicDetailsTask}
                              />
                            )
                          })
                        }
                        disabled={escalate.iskyc1Escalated}>
                        {escalate.iskyc1Escalated ? 'Escalated' : 'Escalate'}
                      </Button>
                    )}
                  </Box>
                )}
              {kycData.status &&
                (kycData.status === 'VERIFIED' || kycData.status === 'WAITING_PARENTAL_LETTER') && (
                  <Button
                    sx={{ ml: 2 }}
                    color="error"
                    size="small"
                    variant="contained"
                    onClick={() => setRejectionModal(true)}
                    disabled={escalate.iskyc1Escalated && isAutoAssign}>
                    Reject
                  </Button>
                )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <h4>User KYC Details</h4>
        </Box>
        <Box>
          {(appState.user?.features.superadmin || appState.user?.features.dualapproval) &&
          kycData.dualApprovalStatus === 'NEED_APPROVAL' &&
          !isAutoAssign ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/ops/dualApproval', {
                  state: {
                    kycData,
                    userData,
                    ktpOcr,
                    dateOfSubmission: ktpOcr.updatedAt,
                    idCardPicture: kycData.idCardPicture,
                    selfPortraitPicture: kycData.selfPortraitPicture
                  }
                });
              }}>
              Need Approval
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              disabled={
                ktpOcr.status === 'NOT UPLOADED' || (escalate.iskyc1Escalated && isAutoAssign)
              }
              onClick={() => setShowModal(true)}>
              Edit Data
            </Button>
          )}
          <Button
            sx={{ ml: 2 }}
            size="small"
            variant="contained"
            onClick={getKycStateTransitionLog}>
            Kyc State Transition logs
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {kycData.idCardPicture && (
          <Grid container item>
            <Grid item xs={6}>
              <Typography sx={{ mb: 2 }}>Id Card Picture</Typography>
              <Box>{kycData.idCardPicture && <ImageMagnifier url={kycData.idCardPicture} />}</Box>
            </Grid>
          </Grid>
        )}
        {kycData.selfPortraitPicture && (
          <Grid container item>
            <Grid item xs={6}>
              <Typography sx={{ mb: 2 }}>Self Portrait Picture</Typography>
              <Box>
                {kycData.selfPortraitPicture && (
                  <ImageMagnifier url={kycData.selfPortraitPicture} />
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {kycData.nationality === NATIONALITIES.INDONESIA && (
          <Grid container item>
            <Grid item xs={6}>
              <Typography sx={{ mb: 2 }}>Parental Letter</Typography>
              <Box>
                {parentalLetter && <ImageMagnifier url={parentalLetter} />}
                {kycData.status &&
                  kycData.status !== 'VERIFIED' &&
                  parentalLetter === null &&
                  (userData && userData.ageVerified === false ? 'Waiting for Parental Letter' : '')}
                {parentalLetter === null &&
                  (userData && userData.ageVerified
                    ? 'User is above 17 years old / Age is verified'
                    : '')}
              </Box>
            </Grid>
          </Grid>
        )}
        {kycData.nationality === NATIONALITIES.INDONESIA && (
          <Grid container item>
            <Grid item xs={6}>
              <Typography sx={{ mb: 2 }}>Family Certificate</Typography>
              <Box>
                {familyCertificate && <ImageMagnifier url={familyCertificate} />}
                {kycData.status &&
                  kycData.status !== 'VERIFIED' &&
                  familyCertificate === null &&
                  (userData && userData.ageVerified === false
                    ? 'Waiting for family certificate'
                    : '')}
                {familyCertificate === null &&
                  (userData && userData.ageVerified
                    ? 'User is above 17 years old / Age is verified'
                    : '')}
              </Box>
            </Grid>
          </Grid>
        )}
        {kycData.npwpPicture && (
          <Grid container item>
            <Grid item xs={6}>
              <Typography sx={{ mb: 2 }}>Npwp Picture</Typography>
              <Box>{kycData.npwpPicture && <ImageMagnifier url={kycData.npwpPicture} />}</Box>
            </Grid>
          </Grid>
        )}
        {kycData.fatcaDocument && (
          <>
            <Typography>Fatca Document</Typography>
            <Box
              component="object"
              sx={{ width: '100%', height: 500 }}
              data={kycData.fatcaDocument}
              type="application/pdf"
            />
          </>
        )}
      </Box>
      <EditDialog
        showModal={showModal}
        setShowModal={setShowModal}
        title="BASIC DETAILS DATA"
        idCardPicture={kycData.idCardPicture}
        handleSubmit={
          kycData.nationality === NATIONALITIES.INDONESIA ? handleSubmit : handleSubmitForiegner
        }>
        <BasicDetailsForm
          nameOnIdentityCard={nameOnIdentityCard}
          identityNumber={identityNumber}
          dateOfBirth={dateOfBirth}
          setBasicDetailsState={setBasicDetailsState}
        />
      </EditDialog>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: '800px',
              maxWidth: '800px'
            }
          }
        }}
        open={showRejectionModal}
        onClose={closeRejectionDialog}>
        <AlertDialog
          title="Reject KYC"
          alertMessage="Reason?"
          onClose={closeRejectionDialog}
          onConfirm={() => updatedStatus('REJECTED')}>
          <RejectionReasonContent
            englishOptions={basicDetailsState.englishOptions}
            bahasaOptions={basicDetailsState.bahasaOptions}
            rejectionOptionsInEn={
              kycData.nationality === NATIONALITIES.INDONESIA
                ? kycRejectionOptionsInEn
                : kycForeignerRejectionOptionsInEn
            }
            rejectionOptionsInId={
              kycData.nationality === NATIONALITIES.INDONESIA
                ? kycRejectionOptionsInId
                : kycForeignerRejectionOptionsInId
            }
            setRejectionReasonState={setBasicDetailsState}
          />
        </AlertDialog>
      </Dialog>
    </KycTask>
  );
};

BasicDetails.propTypes = {
  startTask: PropTypes.func,
  startInterval: PropTypes.func,
  endTask: PropTypes.func,
  escalateTask: PropTypes.func,
  getKyc1Data: PropTypes.func,
  ktpOcrData: PropTypes.object,
  escalate: PropTypes.object,
  kyc1Data: PropTypes.object,
  userAmlRiskRatingData: PropTypes.object,
  userData: PropTypes.object,
  setEscalate: PropTypes.func,
  setKyc1Data: PropTypes.func,
  isAutoAssign: PropTypes.bool
};

export default BasicDetails;
