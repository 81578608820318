import Joi from 'joi';

export const missionPartnerSchema = Joi.object({
  partnerName: Joi.string().min(1).required().label('Partner Name'),
  picName: Joi.string().min(2).required().label('PIC Name'),
  joiningDate: Joi.date().raw().required(),
  pksDoc: Joi.string().required().label('PKS Doc'),
  picEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .label('PIC Email'),
  picPhone: Joi.string().min(8).max(15).required().label('PIC Phone'),
  active: Joi.boolean()
});
