import Joi from 'joi';

export const JoiNumber = Joi.extend((joi) => ({
  type: 'normalizeValue', // add type with custom name which will be called in joi
  base: joi.number(), // let's base it on Joi.number
  coerce(value, helpers) {
    // this executes before 'validate' method is called, so we can convert formatted string to number
    if (value) {
      const normalizedValue = Number(value.toString().replace(/[^\d.-]/g, ''));
      return { value: normalizedValue };
    }
    return { value };
  }
}));
