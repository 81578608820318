import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from './APIService';
const sha256 = require('js-sha256').sha256;

const ACCESS_TOKEN_KEY = 'admin.pluang.com.token';

let ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_KEY);

export const getAccessToken = () => {
  if (!ACCESS_TOKEN) {
    ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_KEY);
  }
  return ACCESS_TOKEN;
};

export const checkIfLoggedIn = () => {
  getAccessToken();
  return !!ACCESS_TOKEN;
};

export const login = (email, password) => {
  return new Promise((resolve, reject) => {
    logout()
      .then(() => {
        return makeRequest({
          method: 'POST',
          url: REMOTE.ADMIN_LOGIN,
          data: { email, password: sha256(password) }
        });
      })
      .then((result) => {
        let isSuperAdmin = false;
        localStorage.setItem(ACCESS_TOKEN_KEY, result.data.data.token);
        result.data.data.user.roles.forEach((roleName) => {
          if (roleName.toLowerCase() === 'superadmin') {
            isSuperAdmin = true;
          }
        });
        if (!isSuperAdmin) {
          const roleIds = result.data.data.user.roleIds;
          return makeRequest({
            url: `${REMOTE.ADMIN_RESOURCES}/permission?roleId=${roleIds.join(',')}`
          }).then((featurePermissions) => {
            result.data.data.user.features = featurePermissions.data.data.permissions;
            return result;
          });
        }
        result.data.data.user.features = { superadmin: true };
        return result;
      })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forgot = (email) => {
  return new Promise((resolve, reject) => {
    makeRequest({
      method: 'POST',
      url: REMOTE.ADMIN_FORGOT,
      data: { email }
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const reset = (resetKey, id, password) => {
  return new Promise((resolve, reject) => {
    makeRequest({
      method: 'POST',
      url: REMOTE.ADMIN_RESET,
      data: { reset_key: resetKey, id, password: sha256(password) }
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    const token = getAccessToken();
    if (token) {
      makeRequest({
        method: 'post',
        url: REMOTE.ADMIN_LOGOUT,
        data: {
          accessToken: localStorage.getItem(ACCESS_TOKEN_KEY)
        }
      })
        .then(() => {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          ACCESS_TOKEN = null;
          resolve();
        })
        .catch(reject);
    } else {
      resolve();
    }
  });
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    makeRequest({
      url: REMOTE.ME
    })
      .then((result) => {
        if (!result.data.success || !result.data.data) {
          logout();
          reject();
        } else {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        logout();
        reject(error);
      });
  });
};

const authServices = {
  getAccessToken,
  checkIfLoggedIn,
  login,
  logout,
  forgot,
  reset,
  getCurrentUser
};

export default authServices;
