import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

const BasicTable = ({ tableData }) => {
  return (
    <Table>
      <TableBody>
        {tableData.map(({ heading, label, value }, i) => (
          <TableRow key={i}>
            {heading ? (
              <TableCell colSpan={2} variant="head">
                {heading}
              </TableCell>
            ) : (
              <>
                <TableCell width="40%">{label}</TableCell>
                <TableCell width="60%">{value}</TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

BasicTable.propTypes = {
  tableData: PropTypes.array.isRequired
};

export default BasicTable;
