const useIdleTimer = ({ timeout, onExpired }) => {
  let interval;

  const checkTimer = () => {
    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || 0, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      if (onExpired) {
        onExpired();
        cleanUp();
      }
    }
  };

  const updateExpiredTime = () => {
    localStorage.setItem('_expiredTime', Date.now() + timeout * 1000);
  };

  const startInterval = () => {
    if (!interval) {
      interval = setInterval(() => {
        const expiredTime = parseInt(localStorage.getItem('_expiredTime') || 0, 10);
        if (expiredTime < Date.now()) {
          if (onExpired) {
            onExpired();
            cleanUp();
          }
        }
      }, 1000);

      updateExpiredTime();
    }
  };

  const cleanUp = () => {
    localStorage.removeItem('_expiredTime');
    clearInterval(interval);
    interval = null;
  };

  return {
    checkTimer,
    updateExpiredTime,
    startInterval,
    cleanUp
  };
};

export default useIdleTimer;
