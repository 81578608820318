import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import Container from 'components/atoms/Container';
import Modal from 'components/molecules/Modal';
import Notification from 'components/molecules/Notification';
import Header from 'components/organisms/Header';
import Sidebar from 'components/organisms/Sidebar';
import { useStateValue } from 'utils/redux';

const Layout = () => {
  const drawerWidth = 250;
  const [appState] = useStateValue();
  return (
    <Container>
      <Header />
      <Sidebar drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{
          '@media print': { maxWidth: '100%' },
          flexGrow: 1,
          maxWidth: `calc(100% - ${drawerWidth}px)`,
          p: 3
        }}>
        <Toolbar />
        <Outlet />
      </Box>
      <Modal>{appState.modalContent}</Modal>
      <Notification />
    </Container>
  );
};

export default Layout;
