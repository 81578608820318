import Layout from 'components/Layout';
import Maintenance from 'components/molecules/Maintenance';
import Welcome from 'components/molecules/Welcome';
import Config from 'config';
import { LOCAL } from 'constants/clientRoutes';
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import RequireAuth from 'RequireAuth';
import { checkIfLoggedIn, getCurrentUser } from 'services/AuthService';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { MAINTENANCE_MODE, USER_STATE } from 'utils/redux/actions';
import Auth from './auth';
import BankDetails from './bankDetails';
import AgentPanel from './ops/kyc/agent';

const SuperAdmin = lazy(() => import('./super'));
const AccountManager = lazy(() => import('./ac'));
const Operations = lazy(() => import('./ops'));
const CustomerService = lazy(() => import('./cs'));
const Finance = lazy(() => import('./fi'));
const AppSettings = lazy(() => import('./as'));
const VoucherService = lazy(() => import('./vs'));
const MutualFunds = lazy(() => import('./mf'));

const App = () => {
  const navigate = useNavigate();
  const [appState, dispatch] = useStateValue();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (checkIfLoggedIn()) {
      getCurrentUser()
        .then((user) => dispatch({ type: USER_STATE, user, loggedIn: true }))
        .catch((error) =>
          dispatch({
            type: USER_STATE,
            user: null,
            loggedIn: false,
            isMaintenance: (error && error.error_message) === Config.MAINTENANCE_ERROR
          })
        );
    }
    document.addEventListener('error', (e) => {
      if (e.detail.title?.includes('403')) {
        navigate('/error', {
          replace: true,
          state: { message: 'You are not authorized to visit that page' }
        });
      }
      if (e.detail.body === Config.MAINTENANCE_ERROR) {
        dispatch({
          type: MAINTENANCE_MODE,
          isMaintenance: true
        });
      }
      showNotification(e.detail.type, e.detail.body, e.detail.title);
    });
    return () => {
      document.removeEventListener('error', null);
    };
  }, []);

  if (appState.isMaintenance)
    return (
      <Routes>
        <Route path="*" element={<Maintenance />} />
      </Routes>
    );

  return !appState.loggedIn ? (
    <Routes>
      <Route path="/*" element={<Auth />} />
    </Routes>
  ) : (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<Layout />}>
          <Route
            path={`${LOCAL.ADMIN}/*`}
            element={
              <Suspense fallback="Loading...">
                <SuperAdmin />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.AC}/*`}
            element={
              <Suspense fallback="Loading...">
                <AccountManager />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.OPS}/*`}
            element={
              <Suspense fallback="Loading...">
                <Operations />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.CS}/*`}
            element={
              <Suspense fallback="Loading...">
                <CustomerService />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.FI}/*`}
            element={
              <Suspense fallback="Loading...">
                <Finance />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.VS}/*`}
            element={
              <Suspense fallback="Loading...">
                <VoucherService />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.AS}/*`}
            element={
              <Suspense fallback="Loading...">
                <AppSettings />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.MF}/*`}
            element={
              <Suspense fallback="Loading...">
                <MutualFunds />
              </Suspense>
            }
          />
          <Route
            path={`${LOCAL.OPS}/kyc/agent`}
            element={
              <Suspense fallback="Loading...">
                <AgentPanel />
              </Suspense>
            }
          />
          <Route
            path={'/bankDetails/:userBankId'}
            element={
              <Suspense fallback="Loading...">
                <BankDetails />
              </Suspense>
            }
          />
          <Route path="welcome" element={<Welcome />} />
          <Route path="*" element={<Navigate to="welcome" />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
