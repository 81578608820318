import Joi from 'joi';

const VALIDATION_RULES = {
  minUsdtBalance: {
    display: 'Minimum USDT Balance',
    min: 5000
  },
  minBidrBalance: {
    display: 'Minimum BIDR Balance',
    min: 50000000
  },
  minQuoteCurrencyBalance: {
    display: 'Minimum Total Quote Currency (USDT)',
    min: 1000,
    max: 10000000
  },
  spreadThreshold: {
    display: 'USDT Portion (%)',
    min: 10,
    max: 90
  },
  lowerThreshold: {
    display: 'USDT Portion Threshold Low (%)',
    min: 0,
    max: 90
  },
  upperThreshold: {
    display: 'USDT Portion Threshold High (%)',
    min: 10,
    max: 100
  }
};

export const minimumBalanceAlertSchema = Joi.object({
  minUsdtBalance: Joi.number()
    .label(VALIDATION_RULES.minUsdtBalance.display)
    .greater(VALIDATION_RULES.minUsdtBalance.min),
  minBidrBalance: Joi.number()
    .integer()
    .label(VALIDATION_RULES.minBidrBalance.display)
    .greater(VALIDATION_RULES.minBidrBalance.min)
});

export const usdtSchema = Joi.object({
  minQuoteCurrencyBalance: Joi.number()
    .label(VALIDATION_RULES.minQuoteCurrencyBalance.display)
    .min(VALIDATION_RULES.minQuoteCurrencyBalance.min)
    .max(VALIDATION_RULES.minQuoteCurrencyBalance.max),
  spreadThreshold: Joi.number()
    .label(VALIDATION_RULES.spreadThreshold.display)
    .min(VALIDATION_RULES.spreadThreshold.min)
    .max(VALIDATION_RULES.spreadThreshold.max),
  lowerThreshold: Joi.alternatives().conditional('spreadThreshold', {
    is: Joi.number().required(),
    then: Joi.number()
      .integer()
      .label(VALIDATION_RULES.lowerThreshold.display)
      .min(VALIDATION_RULES.lowerThreshold.min)
      .max(Joi.ref('spreadThreshold'))
      .messages({
        'number.max': `${VALIDATION_RULES.lowerThreshold.display} must be lower than ${VALIDATION_RULES.spreadThreshold.display}`
      }),
    otherwise: Joi.number()
      .integer()
      .label(VALIDATION_RULES.lowerThreshold.display)
      .min(VALIDATION_RULES.lowerThreshold.min)
      .max(VALIDATION_RULES.lowerThreshold.max)
  }),
  upperThreshold: Joi.alternatives().conditional('spreadThreshold', {
    is: Joi.number().required(),
    then: Joi.number()
      .integer()
      .label(VALIDATION_RULES.upperThreshold.display)
      .min(Joi.ref('spreadThreshold'))
      .messages({
        'number.min': `${VALIDATION_RULES.upperThreshold.display} must be greater than ${VALIDATION_RULES.spreadThreshold.display}`
      }),
    otherwise: Joi.number()
      .integer()
      .label(VALIDATION_RULES.upperThreshold.display)
      .min(VALIDATION_RULES.upperThreshold.min)
  })
});
