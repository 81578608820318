const COINLABELS = Object.freeze({
  DELISTING_SOON: 'DELISTING',
  DELISTED: 'DELISTED',
  BUY_HALT: 'BUY_HALT',
  SELL_HALT: 'SELL_HALT',
  TRADING_HALT: 'TRADING_HALT',
  BANKRUPTCY_FILED: 'BANKRUPTCY',
  RUG_PULLED: 'RUGPULL',
  MAINTENANCE: 'MAINTENANCE',
  NON_TRADABLE: 'NON_TRADABLE',
  HIGH_RISK: 'HIGH_RISK'
});

const coinLabelOptions = [
  {
    id: COINLABELS.DELISTING_SOON,
    name: 'Delisting Soon'
  },
  {
    id: COINLABELS.DELISTED,
    name: 'Delisted'
  },
  {
    id: COINLABELS.BUY_HALT,
    name: 'Buy Halt'
  },
  {
    id: COINLABELS.SELL_HALT,
    name: 'Sell halt'
  },
  {
    id: COINLABELS.TRADING_HALT,
    name: 'Trading Halt'
  },
  {
    id: COINLABELS.BANKRUPTCY_FILED,
    name: 'Bankruptcy Filed'
  },
  {
    id: COINLABELS.RUG_PULLED,
    name: 'Rug Pulled'
  },
  {
    id: COINLABELS.MAINTENANCE,
    name: 'Maintenance'
  },
  {
    id: COINLABELS.NON_TRADABLE,
    name: 'Non-Tradable'
  },
  {
    id: COINLABELS.HIGH_RISK,
    name: 'High Risk'
  }
];

const announcementTypes = Object.freeze({
  ASSEST_PAGE: 'assetPageAnnouncement',
  TRADING_BUTTONS: 'tradingButtonsAnnouncement',
  FULL_PAGE: 'fullPageAnnouncement',
  HIDDEN_SCREENS: 'hiddenScreens'
});

const BUTTON_ACTION = Object.freeze({
  UNDERSTOOD: 'UNDERSTOOD',
  SEND_ALL: 'SEND_ALL'
});

const BUTTON_TEXT = Object.freeze({
  UNDERSTOOD: 'UNDERSTOOD',
  SEND_ALL: 'SEND ALL'
});

const HIDDEN_SCREENS = Object.freeze({
  EXPLORE: 'EXPLORE',
  ALL: 'ALL'
});

const actionButtonOptions = [
  { label: 'Understood', value: BUTTON_ACTION.UNDERSTOOD },
  { label: 'Send All', value: BUTTON_ACTION.SEND_ALL }
];

export {
  coinLabelOptions,
  COINLABELS,
  announcementTypes,
  BUTTON_ACTION,
  actionButtonOptions,
  HIDDEN_SCREENS,
  BUTTON_TEXT
};
