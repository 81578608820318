import { IMAGES } from 'constants/images';
import PropTypes from 'prop-types';
import React from 'react';
import NoContentFound from '../NoContentFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error) {
    console.log('In error boundary', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoContentFound
          src={IMAGES.ERROR}
          title="Whoops, something’s wrong!"
          description={this.state.error}
        />
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
