import Joi from 'joi';

export const merchantSchema = Joi.object({
  id: Joi.number().integer().positive(),
  name: Joi.string().min(5).required(),
  phone: Joi.string().min(8).max(15).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  active: Joi.string().valid('true', 'false').required()
});
