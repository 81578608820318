import { Box, Checkbox, Chip, InputAdornment, MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const FormTextField = (props) => {
  const {
    disabled = false,
    endAdornment,
    error,
    helperText,
    label,
    maxRows = 3,
    methods,
    minRows,
    multiline = false,
    multiple,
    name,
    onChange = null,
    options,
    required = true,
    select = false,
    startAdornment,
    type = 'text',
    uppercase = false
  } = props;
  const {
    control,
    formState: { errors }
  } = methods;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          {...props}
          fullWidth
          multiline={multiline}
          maxRows={maxRows}
          minRows={minRows}
          disabled={disabled}
          label={label && `${label} ${required ? '*' : ''}`}
          margin="normal"
          size="small"
          select={select}
          SelectProps={
            multiple && {
              multiple,
              renderValue: (selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((option) => (
                      <Chip key={option} label={option} />
                    ))}
                  </Box>
                );
              }
            }
          }
          type={type}
          onChange={onChange ? onChange : field.onChange}
          inputProps={uppercase ? { style: { textTransform: 'uppercase' } } : {}}
          InputProps={{
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            )
          }}
          error={error || !!errors[name]}
          helperText={helperText || errors[name]?.message}>
          {options?.length > 0 ? (
            options.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {multiple && <Checkbox checked={field?.value?.includes(option.id)} />}
                {option.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No options</MenuItem>
          )}
        </TextField>
      )}
    />
  );
};

FormTextField.propTypes = {
  disabled: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  methods: PropTypes.object.isRequired,
  multiline: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  select: PropTypes.bool,
  startAdornment: PropTypes.string,
  type: PropTypes.string,
  uppercase: PropTypes.bool
};

export default FormTextField;
