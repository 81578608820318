import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Button } from '@mui/material';
import FormTextField from 'components/molecules/FormTextField';
import { LOCAL } from 'constants/clientRoutes';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { reset } from 'services/AuthService';
import { useNotification } from 'services/hooks';
import { resetSchema } from 'utils/schemas';

const Reset = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { adminId, secretKey } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const methods = useForm({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: joiResolver(resetSchema)
  });

  const handleReset = ({ password }) => {
    setIsButtonDisabled(true);
    reset(secretKey, adminId, password)
      .then(() => {
        showNotification('success', 'Proceed to Login!', 'Password reset successful');
        navigate(LOCAL.LOGIN);
      })
      .finally(() => setIsButtonDisabled(false));
  };

  if (!adminId || !secretKey) return <Navigate to={LOCAL.LOGIN} />;

  return (
    <Box component="form" onSubmit={methods.handleSubmit(handleReset)}>
      <FormTextField methods={methods} label="Password" name="password" type="password" />
      <FormTextField
        methods={methods}
        label="Confirm Password"
        name="confirmPassword"
        type="password"
      />
      <Box mt={1}>
        <Button size="small" variant="contained" type="submit" disabled={isButtonDisabled}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Reset;
