import { Autocomplete, Box, MenuItem, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import HighlightText from 'components/atoms/HighlightText';
import { formatDateTime } from 'helpers/formatDate';
import PropTypes from 'prop-types';
import { Country } from 'country-state-city';

const COUNTRIES = Country.getAllCountries();

const KtpOcrForm = ({ ocrState, setOcrState, showNameFields = false }) => {
  const {
    citizenship,
    countryOfBirth,
    placeOfBirth,
    ktpOcrDateOfBirth,
    address,
    rtRw,
    province,
    provinceCollection,
    city,
    cityCollection,
    district,
    districtCollection,
    subDistrict,
    subDistrictCollection,
    ktpOcrMetaData,
    occupation,
    occupationCollection,
    religion,
    religionCollection,
    gender,
    genderCollection,
    bloodCollection,
    bloodType,
    maritalStatus,
    maritalStatusCollection,
    expirationDate,
    issueDate,
    identityNumber,
    nameOnIdentityCard
  } = ocrState;

  const handleChange = (event) => {
    let { name, value } = event.target;
    setOcrState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Box>
      {showNameFields && (
        <>
          <HighlightText>
            {(ktpOcrMetaData && ktpOcrMetaData.nameOnIdentityCard) || ''}
          </HighlightText>
          <TextField
            fullWidth
            margin="normal"
            size="small"
            label="Name on Identity Card"
            value={nameOnIdentityCard}
            name="nameOnIdentityCard"
            onChange={(e) => handleChange(e)}
          />
          <HighlightText>{(ktpOcrMetaData && ktpOcrMetaData.identityNumber) || ''}</HighlightText>
          <TextField
            fullWidth
            margin="normal"
            size="small"
            label="Identity Number"
            value={identityNumber}
            name="identityNumber"
            onChange={(e) => handleChange(e)}
          />
          <HighlightText>
            {(ktpOcrMetaData && formatDateTime(ktpOcrMetaData.dateOfBirth)) || ''}
          </HighlightText>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date Of Birth (DD/MM/YYYY)"
              onChange={(date) =>
                setOcrState((prevState) => ({
                  ...prevState,
                  ktpOcrDateOfBirth: date
                }))
              }
              value={ktpOcrDateOfBirth}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" size="small" />
              )}
            />
          </LocalizationProvider>
        </>
      )}
      <HighlightText>{ktpOcrMetaData?.citizenship}</HighlightText>
      <TextField
        fullWidth
        margin="normal"
        size="small"
        label="Citizenship"
        value={citizenship}
        name="citizenship"
        onChange={(e) => handleChange(e)}
      />
      <HighlightText>{ktpOcrMetaData?.placeOfBirth}</HighlightText>
      <TextField
        fullWidth
        margin="normal"
        size="small"
        label="Place Of Birth"
        value={placeOfBirth}
        name="placeOfBirth"
        onChange={(e) => handleChange(e)}
      />
      <HighlightText>{ktpOcrMetaData?.address?.address}</HighlightText>
      <TextField
        fullWidth
        margin="normal"
        size="small"
        label="Address"
        value={address || ''}
        name="address"
        onChange={(e) => handleChange(e)}
      />
      <HighlightText>{ktpOcrMetaData?.address?.rtRw}</HighlightText>
      <TextField
        fullWidth
        margin="normal"
        size="small"
        label="RT/RW"
        value={rtRw || ''}
        name="rtRw"
        onChange={(e) => handleChange(e)}
      />
      <HighlightText>{ktpOcrMetaData?.countryOfBirth}</HighlightText>
      <TextField
        fullWidth
        select
        margin="normal"
        size="small"
        label="Country of Birth"
        value={countryOfBirth || ''}
        name="countryOfBirth"
        onChange={(e) => handleChange(e)}>
        {COUNTRIES.map((country) => (
          <MenuItem key={country.name} value={country.name}>
            {country.name}
          </MenuItem>
        ))}
      </TextField>
      <HighlightText>{ktpOcrMetaData?.address?.province}</HighlightText>
      <Autocomplete
        id="district-dropdown"
        options={provinceCollection}
        isOptionEqualToValue={(option, value) => option.provinceName === value.provinceName}
        getOptionLabel={(option) => option.provinceName}
        defaultValue={{ provinceName: province }}
        value={{ provinceName: province.provinceName || province }}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              province: value,
              city: '',
              district: '',
              subDistrict: ''
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Province" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.address?.city}</HighlightText>
      <Autocomplete
        id="district-dropdown"
        options={cityCollection}
        isOptionEqualToValue={(option, value) => option.cityName === value.cityName}
        getOptionLabel={(option) => option.cityName}
        defaultValue={{ cityName: city }}
        value={{ cityName: city.cityName || city }}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              city: value,
              district: '',
              subDistrict: ''
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="City" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.address?.district}</HighlightText>
      <Autocomplete
        id="district-dropdown"
        options={districtCollection}
        isOptionEqualToValue={(option, value) => option.districtName === value.districtName}
        getOptionLabel={(option) => option.districtName}
        defaultValue={{ districtName: district }}
        value={{ districtName: district.districtName || district }}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              district: value,
              subDistrict: ''
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="District" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.address?.subDistrict}</HighlightText>
      <Autocomplete
        id="subdistrict-dropdown"
        options={subDistrictCollection}
        isOptionEqualToValue={(option, value) => option.subDistrictName === value.subDistrictName}
        getOptionLabel={(option) => option.subDistrictName}
        defaultValue={{ subDistrictName: subDistrict }}
        value={{
          subDistrictName: subDistrict.subDistrictName || subDistrict
        }}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              subDistrict: value
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Subdistrict" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.occupation}</HighlightText>
      <Autocomplete
        id="occupation-dropdown"
        options={occupationCollection}
        getOptionLabel={(option) => option}
        defaultValue={occupation}
        value={occupation}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              occupation: value
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Occupation" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.religion}</HighlightText>
      <Autocomplete
        id="occupation-dropdown"
        options={religionCollection}
        getOptionLabel={(option) => option}
        defaultValue={religion}
        value={religion}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              religion: value
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Religion" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.bloodType}</HighlightText>
      <Autocomplete
        id="occupation-dropdown"
        options={bloodCollection}
        getOptionLabel={(option) => option}
        defaultValue={bloodType}
        value={bloodType}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              bloodType: value
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Blood Type" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.gender}</HighlightText>
      <Autocomplete
        id="occupation-dropdown"
        options={genderCollection}
        getOptionLabel={(option) => option}
        defaultValue={gender}
        value={gender}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              gender: value
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Gender" />
        )}
      />
      <HighlightText>{ktpOcrMetaData?.maritalStatus}</HighlightText>
      <Autocomplete
        id="occupation-dropdown"
        options={maritalStatusCollection}
        getOptionLabel={(option) => option}
        defaultValue={maritalStatus}
        value={maritalStatus}
        onChange={(event, value) => {
          if (value) {
            setOcrState((prevState) => ({
              ...prevState,
              maritalStatus: value
            }));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label="Marital Status" />
        )}
      />
      <HighlightText>
        {(ktpOcrMetaData?.expirationDate && formatDateTime(ktpOcrMetaData.expirationDate)) || ''}
      </HighlightText>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Expiration Date"
          inputFormat="dd/MM/yyyy"
          onChange={(date) =>
            setOcrState((prevState) => ({
              ...prevState,
              expirationDate: date
            }))
          }
          value={expirationDate}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
        />
      </LocalizationProvider>
      <HighlightText>
        {(ktpOcrMetaData?.issueDate && formatDateTime(ktpOcrMetaData.issueDate)) || ''}
      </HighlightText>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Issue Date"
          inputFormat="dd/MM/yyyy"
          onChange={(date) =>
            setOcrState((prevState) => ({
              ...prevState,
              issueDate: date
            }))
          }
          value={issueDate}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" size="small" />}
        />
      </LocalizationProvider>
    </Box>
  );
};

KtpOcrForm.propTypes = {
  ktpOcrMetaData: PropTypes.object,
  ocrState: PropTypes.object.isRequired,
  setOcrState: PropTypes.func.isRequired,
  showNameFields: PropTypes.bool
};

export default KtpOcrForm;
